import './Landing.scss';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import Fetcher from './Component/Helper/Fetcher';
import AppFooter from './Component/ClientPages/AppFooter/AppFooter.jsx';
import { Helmet } from 'react-helmet';
import { GiHamburgerMenu } from "react-icons/gi";
import LoadingCard from './Component/Helper/LoadingCard';
import { awaitedSubmitData, awaitedQueryData } from './Component/Helper/PromiseWrapper.ts';
import SuspenseBody from './Component/Helper/SuspenseBody';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import { Button } from 'reactstrap';
import { GET } from './Reusables/FetchHandler.jsx';

export default function Landing() {

    const [mainBusiness, setMainBusiness] = useState();
    const [marketingDeptInfo, setMarketingDeptInfo] = useState();
    const verseType = useRef("kjv");
    const [dailyverses, setDailyverses] = useState(<Helmet><script async defer src="https://dailyverses.net/get/verse.js?language=kjv" ></script></Helmet>)
    const [businesses, setBusinesses] = useState()
    const [brands, setBrands] = useState()
    const [channel] = useState("VMI")

    useEffect(() => {
        setBusinesses(createBusinessCards());
        getMainBusinessInfo();
        getMarketingDeptInfo();
    }, [])

    useEffect(() => { setBrands(createBrandCards()) }, [businesses])

    function getMainBusinessInfo() {
        $(`/public/business/main`, { businessId: channel },
            async (fail) => { },
            async (error) => { },
            async (success) => { setMainBusiness(await success.json()) })
    }

    function getMarketingDeptInfo() {
        $(`/public/marketingdept/main`, { businessId: channel },
            async (fail) => { },
            async (error) => { },
            async (success) => { setMarketingDeptInfo(await success.json()) })
    }

    function $(url, search, onFailed, onError, onSuccess) {
        GET(url + (search ? `?${new URLSearchParams(search).toString()}` : ``), onFailed, onSuccess, onError)
    }


    document.title = `Viajero Group of Companies`;

    const createBusinessCards = () => {
        return <Suspense fallback={<LoadingCard />}>
            <SuspenseBody
                wrappedPromiseData={awaitedQueryData('/public/businesses')}
                onCreateBody={(result) => {
                    if (result.fail) {
                    } else {
                        return result.map((business) => {
                            return <LazyLoadComponent>
                                <div className='l-b-item' key={business.businessId} onClick={(e) => { window.open(business.url) }}>
                                    <LazyLoadImage src={"/public/mgrbusiness/image?" + new URLSearchParams({ id: business.imageUUID, imageType: 80 }).toString()} />
                                    <div>
                                        <p>{business.businessName}</p>
                                        <p>{business.description}</p>
                                    </div>
                                    <Button className='lbm'>Explore</Button>
                                </div>
                            </LazyLoadComponent>
                        })
                    }
                }} />
        </Suspense>
    }

    const createBrandCards = () => {
        return <Suspense fallback={<LoadingCard />}>
            <SuspenseBody
                wrappedPromiseData={awaitedQueryData('/public/brands')}
                onCreateBody={(result) => {
                    if (result.fail) {
                    } else {
                        return <div id="p-p-imglist">{
                            result.map((brand) => {
                                return <LazyLoadComponent>
                                    <div className='brand-items' key={brand.brandId}  >
                                        <LazyLoadImage src={"/public/mgrproductbrand/image?" + new URLSearchParams({ id: brand.brandId, imageType: 80 }).toString()} />

                                        <p>{brand.name}</p>

                                    </div>
                                </LazyLoadComponent>
                            })
                        }</div>;
                    }
                }} />
        </Suspense>
    }


    return [
        <>
            <Helmet>
                <link rel="icon" href={`/head_landing/favicon.ico`} />
                <link rel="apple-touch-icon" href={`/head_landing/logo192.png`} />
                <link rel="manifest" href={`/head_landing/manifest.json`} />
            </Helmet>
            {dailyverses}
        </>,

        <div id="main_page" className='main-page'  >
            <div className='top_intro '>
                <div className='navigation-page'>
                    <div id='d-dropdownnav-logo'>
                        <img src={'/images/logo/vg-full.png'} />
                    </div>

                </div>
            </div>
            <div id='banner-page' >
                <div className='b-p-image'>
                    <picture class="b-p-pictureset">
                        <source srcset={`images/logo/viajero/mobile.png`} media="(max-width: 768px)" />
                        <source srcset={`images/logo/viajero/medium.png`} media="(min-width: 769px) and (max-width: 1920px)" />
                        <source srcset={`images/logo/viajero/large.png`} media="(min-width: 1921px)" />
                        <img loading="lazy" srcset={`images/logo/viajero/mobile.png`} alt="company building" />
                    </picture>
                    <div className="b-p-i-text">
                        <p>    Viajero Group of Companies, headed by Viajero Motorsales, Inc. with the aim to provide excellent services and customer satisfactory.
                        </p>
                    </div>
                </div>
            </div>
            <div className="business-page">
                <p className='area-title'>
                    OUR BUSINESS
                </p>
                <div className='center-ppg'  >

                    {businesses}
                </div>
            </div>
            <div className='partner-page'>
                <div className='center-page'  >
                    <p className='area-title'> A TRUSTED DEALER OF  </p>
                    <img loading="lazy" src={`images/landing/moto-group.png`} alt="motorcycles" className='p-p-moto-img' />
                </div>
                <div className='l-brands'>
                    {brands}
                </div>
            </div>
        </div >
        ,
        <div className='companyinfo-page' >
            <div className='center-page' >
                <div>
                    <p>Mission</p>
                    <p>
                        “To be an instrument in attaining life’s fulfillment by providing quality products and services at a reasonable price, thereby building trust and satisfaction to the community and for the coming generation.”
                    </p>
                </div>
                <div>
                    <p>Vision</p>
                    <p>
                        “A prestigious company that leads in the industry we exist through continued innovations.”
                    </p>
                </div>
            </div>
        </div>,
        <AppFooter logo={`/images/logo/vg-full.png`}
            channel={channel}
            setDailyverses={setDailyverses}
            verseType={verseType}
            mainBusiness={mainBusiness}
            marketingDeptInfo={marketingDeptInfo}
        />
    ]
}
