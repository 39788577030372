import React, { useState } from 'react';
import './Card.scss';
import SvgEks from "../../resources/Vector/SvgEks.jsx";
import SvgEdit from "../../resources/Vector/SvgEdit.jsx";
import { TbTrash } from "react-icons/tb";
import { TbEditCircle } from "react-icons/tb";
import { TbPencilCheck } from "react-icons/tb";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LoadingCard from './LoadingCard.jsx';
import { InputGroup } from 'react-bootstrap';

const Card = ({ isSvg, isItemIsBody, controlKey, isRounded, item, voided, onEdit, onVoidOrDelete, onUnvoid, icon, removeButtonText, onClick, addtlDesc }) => {

  const [callback, setCallBack] = useState(false);

  function onSetCallBack(callback) {
    setCallBack(callback);
  }

  function createButtons() {

    if (voided) {
      return <div className="card-actions">
        <button onClick={() => onUnvoid(item.data, onSetCallBack)} className="unvoid-btn" title="Set this item active">
          <TbPencilCheck /> Unvoid
        </button>
      </div>
    }

    return <div className="card-actions">
      <InputGroup>
        <button onClick={() => onEdit(item.data, onSetCallBack)} className="edit-btn" title="Edit this data"> <TbEditCircle /> Edit</button>
        <button onClick={() => onVoidOrDelete(item.data, onSetCallBack)} className="void-btn" title="Void or delete this data"><TbTrash /> {removeButtonText}</button>
      </InputGroup>
    </div>
  }

  return callback ?
    <div className="card card-callback" key={controlKey + "-callback"} >
      {callback}
    </div>
    :
    <div className="card" voided={voided ? "true" : "false"} key={controlKey} id={controlKey} onClick={onClick}>

      <div className="card-body">
        {isItemIsBody ? item.render  :
          <>
            <div className={"card-image-container" + (isRounded ? "" : " card-image-plain")}>
              {isSvg ? icon :
                <LazyLoadImage placeholder={<LoadingCard />} src={icon} />
              }

            </div>
            <div className="card-text-container">
              <h4>{item.title}</h4>
              {item.description}
              {addtlDesc}
            </div>

          </>


        }

      </div>
      {createButtons()}
    </div>
};

export default Card;
