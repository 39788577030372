
import { Suspense, useRef } from "react";
import "./Business.scss";
import TransactionTemplate from "../../Reusables/TransactionTemplate.jsx";
import { Button, Form } from "react-bootstrap";
import Card from "../Helper/Card.jsx";
import UpdatingCard from "../Helper/UpdatingCard.jsx";
import NoData from "../Helper/NoData.jsx";
import { awaitedSubmitData, awaitedQueryData } from "../Helper/PromiseWrapper.ts";
import SuspenseBody from "../Helper/SuspenseBody.jsx";
import Select from 'react-select';
import Toast from "./Toast.jsx";
import { v4 as uuidv4 } from 'uuid';
import { ValueValidator } from "../../Reusables/ValueValidator.jsx";

export default function Business(props) {

    function onTransactionFormClosed() {
        formDataRef.current = undefined;
        formBrandsRef.current = undefined;
        formDataImageRef.current = undefined;
    }


    const onFetchList = (page) => {
        transactionTemplate.setCards(<UpdatingCard />)
        fetch('/api/mgrbusiness/list?' +
            new URLSearchParams({ page: page, limit: transactionTemplate.getLimit(), voided: transactionTemplate.getVoided() }).toString())
            .then(response => { return response.json() })
            .then(response => {
                transactionTemplate.setTransactionList(response.response);
                transactionTemplate.setTotalPages(Math.ceil(response.totalCount / transactionTemplate.getLimit()));
            })
    }


    function createEntryForm(title, type, data, callback) {

        const isEdit = data ? true : false;

        formDataRef.current = data ? { ...data } : {};
        formBrandsRef.current = data ? data.brands : [];
        formDataImageRef.current = new Blob();

        const toggle = (e) => {
            const checkboxes = document.querySelectorAll('#toggleGroup input[type="checkbox"]');
            if (e.currentTarget.checked) { checkboxes.forEach(cb => { if (cb !== e.currentTarget) { cb.checked = false; } }); }
        };

        transactionTemplate.createEntryForm(title,
            <>
                <Form.Group className="mb-3" >
                    <Form.Label>Business ID</Form.Label>
                    <Form.Control className="default-focus" type="text" name="name"
                        onChange={(e) => formDataRef.current.businessId = e.currentTarget.value}
                        placeholder="Enter business id or abbreviation" defaultValue={data ? formDataRef.current.businessId : ""} />
                </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control type="text"
                        onChange={(e) => formDataRef.current.businessName = e.currentTarget.value}
                        placeholder="Enter business name" defaultValue={data ? formDataRef.current.businessName : ""} />
                </Form.Group>


                <Form.Group className="mb-3" >
                    <Form.Label>Short Description</Form.Label>
                    <Form.Control type="text"
                        onChange={(e) => formDataRef.current.description = e.currentTarget.value}
                        placeholder="Enter short description" defaultValue={data ? formDataRef.current.description : ""} />
                </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>URL (Open website address when clicked)</Form.Label>
                    <Form.Control type="text"
                        onChange={(e) => formDataRef.current.url = e.currentTarget.value}
                        placeholder="Enter an URL" defaultValue={data ? formDataRef.current.url : ""} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Business Logo {isEdit ? `(Optional)` : ``}</Form.Label>
                    <Form.Control type="file" accept="image/png, image/jpeg, image/webp, image/jpg"
                        onChange={(e) => { formDataImageRef.current = e.target.files[0] }} />
                </Form.Group>

                <Form.Label>Type</Form.Label>
                <Form.Group id="toggleGroup" className="mb-3">
                    <Form.Check inline label="Store" type="checkbox" defaultChecked={data ? formDataRef.current.store : false}
                        onChange={(e) => {
                            formDataRef.current.department = false;
                            formDataRef.current.store = new Boolean(e.currentTarget.value);
                            toggle(e)
                        }} />
                    <Form.Check inline label="Department" type="checkbox" defaultChecked={data ? formDataRef.current.department : false}
                        onChange={(e) => {
                            formDataRef.current.department = new Boolean(e.currentTarget.value);
                            formDataRef.current.store = false;
                            toggle(e)
                        }} />
                </Form.Group>

                <Suspense fallback={<UpdatingCard />}>
                    <SuspenseBody
                        wrappedPromiseData={awaitedQueryData('/api/mgrproductbrand/activelist?')}
                        onCreateBody={(result) => {
                            const options = result.map((e) => { return { value: e.brandId, label: e.name } })
                            let defaults = undefined;
                            if (formBrandsRef.current) { defaults = options.map((element) => { for (const id of formBrandsRef.current) { if (element.value === id) { return element } } }); }
                            return <Form.Group className="mb-3" >
                                <Form.Label>Product Brands (Can select multiple) (Optional)</Form.Label>
                                <Select
                                    isMulti
                                    options={options}
                                    menuPlacement="top"
                                    defaultValue={defaults}
                                    onChange={(option) => {
                                        formBrandsRef.current = option.map((obj) => obj.value)
                                    }} />
                            </Form.Group>
                        }} />
                </Suspense>
            </>,
            (type === "add" ?
                <Button onClick={(e) => { onSaveAddNew(e, callback) }}>Save New</Button> :
                <Button onClick={(e) => { onSaveEdit(e, data, callback) }}>Update</Button>));
    }


    const onAddNew = () => {
        createEntryForm("New Item", "add", undefined, transactionTemplate.setNewDataCallBack)
    }

    const onSaveAddNew = (e, callback) => {
        e.target.disabled = true;
        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(formDataRef.current.businessId, `Business ID`)
            .add(formDataRef.current.businessName, `Business Name`)
            .add(formDataRef.current.description, `Short Description`)
            .add(formDataRef.current.url, `URL`)
            .add((formDataRef.current.department || formDataRef.current.store) ? `t` : ``, `Type (Select atleast 1)`)
            .add(formDataImageRef.current.size === 0 ? `` : `1`, `Business Logo (Required)`)
            .validate()
            .get();
        if (!isValid) {
            e.target.disabled = false;
        }
        else {
            formDataRef.current.brands = formBrandsRef.current;
            const request = new FormData()
            request.append("business", new Blob([JSON.stringify(formDataRef.current)], { type: "application/json" }));
            request.append("image", formDataImageRef.current);
            callback(<Suspense fallback={<></>}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrbusiness/savenew', request)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            e.target.disabled = false;
                            transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                        } else {
                            onFetchList(transactionTemplate.getCurrentPage());
                            transactionTemplate.setEntryForm(undefined)
                            transactionTemplate.setToast(<Toast variant="success" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={"201 OK"} descriptionText={"Item has been created"} />)
                        }
                    }} />
            </Suspense>)
        }
    }

    const onEdit = (data, callback) => {
        createEntryForm("Modify Item [ " + data.businessName + " ]", "update", data, callback)
    }

    const onSaveEdit = (e, data, callback) => {
        e.target.disabled = true;

        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(formDataRef.current.businessId, `Business ID`)
            .add(formDataRef.current.businessName, `Business Name`)
            .add(formDataRef.current.description, `Short Description`)
            .add(formDataRef.current.url, `URL`)
            .add((formDataRef.current.department || formDataRef.current.store) ? `t` : ``, `Type (Select atleast 1)`)
            .validate()
            .get();
        if (!isValid) {
            e.target.disabled = false;
        }
        else {

            const map = new FormData()
            map.append("oldID", data.businessId);
            map.append("newID", formDataRef.current.businessId);
            map.append("businessName", formDataRef.current.businessName);
            map.append("description", formDataRef.current.description);
            map.append("url", formDataRef.current.url);
            map.append("department", formDataRef.current.department);
            map.append("store", formDataRef.current.store);
            map.append("brands", new Blob([JSON.stringify(formBrandsRef.current)], { type: "application/json" }));
            map.append("businessImage", formDataImageRef.current);
            map.append("imageUUID", formDataRef.current.imageUUID);

            callback(<Suspense fallback={<UpdatingCard />}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrbusiness/update', map)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            callback(undefined);
                            e.target.disabled = false;
                            transactionTemplate.setToast(<Toast
                                variant="danger"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={result.status + "  " + result.statusText}
                                descriptionText={result.response} />)
                        } else {
                            transactionTemplate.setTransactionList(transactionTemplate.getTransactionList().map(item => item === data ? result : item))
                            transactionTemplate.setEntryForm(undefined)
                            callback(undefined);
                            transactionTemplate.setToast(<Toast
                                variant="success"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={"201 OK"}
                                descriptionText={"Modification has been saved"} />)
                        }
                    }} />
            </Suspense>)
        }
    }



    function onUpdateStatus(data, callback, voided) {

        if (formDataRef.current) {
            transactionTemplate.setToast(<Toast
                variant="danger"
                onClose={() => { transactionTemplate.setToast(undefined) }}
                headingText={"405 Method Not Allowed"}
                descriptionText={"Your requested action is not allowed for currently editing data"} />)
            return;
        }

        const map = new FormData()
        map.append("id", data.businessId);
        map.append("voided", voided);
        callback(<Suspense fallback={<UpdatingCard />}>
            <SuspenseBody
                wrappedPromiseData={awaitedSubmitData('/api/mgrbusiness/updatestatus', map)}
                onCreateBody={(result) => {
                    if (result.fail) {
                        transactionTemplate.setToast(<Toast
                            variant="danger"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={result.status + "  " + result.statusText}
                            descriptionText={result.response} />)
                    } else {
                        transactionTemplate.setToast(<Toast
                            variant="success"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={"201 OK"}
                            descriptionText={"Modification has been saved"} />)
                        let pageNo = transactionTemplate.getCurrentPage();
                        if (transactionTemplate.getTransactionList().length === 1) { transactionTemplate.setCurrentPage(pageNo = transactionTemplate.getCurrentPage() - 1) }
                        onFetchList(pageNo);
                    }
                }}
            />
        </Suspense>)
    }

    const onUnvoid = (data, callback) => {
        onUpdateStatus(data, callback, false)
    }


    const onVoid = (data, callback) => {
        onUpdateStatus(data, callback, true)
    }

    function onCreateCards() {
        if (!transactionTemplate.getTransactionList()) { return <UpdatingCard /> }
        return transactionTemplate.getTransactionList().length === 0 ? NoData() : <div className="cardlist" key={"empty-list"}>
            {transactionTemplate.getTransactionList().map((data) => {
                return <Card
                    controlKey={data.businessId}
                    isRounded={false}
                    item={{ title: data.businessName, description: <small className="text-muted"><b>{data.businessId}</b></small>, data: data }}
                    voided={data.voided} onEdit={onEdit} onVoidOrDelete={onVoid} onUnvoid={onUnvoid}
                    icon={'/public/mgrbusiness/image?' + new URLSearchParams({ id: data.imageUUID, imageType: 90, genkey: uuidv4() }).toString()}
                    removeButtonText="Void" />;
            })}
        </div>
    }

    const formBrandsRef = useRef();
    const formDataRef = useRef();
    const formDataImageRef = useRef();
    const transactionTemplate = {};


    return <TransactionTemplate key={uuidv4()} controlId="txn-business" bind={transactionTemplate} imports={{ onFetchList: onFetchList, onCreateCards: onCreateCards, onAddNew: onAddNew, onFormClosed: onTransactionFormClosed }} />
}