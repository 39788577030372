import React, { useEffect, useRef, useState } from 'react';
import "./ProductCompare.scss";
import { Button } from 'reactstrap';
import { useOutletContext } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Select from 'react-select';
import UpdatingCard from '../../Helper/UpdatingCard';
import { v4 as uuidv4 } from 'uuid';

export default function ProductCompare(props) {

  const { poweproduct } = props;
  const { channel, onCompareView, $, _$ } = useOutletContext();
  const selectedKey = useRef()
  const [prodId1, setProdId1] = useState()
  const [prodId2, setProdId2] = useState()
  const [prodId3, setProdId3] = useState()
  const [display1, setDisplay1] = useState()
  const [display2, setDisplay2] = useState()
  const [display3, setDisplay3] = useState()
  const [image1, setImage1] = useState()
  const [image2, setImage2] = useState()
  const [image3, setImage3] = useState()
  const [default1, setDefault1] = useState()
  const [default2, setDefault2] = useState()
  const [default3, setDefault3] = useState()
  const [products, setProducts] = useState()
  const [uuid1, setUuid1] = useState(uuidv4())
  const [uuid2, setUuid2] = useState(uuidv4())
  const [uuid3, setUuid3] = useState(uuidv4())
  const onStorageUpdate = (event) => { if (event.key.startsWith("compare-")) { updateDisplay() } }
  document.title = `${channel === "VMI" ? "Viajero Motorsales Inc." : "Jicjam Cycle Inc."} - Compare`;

  useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); onLoad(); return () => { onUnload() } }, []);
  useEffect(() => { if (products) { if (prodId1) { setDefault1(getDefault(prodId1)) } else { setDisplay1(<></>); setImage1("") } } }, [prodId1, products])
  useEffect(() => { if (products) { if (prodId2) { setDefault2(getDefault(prodId2)) } else { setDisplay2(<></>); setImage2("") } } }, [prodId2, products])
  useEffect(() => { if (products) { if (prodId3) { setDefault3(getDefault(prodId3)) } else { setDisplay3(<></>); setImage3("") } } }, [prodId3, products])
  useEffect(() => { if (default1) { createDetails(default1, setDisplay1, setImage1, 0, setUuid1, setDefault1) } }, [default1])
  useEffect(() => { if (default2) { createDetails(default2, setDisplay2, setImage2, 1, setUuid2, setDefault2) } }, [default2])
  useEffect(() => { if (default3) { createDetails(default3, setDisplay3, setImage3, 2, setUuid3, setDefault3) } }, [default3])
  useEffect(() => { updateDisplay() }, [products]);


  function onLoad() {
    onCompareView(true);
    getAllProducts();
    updateDisplay()
    window.addEventListener("storage", onStorageUpdate);
  }

  function onUnload() {
    window.removeEventListener("storage", onStorageUpdate);
    onCompareView(false)
  }

  function getAllProducts() {
    $("/public/all/products", { businessId: channel, powerproduct: poweproduct },
      async (fail) => { },
      async (error) => { },
      async (success) => {
        const result = await success.json();
        const options = new Map();
        const groupedOptions = []
        result.map((product) => {
          var productByBrands = options.get(product.brandName)
          if (!productByBrands) { productByBrands = [] }
          productByBrands.push({ value: product, label: `${product.modelName} ${product.additionalModelName}  (${product.year})` })
          options.set(product.brandName, productByBrands)
        });
        for (const [key, value] of options.entries()) { groupedOptions.push({ label: key, options: value }) }
        if (groupedOptions.length !== 0) { setProducts(groupedOptions) }
      })
  }


  function updateDisplay() {
    const key = `compare-${channel}-${poweproduct ? "powerproduct" : "motorcycle"}`;
    const compareStorage = localStorage.getItem(key);
    const compareStorageArray = (compareStorage !== null) ? JSON.parse(compareStorage) : [];
    setUuid1(uuidv4())
    setUuid2(uuidv4())
    setUuid3(uuidv4())
    setProdId1(compareStorageArray[0])
    setProdId2(compareStorageArray[1])
    setProdId3(compareStorageArray[2])

  }

  function getDefault(productId) { 
    var defaultValue = undefined;
    products.some((item) => {
      return item.options.some((option) => {
        if (option.value.productId === productId) {
          defaultValue = option;
          return true;
        }
      })
    }
    )
    return defaultValue;
  }

  function createView(descriptor, image, defaultValue, index) {
    var uuid; var setUUID;
    switch (index) {
      case 0: { uuid = uuid1; setUUID = setUuid1 } break;
      case 1: { uuid = uuid2; setUUID = setUuid2 } break;
      case 2: { uuid = uuid3; setUUID = setUuid3 } break;
    }
    return <div className='item-card' >
      {image ? <LazyLoadImage src={image} /> : <div className='fk-img'> <span>NO PRODUCT SELECTED</span></div>}
      <Select className='select'
        key={uuid}
        formatGroupLabel={(data) => {
          return <div className="selection-group"  >
            <span className='group-name'> {data.label}</span>
            <span className='group-count'  >{data.options.length}</span>
          </div>
        }}
        defaultValue={defaultValue}
        options={products}
        onChange={(option) => {
          const key = `compare-${channel}-${poweproduct ? "powerproduct" : "motorcycle"}`;
          const compareStorage = localStorage.getItem(key);
          const compareStorageArray = (compareStorage !== null) ? JSON.parse(compareStorage) : [];
          compareStorageArray[index] = option.value.productId;
          localStorage.setItem(key, JSON.stringify(compareStorageArray))
          setUUID(uuidv4())
          updateDisplay()
        }}
      />
      <div className='details'>
        {descriptor}
      </div>
    </div>
  }


  function createDetails(option, setter, imageSetter, index, setUUID, setDefault) {
    const param = { productId: option.value.productId, imageName: option.value.variationImageUUID, type: "v", quality: 70 }
    const infOnClick = (key) => {
      selectedKey.current = key;
      var refs = document.querySelectorAll(`.det-pair._selected`)
      if (refs) { refs.forEach((element) => { element.classList.remove("_selected") }) }
      refs = document.querySelectorAll(`.${key}`)
      if (refs) { refs.forEach((element) => { element.classList.add("_selected") }) }
    }
    const createInfPair = (key, keyText, value) => {
      return <div key={key} className={`det-pair  ${key} ${selectedKey.current === key ? "_selected" : ""}`}
        onClick={(e) => { infOnClick(`${key}`) }}  >
        <span className='key'>{keyText}</span>
        <span className='value'>{value}</span>
      </div>
    }
    imageSetter("/public/mgrproduct/getimage?" + new URLSearchParams(param).toString())
    setter(< >
      <Button onClick={(e) => {
        const key = `compare-${channel}-${poweproduct ? "powerproduct" : "motorcycle"}`;
        const compareStorage = localStorage.getItem(key);
        const compareStorageArray = (compareStorage !== null) ? JSON.parse(compareStorage) : [];
        compareStorageArray[index] = null;
        localStorage.setItem(key, JSON.stringify(compareStorageArray))
        setDefault(undefined)
        updateDisplay()
        setUUID(uuidv4())
      }}>Remove</Button>
      <div className='det-group prod-name'>
        {`${option.value.modelName} ${option.value.additionalModelName}`}
      </div>
      {createInfPair(`_brand`, `Brand`, option.value.brandName)}
      {createInfPair(`_modelCode`, `Model Code`, option.value.modelCode)}
      {createInfPair(`_modelYear`, `Model Year`, option.value.year)}
      <hr />
      <div className='det-group spec-name'>
        {`SPECIFICATIONS`}
      </div>
      {_$(`/public/productinfo/specs`, { productId: option.value.productId },
        (fail) => { }
        , (specs) => {
          const dets = [];
          var group = "";
          specs.forEach((spec) => {
            if (group !== spec.group) {
              group = spec.group;
              dets.push(<div className='det-group'>
                {group}
              </div>)
            }
            const classStyleRef = `${group.replace(/ /g, '_')}_${spec.key.replace(/ /g, '_')}`
            if (classStyleRef !== `ENGINE_REGISTERED_DISPLACEMENT`) {
              dets.push(<div
                key={classStyleRef}
                className={`det-pair ${classStyleRef} ${selectedKey.current === classStyleRef ? "_selected" : ""}`}
                onClick={(e) => {
                  selectedKey.current = classStyleRef;
                  var refs = document.querySelectorAll(`.det-pair._selected`)
                  if (refs) { refs.forEach((element) => { element.classList.remove("_selected") }) }
                  refs = document.querySelectorAll(`.${classStyleRef}`)
                  if (refs) { refs.forEach((element) => { element.classList.add("_selected") }) }
                }}>
                <span className='key'>{spec.key}</span>
                <span className='value'>{spec.value}</span>
              </div>)
            }
          }
          )
          return dets;
        })}
    </ >
    )
  }

  return <div id='compare-products' style={channel === 'VMI' ? { "--color": "58, 123, 189" } : { "--color": "194, 2, 2" }}>
    {products ?
      <>
        {createView(display1, image1, getDefault(prodId1), 0)}
        {createView(display2, image2, getDefault(prodId2), 1)}
        {createView(display3, image3, getDefault(prodId3), 2)}
      </> :
      <UpdatingCard />}
  </div>

}
