import React from 'react';
import './UpdatingCard.scss'; // Import CSS for styling

const UpdatingCard = () => {
  return (
    <div className="updating-card">
      <div className="loader"></div>
    </div>
  );
};

export default UpdatingCard;
