import React, { useEffect, useState } from 'react';
import { FaFacebook, FaFacebookMessenger, FaPhoneAlt } from 'react-icons/fa';
import { FaMapLocation, FaMobileButton } from "react-icons/fa6";
import { IoArrowBackSharp } from "react-icons/io5";
import { Button } from 'reactstrap';
import Fetcher from '../../Helper//Fetcher';
import StringUtils from '../../Helper//StringUtils';
import '../VenoBox/venobox.min.scss';
import VenoBox from '../VenoBox/venobox.min.jsx';
import './StoreLocator.scss';

export default function StoreLocatorInformation(props) {

    const [branchImages, setBranchImages] = useState([]);
    const [locationData] = useState(props.location.data);

    useEffect(() => {
        document.title = "Our Stores -  "+locationData.branch.name;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (locationData !== undefined)
            Fetcher.onFetchBranchImages(locationData.branch.vglocationId, (result) => { setBranchImages(result); })
        else
            props.history.push('/storelocator')
    }, []);

    useEffect(() => {
        if (branchImages.length !== 0)
            new VenoBox({ fitView: true, numeration: true, infinigall: true, share: false, spinner: 'rotating-plane' })
    }, [branchImages]);

    const renderSocialMediaButtons = (array) => {
        const tag = [];
        for (let x of array) {
            const button = x.startsWith('https://www.facebook.com') ?
                <button className='art_shadow' ><FaFacebook className='fa-icon' /> {'Follow Us'}</button> :
                <button className='art_shadow' ><FaFacebookMessenger className='fa-icon' /> {'Message Us'}</button>;
            tag.push(<span><a target='_blank' rel='noopener noreferrer' href={x}> {button} </a></span>);
        }
        return <div>{tag}</div>;
    }

    const renderBranchInformation = (branch) => {
        return <div className="item-desc">
            <img id='item-logo' src={props.logo} />
            <strong className="item-title">{branch.name.toUpperCase()}</strong>
            <strong className="item-qoute"  >{'Your ride starts here'}</strong>
            <figure id='branch_tag'  > {props.location.tag.createTags(locationData.branch)}  </figure>
            {renderBranchNumbers(branch)}
            {renderSocialMediaButtons([branch.facebook, branch.messenger])}
        </div>;
    }

    const renderBranchNumbers = (branch) => {
        const tag = [<span className='_contact'><div><FaMapLocation className='svg_contact'/></div>  <div> {branch.address}</div></span>];
        if (!StringUtils.isNullOrEmpty(branch.phoneNumber)) {
            for (let text of StringUtils.splitByComma(branch.phoneNumber))
                tag.push(<span className='_contact'><div><FaPhoneAlt className='svg_contact'/></div> <div>{text}</div></span>)
        }
        if (!StringUtils.isNullOrEmpty(branch.mobileNumber)) {
            for (let text of StringUtils.splitByComma(branch.mobileNumber))
                tag.push(<span className='_contact'><div><FaMobileButton className='svg_contact'/></div>  <div>{text}</div> </span>)
        }
        return <div className='_contact'>{tag}</div>;
    }

    const renderBranchImages = () => {
        const tag = [], imageFolder = `/images/branches/${locationData.branch.vglocationId}/`;
        for (let image of branchImages) {
            const ref = imageFolder + image;
            tag.push(
                <a className="venobox" data-gall="myGallery" href={ref}>
                    <figure>
                        <img loading="lazy"
                            className='art_shadow'
                            src={ref} data-src-pc={ref}
                            data-src-tablet={ref}
                            alt={image} />
                    </figure>
                </a>);
        }
        return tag;
    }

    const onClickBack = (event) => { props.history.push('/storelocator') }

    if (locationData === undefined) {
        return <></>;
    }


    return <div id="main_page" business={props.businessId} className='store_main'>

        <Button color='primary'
            business={props.businessId}
            onClick={() => { onClickBack() }}>
            <IoArrowBackSharp /> Back to branches
        </Button>

        <div className='selectedbranch_textinf'>
            <div id="center_page">
                <div className="text-area">
                    {renderBranchInformation(locationData.branch)}
                    <div className='map_group'>
                        <iframe

                            title='google_map'
                            className='art_shadow'
                            src={locationData.branch.mapURI}
                            allowfullscreen=""
                            loading="lazy"

                            referrerpolicy="no-referrer-when-downgrade" />
                    </div>
                </div>
            </div>
        </div>

        <div id="center_page"
            className='store_secondary'>
            <div className='head_images'  >
                {renderBranchImages()}
            </div>
        </div>

    </div>;

}

