import React, { useEffect, useMemo, useState } from 'react';
import './DynamicEntry.scss';
import { Button } from 'react-bootstrap';
import { RiAddCircleLine } from "react-icons/ri";
import { v4 as uuidv4 } from 'uuid';

export default function DynamicEntry({ defaultValues, onCreateRenderer, onChanged ,buttonText}) {

    const [items, setItems] = useState([]);

    useEffect(() => { if (defaultValues) setItems(defaultValues) }, []);
    useEffect(() => { onChanged(items) }, [items]);

    return <div className='dynamic-entry'>
        {items.map((object) => onCreateRenderer(
            object,
            (e) => { setItems(items.filter((value) => value.key !== object.key)) },
            () => { onChanged(items) }))}
        <Button className='dynamic-entry-button'
            onClick={(e) => { setItems([...items, { key: uuidv4(), data: {} }]) }}>
            <RiAddCircleLine /> {buttonText}
        </Button>
    </div>

}


