
import { Suspense, useEffect, useRef, useState } from "react";
import "./Product.scss";
import TransactionTemplate from "../../Reusables/TransactionTemplate.jsx";
import { Button, Card as ImgCard, Carousel, Form, InputGroup, Tab, Tabs, Badge } from "react-bootstrap";
import UpdatingCard from "../Helper/UpdatingCard.jsx";
import NoData from "../Helper/NoData.jsx";
import { awaitedSubmitData, awaitedQueryData } from "../Helper/PromiseWrapper.ts";
import SuspenseBody from "../Helper/SuspenseBody.jsx";
import Toast from "./Toast.jsx";
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";
import TransactionList from "../../Reusables/TransactionList.jsx";
import Select from 'react-select';
import DynamicEntry from "../Helper/DynamicEntry.jsx";
import ProductVariationGroup from "./ProductVariationGroup.jsx";
import ProductFeaturesGroup from "./ProductFeaturesGroup.jsx";
import ProductManualSpec from "./ProductManualSpec.jsx";
import { v4 as uuidv4 } from 'uuid';
import Card from "../Helper/Card.jsx";
import ListGroup from 'react-bootstrap/ListGroup';
import { TbEditCircle } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { GET } from "../../Reusables/FetchHandler.jsx";
import { ValueValidator } from "../../Reusables/ValueValidator.jsx";


export default function Product(props) {




    const onFetchBrandList = () => {
        setBrandCards(<UpdatingCard />)
        setSelectedBrand(undefined);
        GET('/api/mgrproductbrand/activelist',
            async (failedResponse) => { navigate("../../controlpanel"); },
            async (successResponse) => { setBrandList(await successResponse.json()); },
            async (errorResponse) => { }
        )

    }

    const onFetchModelList = () => {
        if (selectedBrand) {
            modelTemplate.setTransactionList(undefined)
            setSelectedModel(undefined)
            setSelectedProduct(undefined)
            removeSelected(".ctmd-md-nav[selected=\"true\"]");
            fetch('/api/mgrproductmodel/activelist?' + new URLSearchParams({ brandId: selectedBrand.brandId }).toString(), {
                method: 'GET',

            })
                .then(response => {
                    if (response.status === 403) {
                        navigate("/auth");
                    } else {
                        return response.json()
                    }
                })
                .then(response => {
                    modelTemplate.setTransactionList(response);
                    if (response.length === 0) {
                        transactionTemplate.setTransactionList([])
                        transactionTemplate.setTotalPages(0);
                        document.querySelector(".ctmd-md-nav").setAttribute("selected", "true");
                    }
                })
        }
    }

    const onFetchList = (page) => {

        if (selectedModel) {
            removeSelected(".ctmd-md-nav[selected=\"true\"]");
            transactionTemplate.setTransactionList(undefined)


            fetch('/api/mgrproduct/list?' +
                new URLSearchParams({ modelId: selectedModel.modelId, page: page, limit: transactionTemplate.getLimit(), voided: transactionTemplate.getVoided() }).toString(),
                {
                    method: 'GET',
                })
                .then(response => {
                    if (response.status === 403) {
                        navigate("/auth");
                    } else {
                        return response.json()
                    }
                })
                .then(response => {
                    transactionTemplate.setTransactionList(response.response);
                    transactionTemplate.setTotalPages(Math.ceil(response.totalCount / transactionTemplate.getLimit()));

                })
        } else {
            transactionTemplate.setTransactionList([])
            transactionTemplate.setTotalPages(0);
            document.querySelector(".ctmd-md-nav").setAttribute("selected", "true");
        }
    }

    const onDisplaySpecification = () => {
        if (selectedProduct) {
            return <Suspense fallback={<UpdatingCard />}>
                <SuspenseBody
                    wrappedPromiseData={awaitedQueryData('/api/mgrproduct/getspecification?' + new URLSearchParams({ productId: selectedProduct.productId }).toString())}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                            return <div>Fetching error</div>
                        } else {
                            prodDetSpecifications.current = new Map(Object.entries(result))
                            let currentGroup;
                            const pairs = [];
                            for (const [key, value] of prodDetSpecifications.current) {
                                const keygroup = key.split('$');
                                if (currentGroup !== keygroup[0]) { currentGroup = keygroup[0]; pairs.push(<label className="mt-3 spec-binary-group"><strong>{currentGroup}</strong></label>) }
                                pairs.push(<InputGroup className="spec-binary-pair"><label>{keygroup[1]}</label><label>{value}</label></InputGroup>)
                            }

                            return <div className="d-flex flex-column">
                                {txnpGroup("Specifications", undefined, onEditSpecifications)}
                                <div className="spec-binary txnp-body ">{pairs}</div>
                            </div>

                        }
                    }}
                />
            </Suspense>
        }
    }


    const onDisplayIntro = () => {
        if (selectedProduct) {
            return <Suspense fallback={<UpdatingCard />}>
                <SuspenseBody
                    wrappedPromiseData={awaitedQueryData('/api/mgrproduct/getintro?' + new URLSearchParams({ productId: selectedProduct.productId }).toString())}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                            return <div>Fetching error</div>
                        } else {
                            prodDetIntroduction.current = result;
                            return <div className="d-flex flex-column">
                                {txnpGroup("Introduction", undefined, onEditIntroduction)}
                                <div className="txnp-body ">
                                    <center> <strong >{prodDetIntroduction.current.tagLine}</strong></center>
                                    <p className="preserve">{prodDetIntroduction.current.texts}</p></div>
                            </div>
                        }
                    }}
                />
            </Suspense>
        }
    }

    const onDisplayFeatures = () => {
        if (selectedProduct) {
            return <Suspense fallback={<UpdatingCard />}>
                <SuspenseBody
                    wrappedPromiseData={awaitedQueryData('/api/mgrproduct/getfeatures?' + new URLSearchParams({ productId: selectedProduct.productId }).toString())}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            transactionTemplate.setToast(<Toast
                                variant="danger"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={result.status + "  " + result.statusText}
                                descriptionText={result.response} />)
                            return <div>Fetching error</div>
                        } else {
                            prodDetFeatures.current = result;

                            return <div className="d-flex flex-column">
                                {txnpGroup("Features", prodDetFeatures.current.length, onEditFeatures)}
                                {(prodDetFeatures.current = result).map((feature) => {
                                    return <div className=" txnp-body  ">
                                        {txnpFeatureGroup(feature)}
                                    </div>
                                })}
                            </div>

                        }
                    }}
                />
            </Suspense>
        }
    }

    const onDisplayVariations = () => {

        const createEmptyImgCard = (length) => {
            const elements = []; for (let i = 0; i < length; i++) { elements.push(<ImgCard className="variation-card"></ImgCard>) }
            return elements;
        }

        if (selectedProduct) {
            return <Suspense fallback={<UpdatingCard />}>
                <SuspenseBody
                    wrappedPromiseData={awaitedQueryData('/api/mgrproduct/getvariations?' + new URLSearchParams({ productId: selectedProduct.productId }).toString())}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                            return <div>Fetching error</div>
                        } else {
                            prodDetVariations.current = result;
                            return <div className="txnp-variation">
                                {txnpGroup("Variations", prodDetVariations.current.length, onEditVariation)}
                                <div className="txnp-body txnp-var-grid">
                                    {prodDetVariations.current.map((data) => {
                                        return <ImgCard className="variation-card">
                                            <ImgCard.Img variant="top" src={'/public/mgrproduct/getimage?' + new URLSearchParams({ productId: data.productId, type: "v", imageName: data.imageUUID, quality: 80, genkey: uuidv4() }).toString()} />
                                            <ImgCard.Body>
                                                <ImgCard.Text><strong>  {data.variationName}</strong></ImgCard.Text>
                                            </ImgCard.Body>
                                        </ImgCard>;
                                    })}
                                    {prodDetVariations.current.length < 2 ? <>{createEmptyImgCard(2 - prodDetVariations.current.length)}</> : <></>}
                                </div>
                            </div>
                        }
                    }
                    }
                />
            </Suspense >
        }
    }


    const txnpGroup = (text, count, action) => {
        return <div className="listgroup-header   mb-2">
            <span>{text}</span>
            <Badge bg="primary" pill>{count}</Badge>
            <div className="listgroup-control">
                <Button className="ml-auto " onClick={action} disabled={selectedProduct.voided}><TbEditCircle />
                    Edit
                </Button>
            </div>
        </div>
    }


    const txnpFeatureGroup = (data) => {
        return <div className="listgroup-features d-flex flex-row">
            <LazyLoadImage src={'/public/mgrproduct/getimage?' + new URLSearchParams({ productId: data.productId, type: "f", imageName: data.imageUUID, quality: 90, genkey: uuidv4() }).toString()} />
            <div className="lgf-texts d-flex flex-column">
                <p><b>{data.featureName}</b></p>
                <p className="preserve">{data.featureDescription}</p>
            </div>
        </div>
    }



    const loadDefaultFocus = (e) => {
        const element = document.getElementsByClassName("default-focus");
        if (element.length !== 0) { element[0].focus() }
    }


    function createBasicInfoEntry(defaultData, defaultTypeIds) {
        const isEdit = defaultData ? true : false;
        formBasicInfoRef.current = defaultData ? { ...defaultData } : {};
        formBasicInfoRef.current.modelId = selectedModel.modelId;
        formTypesRef.current = defaultTypeIds ? [...defaultTypeIds] : [];
        formModelImgRef.current = {};

        return <Tab eventKey="pg=1" title="Basic Info">
            <div>
                <Form.Check label="Add to home page featured product" type="checkbox" defaultChecked={formBasicInfoRef.current.featured} onChange={(e) => {
                    formBasicInfoRef.current.featured = e.currentTarget.checked
                }} />
                <Form.Group className="mb-3" >
                    <Form.Label>Product Name</Form.Label>
                    <InputGroup  >
                        <InputGroup.Text > {selectedBrand.name.toUpperCase() + " " + selectedModel.name.toUpperCase()} </InputGroup.Text>
                        <Form.Control className="default-focus"
                            placeholder="Enter product model additional name"
                            defaultValue={formBasicInfoRef.current.additionalModelName}
                            onChange={(e) => formBasicInfoRef.current.additionalModelName = new String(e.currentTarget.value)}
                            onLoad={loadDefaultFocus} />
                    </InputGroup>
                    <Form.Text className="text-muted">Input the CC number if no additional name specified</Form.Text></Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Manufacturer Model Code</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter model code"
                        defaultValue={formBasicInfoRef.current.modelCode}
                        onChange={(e) => formBasicInfoRef.current.modelCode = new String(e.currentTarget.value)} />
                    <Form.Text className="text-muted">A series of letters mostly four or more to track specific product </Form.Text></Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Model Icon {isEdit ? `(Optional)` : ``}</Form.Label>
                    <Form.Control
                        type="file"
                        accept="image/png, image/jpeg, image/webp, image/jpg"
                        defaultValue={formModelImgRef.current.modelImage}
                        onChange={(e) => { formModelImgRef.current.modelImage = e.target.files[0] }} />
                    <Form.Text className="text-muted">Note: Model icon is not the product image. An icon represents the model code</Form.Text> </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Model Year</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter product model year"
                        defaultValue={formBasicInfoRef.current.year}
                        onChange={(e) => formBasicInfoRef.current.year = parseInt(e.currentTarget.value)} /></Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Other Classification (Optional)</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter other classification"
                        defaultValue={formBasicInfoRef.current.classification}
                        onChange={(e) => formBasicInfoRef.current.classification = new String(e.currentTarget.value)} />
                    <Form.Text className="text-muted">Can be empty or ignored. Classification can be a ''construction tools'', ''boat engine'' or related</Form.Text></Form.Group>



                <Suspense fallback={<UpdatingCard />}>
                    <SuspenseBody
                        wrappedPromiseData={awaitedQueryData('/api/mgrproducttype/activelist?' + new URLSearchParams
                            ({ category: (selectedBrand.powerProduct ? "Powerproducts" : "Motorcycle") }).toString())}
                        onCreateBody={(result) => {
                            const options = result.map((e) => { return { value: e.typeId, label: e.name } })
                            let defaults = undefined;
                            if (formTypesRef.current) { defaults = options.map((element) => { for (const id of formTypesRef.current) { if (element.value === id) { return element } } }); }
                            return <Form.Group className="mb-3" >
                                <Form.Label>Product Type (Can select multiple)</Form.Label>
                                <Select
                                    isMulti
                                    options={options}
                                    menuPlacement="top"
                                    defaultValue={defaults}
                                    onChange={(option) => formTypesRef.current = option.map((obj) => obj.value)} />
                            </Form.Group>
                        }} />
                </Suspense>

            </div>
        </Tab>

    }

    function createIntroductionEntry(defaultIntro) {
        formIntroductionRef.current = defaultIntro ? defaultIntro : { tagLine: undefined, texts: undefined };
        return <Tab eventKey="pg=2" title="Introduction">
            <div>
                <Form.Group className="mb-3" >
                    <Form.Label>Tag Line</Form.Label>
                    <Form.Control className="default-focus" type="text" placeholder="Product Tag Line" defaultValue={formIntroductionRef.current.tagLine} onChange={(e) => formIntroductionRef.current.tagLine = e.currentTarget.value} />
                    <Form.Text className="text-muted">A text that can gain customer attention e.g 'THE UNDERBONE KING'</Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Descriptions</Form.Label>
                    <Form.Control as="textarea" rows={18} defaultValue={formIntroductionRef.current.texts} onChange={(e) => formIntroductionRef.current.texts = e.currentTarget.value} />
                </Form.Group>
            </div>
        </Tab>
    }



    function createSpecificationsEntry(defaultSpec) {
        formSpecsRef.current = new Map();
        formOtherSpecsRef.current = [];
        if (defaultSpec) {
            defaultSpec.forEach((value, key) => {
                if (!key.startsWith("ENGINE$") & !key.startsWith("CHASSIS$") & !key.startsWith("DIMENSION$")) {
                    const result = key.split("$");
                    formOtherSpecsRef.current.push({ group: result[0], key: result[1], value: value })
                } else {
                    formSpecsRef.current.set(key, value)
                }
            });


        }

        return <Tab eventKey="pg=4" title="Specification" >
            <center> <Form.Text className="text-muted mb-3">Everything in this page is not required. Just provide those with known values from manufacturer</Form.Text></center>
            <Form.Group className="mb-3 engine spec-group">  <hr />
                <Form.Label><center><strong>ENGINE</strong></center></Form.Label>
                {createSpecsTextInpuGroup("Max Power", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Max Torque", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Engine Type", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Bore X Stroke", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Displacement", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Registered Displacement", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) }, true)}
                {createSpecsTextInpuGroup("Compression Ratio", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Fuel System", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Fuel Type", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Cooling System", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Lubrication", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Oil Capacity", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Starting", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Ignition System", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Transmission Type", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Clutch Type", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Battery Type", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Fuel Consumption", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Gear Shift Pattern", "ENGINE$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}  <hr />
            </Form.Group>
            <Form.Group className="mb-3 chassis spec-group">
                <Form.Label><center><strong>CHASSIS</strong></center></Form.Label>
                {createSpecsTextInpuGroup("Front Suspension", "CHASSIS$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Rear Suspension", "CHASSIS$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Front Tire Size", "CHASSIS$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Rear Tire Size", "CHASSIS$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Front Brake", "CHASSIS$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Rear Brake", "CHASSIS$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                <hr />
            </Form.Group>
            <Form.Group className="mb-3 dimension spec-group">
                <Form.Label><center><strong>DIMENSION</strong></center></Form.Label>
                {createSpecsTextInpuGroup("Fuel Tank Capacity", "DIMENSION$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Overall Length", "DIMENSION$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Overall Width", "DIMENSION$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Overall Height", "DIMENSION$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Wheelbase", "DIMENSION$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Ground Or Road Clearance", "DIMENSION$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Curb Mass", "DIMENSION$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                {createSpecsTextInpuGroup("Seat Height", "DIMENSION$", (key, value) => { formSpecsRef.current.set(key, new String(value)) })}
                <hr />
            </Form.Group>
            <Form.Group className="mb-3 others spec-group">
                <Form.Label><center><strong>OTHERS (MANUAL)</strong></center></Form.Label>
                <div className="others-label"><span>Group</span><span>Key</span><span>Value</span></div>
                <DynamicEntry
                    defaultValues={formOtherSpecsRef.current.map((data) => { return { key: uuidv4(), data: { group: data.group, key: data.key, value: data.value } } })}
                    onCreateRenderer={(object, onRemove, onDataChanged) =>
                        <ProductManualSpec key={object.key} componentId={object.key} data={object.data} onRemove={onRemove} onDataChanged={onDataChanged} />}
                    onChanged={(values) => { formOtherSpecsRef.current = values }} />
            </Form.Group>
        </Tab>

    }

    function createSpecsTextInpuGroup(label, group, onChange, number) {
        const key = group + label.toUpperCase();
        return <InputGroup  >
            <InputGroup.Text className={"spec-tag"}>{label}</InputGroup.Text>
            <Form.Control className={"spec-input"} type={number ? "number" : "text"} defaultValue={formSpecsRef.current.get(key)} onChange={(e) => onChange(key, e.currentTarget.value)} />
        </InputGroup>;
    }

    function createFeaturesEntry(defaultFeature) {

        formFeaturesRef.current = defaultFeature ? defaultFeature : [];

        return <Tab eventKey="pg=3" title="Features" className="spec-feature">
            <DynamicEntry

                defaultValues={formFeaturesRef.current.map((data) => {
                    return {
                        key: data.imageUUID,
                        data: {
                            defaultImage: '/public/mgrproduct/getimage?' + new URLSearchParams({ productId: data.productId, type: "f", imageName: data.imageUUID, quality: 80, genkey: uuidv4() }).toString(),
                            key: data.imageUUID,
                            value: {
                                featureName: data.featureName,
                                featureDescription: data.featureDescription
                            },
                            objectId: data.featureId,
                            imageUUID: data.imageUUID
                        }
                    }
                })}

                onCreateRenderer={(object, onRemove, onDataChanged) => {
                    return <ProductFeaturesGroup
                        key={object.key}
                        componentId={object.key}
                        data={object.data}
                        onRemove={onRemove}
                        onDataChanged={onDataChanged} />
                }}

                onChanged={(values) => { formFeaturesRef.current = values }} />
        </Tab>

    }

    function createVariationsEntry(defaultVariations) {
        formVariationsRef.current = defaultVariations ? defaultVariations : [];
        return <Tab eventKey="pg=5" title="Variation" className="spec-variation">
            <DynamicEntry
                defaultValues={formVariationsRef.current.map((data) => {
                    return {
                        key: data.imageUUID,
                        data: {
                            defaultImage: '/public/mgrproduct/getimage?' + new URLSearchParams({ productId: data.productId, type: "v", imageName: data.imageUUID, quality: 80, genkey: uuidv4() }).toString(),
                            key: data.imageUUID,
                            value: data.variationName,
                            objectId: data.variationId,
                            imageUUID: data.imageUUID
                        }
                    }
                })}
                onCreateRenderer={(object, onRemove, onDataChanged) => {
                    return <ProductVariationGroup
                        key={object.key}
                        componentId={object.key}
                        data={object.data}
                        onRemove={onRemove}
                        onDataChanged={onDataChanged} />
                }}
                onChanged={(values) => { formVariationsRef.current = values }} />
        </Tab>
    }

    function onAddNew() {
        const title = "New Item";
        const callback = transactionTemplate.setNewDataCallBack;
        transactionTemplate.createEntryForm(title,
            <Tabs id="form-tab" defaultActiveKey={"pg=1"} className="mb-3"  >
                {createBasicInfoEntry()}
                {createIntroductionEntry()}
                {createFeaturesEntry()}
                {createSpecificationsEntry()}
                {createVariationsEntry()}
            </Tabs>,
            <Button onClick={(e) => { onSaveAddNew(e, callback) }}>Save New</Button>)
    }

    function onSaveAddNew(e, callback) {
        e.target.disabled = true;
        formBasicInfoRef.current.typeIds = formTypesRef.current;
        formBasicInfoRef.current.brandId = selectedBrand.brandId;
        const request = new FormData(), featuresData = [], variationsData = [];
        const otherSpecMap = new Map(); for (const item of formOtherSpecsRef.current) { item.data.group = item.data.group ? item.data.group : "OTHERS"; otherSpecMap.set(item.data.group.toUpperCase() + "$" + item.data.key, item.data.value) }
        for (const item of formFeaturesRef.current) { featuresData.push({ featureName: item.data.value.featureName, featureDescription: item.data.value.featureDescription }) }
        for (const item of formVariationsRef.current) { variationsData.push({ variationName: item.data.value }) }


        const valueValidator = new ValueValidator((message) => { transactionTemplate.setToast(message) })
        valueValidator.add(formBasicInfoRef.current.additionalModelName, `Product Name`)
        valueValidator.add(formBasicInfoRef.current.modelCode, `Manufacturer Model Code`)
        valueValidator.add(formModelImgRef.current.modelImage, `Model Icon`)
        valueValidator.add(formBasicInfoRef.current.year, `Model Year`)
        valueValidator.add(formBasicInfoRef.current.typeIds, `Product Type (Select atleast 1)`)

        if (!selectedBrand.powerProduct) {
            valueValidator.add(formIntroductionRef.current.tagLine, `Introduction - Tag Line`)
            valueValidator.add(formIntroductionRef.current.texts, `Introduction - Descriptions`)
            valueValidator.add((formSpecsRef.current.size + otherSpecMap.size) === 0 ? `` : `t`, `Specification`)
        }

        valueValidator.add(variationsData, `Variations (Required atleast 1)`)
        valueValidator.validate()
        const isValid = valueValidator.get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {
            for (const item of formFeaturesRef.current) { request.append("featuresImages", item.data.file); }
            for (const item of formVariationsRef.current) { request.append("variationsImages", item.data.file); }
            request.append("product", new Blob([JSON.stringify(formBasicInfoRef.current)], { type: "application/json" }));
            request.append("modelImage", formModelImgRef.current.modelImage);
            request.append("specification", new Blob([JSON.stringify(Object.fromEntries(formSpecsRef.current))], { type: 'application/json' }))
            request.append("otherSpecification", new Blob([JSON.stringify(Object.fromEntries(otherSpecMap))], { type: 'application/json' }))
            request.append("introduction", new Blob([JSON.stringify(formIntroductionRef.current)], { type: 'application/json' }))
            request.append("features", new Blob([JSON.stringify(featuresData)], { type: "application/json" }));
            request.append("variations", new Blob([JSON.stringify(variationsData)], { type: "application/json" }));

            callback(<Suspense fallback={<></>}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrproduct/savenew', request)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            e.target.disabled = false;
                            transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                        } else {
                            onFetchList(transactionTemplate.getCurrentPage());
                            transactionTemplate.setEntryForm(undefined)
                            transactionTemplate.setToast(<Toast variant="success" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={"201 OK"} descriptionText={"Item has been created"} />)
                        }
                    }} />
            </Suspense>)
        }
    }

    function onEditIntroduction(e) {
        const title = "Edit Introduction";
        const callback = setDisplayIntro;
        transactionTemplate.createEntryForm(title,

            <Tabs id="form-tab" defaultActiveKey={"pg=2"} className="mb-3"  >
                {createIntroductionEntry(prodDetIntroduction.current)}
            </Tabs>,

            <Button onClick={(e) => {

                const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
                    .add(formIntroductionRef.current.tagLine, `Introduction - Tag Line`)
                    .add(formIntroductionRef.current.texts, `Introduction - Descriptions`)
                    .validate()
                    .get();

                if (!isValid) {
                    e.target.disabled = false;
                } else {
                    const request = new FormData();
                    request.append("productId", selectedProduct.productId);
                    request.append("introduction", new Blob([JSON.stringify(formIntroductionRef.current)], { type: 'application/json' }))

                    callback(<Suspense fallback={<UpdatingCard />}>
                        <SuspenseBody
                            wrappedPromiseData={awaitedSubmitData('/api/mgrproduct/updateintro', request)}
                            onCreateBody={(result) => {
                                if (result.fail) {
                                    e.target.disabled = false;
                                    transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                                } else {

                                    transactionTemplate.setEntryForm(undefined)
                                }
                                return onDisplayIntro();
                            }} />
                    </Suspense>)

                }
            }}>Update</Button>)
    }

    function onEditVariation(e) {
        const title = "Edit Variations";
        const callback = setDisplayVariations;
        transactionTemplate.createEntryForm(title,
            <Tabs id="form-tab" defaultActiveKey={"pg=5"} className="mb-3"  >
                {createVariationsEntry(prodDetVariations.current)}
            </Tabs>,
            <Button onClick={(e) => {
                const request = new FormData(), variationsData = [];
                for (const item of formVariationsRef.current) { variationsData.push({ imageUUID: item.data.imageUUID, variationId: item.data.objectId, variationName: item.data.value }) }
                for (const item of formVariationsRef.current) { request.append("variationsImages", item.data.file ? item.data.file : new Blob()); }

                const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
                    .add(variationsData, `Variations (Required atleast 1)`)
                    .validate()
                    .get();

                if (!isValid) {
                    e.target.disabled = false;
                }
                else {

                    request.append("productId", selectedProduct.productId);
                    request.append("variations", new Blob([JSON.stringify(variationsData)], { type: "application/json" }));
                    callback(<Suspense fallback={<UpdatingCard />}>
                        <SuspenseBody
                            wrappedPromiseData={awaitedSubmitData('/api/mgrproduct/updatevariations', request)}
                            onCreateBody={(result) => {
                                if (result.fail) {
                                    e.target.disabled = false;
                                    transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                                } else {
                                    transactionTemplate.setEntryForm(undefined)
                                }
                                return onDisplayVariations();
                            }} />
                    </Suspense>)

                }
            }}>Update</Button>)
    }


    function onEditFeatures(e) {
        const title = "Edit Features";
        const callback = setDisplayFeatures;
        transactionTemplate.createEntryForm(title,
            <Tabs id="form-tab" defaultActiveKey={"pg=3"} className="mb-3"  >
                {createFeaturesEntry(prodDetFeatures.current)}
            </Tabs>,
            <Button onClick={(e) => {
                const request = new FormData(), featuresData = [];
                for (const item of formFeaturesRef.current) { featuresData.push({ imageUUID: item.data.imageUUID, featureId: item.data.objectId, featureName: item.data.value.featureName, featureDescription: item.data.value.featureDescription }) }
                for (const item of formFeaturesRef.current) { request.append("featuresImages", item.data.file ? item.data.file : new Blob()); }
                request.append("productId", selectedProduct.productId);
                request.append("features", new Blob([JSON.stringify(featuresData)], { type: "application/json" }));
                callback(<Suspense fallback={<UpdatingCard />}>
                    <SuspenseBody
                        wrappedPromiseData={awaitedSubmitData('/api/mgrproduct/updatefeatures', request)}
                        onCreateBody={(result) => {
                            if (result.fail) {
                                e.target.disabled = false;
                                transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                            } else {
                                transactionTemplate.setEntryForm(undefined)
                            }
                            return onDisplayFeatures();
                        }} />
                </Suspense>)
            }}>Update</Button>)
    }



    function onEditSpecifications(e) {
        const title = "Edit Specifications";
        const callback = setDisplaySpecification;
        transactionTemplate.createEntryForm(title,
            <Tabs id="form-tab" defaultActiveKey={"pg=4"} className="mb-3"  >
                {createSpecificationsEntry(prodDetSpecifications.current)}
            </Tabs>,
            <Button onClick={(e) => {

                const request = new FormData();
                const otherSpecMap = new Map();
                for (const item of formOtherSpecsRef.current) {
                    item.data.group = item.data.group ? item.data.group : "OTHERS";
                    otherSpecMap.set(item.data.group.toUpperCase() + "$" + item.data.key, item.data.value)
                }

                const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
                    .add((formSpecsRef.current.size + otherSpecMap.size) === 0 ? `` : `t`, `Specification`)
                    .validate()
                    .get();

                if (!isValid) {
                    e.target.disabled = false;
                }
                else {

                    request.append("specification", new Blob([JSON.stringify(Object.fromEntries(formSpecsRef.current))], { type: 'application/json' }))
                    request.append("otherSpecification", new Blob([JSON.stringify(Object.fromEntries(otherSpecMap))], { type: 'application/json' }))
                    request.append("productId", selectedProduct.productId);
                    callback(<Suspense fallback={<UpdatingCard />}>
                        <SuspenseBody
                            wrappedPromiseData={awaitedSubmitData('/api/mgrproduct/updatespecification', request)}
                            onCreateBody={(result) => {
                                if (result.fail) {
                                    e.target.disabled = false;
                                    transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                                } else {
                                    transactionTemplate.setEntryForm(undefined)
                                }
                                return onDisplaySpecification();
                            }} />
                    </Suspense>)
                }
            }}>Update</Button>)
    }


    function onEdit(selectedProduct, callback) {

        if (selectedProduct) {
            formBasicInfoRef.current = selectedProduct;
            const title = "Update Item";
            transactionTemplate.createEntryForm(title,
                <Tabs id="form-tab" defaultActiveKey={"pg=1"} className="mb-3"  >
                    {createBasicInfoEntry(selectedProduct, selectedProduct.typeIds)}
                </Tabs>,
                <Button onClick={(e) => { onSaveEdit(e, callback) }}>Update</Button>)
        }
    }

    const onSaveEdit = (e, callback) => {
        e.target.disabled = true;
        formBasicInfoRef.current.typeIds = formTypesRef.current;
        formBasicInfoRef.current.brandId = selectedBrand.brandId;
        const request = new FormData()
        request.append("product", new Blob([JSON.stringify(formBasicInfoRef.current)], { type: "application/json" }));
        request.append("modelImage", formModelImgRef.current.modelImage);

        callback(<Suspense fallback={<UpdatingCard />}>
            <SuspenseBody
                wrappedPromiseData={awaitedSubmitData('/api/mgrproduct/updatebasicinfo', request)}
                onCreateBody={(result) => {
                    if (result.fail) {
                        e.target.disabled = false;
                        transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                    } else {
                        onFetchList(transactionTemplate.getCurrentPage());
                        transactionTemplate.setEntryForm(undefined)
                        transactionTemplate.setToast(<Toast variant="success" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={"201 OK"} descriptionText={"Item has been created"} />)
                    }
                }} />
        </Suspense>)
    }

    const onUnvoid = (data, callback) => {
        onUpdateStatus(data, callback, false)
    }


    const onVoid = (data, callback) => {
        onUpdateStatus(data, callback, true)
    }

    function onUpdateStatus(data, callback, voided) {

        if (formBasicInfoRef.current) {
            transactionTemplate.setToast(<Toast
                variant="danger"
                onClose={() => { transactionTemplate.setToast(undefined) }}
                headingText={"405 Method Not Allowed"}
                descriptionText={"Your requested action is not allowed for currently editing data"} />)
            return;
        }

        const map = new FormData()
        map.append("id", data.productId);
        map.append("voided", voided);
        callback(<Suspense fallback={<UpdatingCard />}>
            <SuspenseBody
                wrappedPromiseData={awaitedSubmitData('/api/mgrproduct/updatestatus', map)}
                onCreateBody={(result) => {
                    if (result.fail) {
                        transactionTemplate.setToast(<Toast
                            variant="danger"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={result.status + "  " + result.statusText}
                            descriptionText={result.response} />)
                    } else {
                        transactionTemplate.setToast(<Toast
                            variant="success"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={"201 OK"}
                            descriptionText={"Modification has been saved"} />)
                        let pageNo = transactionTemplate.getCurrentPage();
                        if (transactionTemplate.getTransactionList().length === 1) { transactionTemplate.setCurrentPage(pageNo = transactionTemplate.getCurrentPage() - 1) }
                        onFetchList(pageNo);
                    }
                }}
            />
        </Suspense>)
    }




    function onCreateProductCards() {
        
        if (!selectedModel) { return <UpdatingCard /> }
        if (!transactionTemplate.getTransactionList()) { return <UpdatingCard /> }

        return transactionTemplate.getTransactionList().length === 0 ? NoData() :
            <div className="cardlist">
                {transactionTemplate.getTransactionList().map((product) => {

                    function displayTypes() {
                        return <div className="type-names"><Suspense fallback={<>...</>}>
                            {product.typeIds.map((typeId) => {
                                return <LazyLoadComponent>
                                    <SuspenseBody
                                        wrappedPromiseData={awaitedQueryData('/api/mgrproducttype/gettype?' + new URLSearchParams({ id: typeId }).toString())}
                                        onCreateBody={(result) => {
                                            if (result.fail) {
                                                transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                                                return <></>
                                            } else {
                                                return <Badge >{result.name}</Badge>
                                            }
                                        }}
                                    />
                                </LazyLoadComponent>
                            }
                            )}
                        </Suspense>
                        </div>
                    }

                    return <Card
                        isRounded={false}
                        controlKey={product.productId}
                        item={{
                            title: <>{selectedModel.name + " " + product.additionalModelName} <small className="text-muted"> {" (" + product.year + ")"} </small> </>,
                            description: <small className="text-muted"><b>{product.modelCode}</b></small>,
                            data: product
                        }}
                        voided={product.voided}
                        onEdit={onEdit}
                        onVoidOrDelete={onVoid}
                        onUnvoid={onUnvoid}
                        icon={'/public/mgrproduct/getimage?' + new URLSearchParams({ productId: product.productId, imageName: product.imageUUID, type: "m", quality: 90, genkey: uuidv4() }).toString()}
                        removeButtonText="Void"
                        onClick={(e) => {
                            removeSelected("div.card[selected=\"true\"]");
                            e.currentTarget.setAttribute("selected", "true");
                            setSelectedProduct(product)
                        }}
                        addtlDesc={<div className="addtl-card-inf">
                            {displayTypes(product)}
                            {product.classification ? <span > <Badge className="other-class" >{product.classification} </Badge></span> : <></>}
                        </div>}
                    />;
                })}
            </div>
    }

    function onCreateModelCards() {
        if (!modelTemplate.getTransactionList()) { return <UpdatingCard /> }
        return modelTemplate.getTransactionList().length === 0 ? <></> :
            <div className="list-selector">
                {modelTemplate.getTransactionList().map((model) => {
                    return <button className="bl-mod-selector" onClick={
                        (e) => {
                            const prevSelected = document.querySelectorAll(".bl-mod-selector[selected=\"true\"]");
                            if (prevSelected.length !== 0) { prevSelected[0].removeAttribute("selected") }
                            e.currentTarget.setAttribute("selected", "true");
                            setSelectedProduct(undefined)
                            setSelectedModel(model)

                        }}>
                        <LazyLoadImage src={'/public/mgrproductbrand/image?' + new URLSearchParams({ id: model.brandId, imageType: 90 }).toString()} />
                        {model.name}</button>;
                })}
            </div>
    }

    function onModelCardsChanged() {
        if (modelTemplate.getTransactionList()) {
            const prevSelected = document.querySelectorAll(".bl-mod-selector");
            if (prevSelected.length !== 0) { prevSelected[0].setAttribute("selected", "true") };
            setSelectedModel(modelTemplate.getTransactionList()[0])
        } else {
            transactionTemplate.setTransactionList([])
        }
    }

    function removeSelected(selector) {
        const elements = document.querySelectorAll(selector);
        if (elements.length !== 0) { elements[0].removeAttribute("selected") }
    }

    function setSelected(selector) {
        const elements = document.querySelectorAll(selector);
        if (elements.length !== 0) { elements[0].setAttribute("selected", "true") };
    }

    function onTransactionFormClosed() {
        formBasicInfoRef.current = undefined;
        formModelImgRef.current = undefined;
        formTypesRef.current = undefined;
        formIntroductionRef.current = undefined;
        formVariationsRef.current = undefined;
        formSpecsRef.current = undefined;
        formOtherSpecsRef.current = undefined;
    }


    function onTransactionListUpdated(transactionList) {
        prodDetSpecifications.current = [];
        prodDetOtherSpecifications.current = [];
        prodDetIntroduction.current = undefined;
        prodDetVariations.current = [];
        prodDetFeatures.current = [];
        if (transactionList) {
            if (transactionList.length !== 0) {
                if (selectedProduct) {
                    const element = document.getElementById(selectedProduct.productId);
                    if (element) {
                        element.click()
                        element.setAttribute("selected", "true")
                    }
                } else {
                    setSelectedProduct(transactionList[0])
                    setSelected("div.card");
                }
            }
        }
    }

    function updateBrandCards() {
        if (!brandList) {
            setBrandCards(undefined)
        } else {
            setBrandCards(<div className="list-selector brand-selector">
                {brandList.map((data) => {
                    return <button className="bl-bt-selector" variant="light" onClick={(e) => {
                        removeSelected(".bl-bt-selector[selected=\"true\"]");
                        e.currentTarget.setAttribute("selected", "true");
                        setSelectedBrand(data)
                    }}>
                        <LazyLoadImage src={'/public/mgrproductbrand/image?' + new URLSearchParams({ id: data.brandId, imageType: 90 }).toString()}
                        /> </button>;
                })}
            </div>)
        }
    }

    const navigate = useNavigate();

    const formBasicInfoRef = useRef();
    const formModelImgRef = useRef();
    const formTypesRef = useRef();
    const formIntroductionRef = useRef();
    const formFeaturesRef = useRef();
    const formSpecsRef = useRef();
    const formOtherSpecsRef = useRef();
    const formVariationsRef = useRef();
    const prodDetSpecifications = useRef([]);
    const prodDetOtherSpecifications = useRef([]);
    const prodDetIntroduction = useRef();
    const prodDetVariations = useRef([]);
    const prodDetFeatures = useRef([]);

    const modelTemplate = {};
    const transactionTemplate = {};
    const [brandCards, setBrandCards] = useState();
    const [brandList, setBrandList] = useState();
    const [selectedBrand, setSelectedBrand] = useState();
    const [selectedModel, setSelectedModel] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const [displayVariations, setDisplayVariations] = useState();
    const [displayIntro, setDisplayIntro] = useState();
    const [displaySpecification, setDisplaySpecification] = useState();
    const [displayFeatures, setDisplayFeatures] = useState();


    useEffect(() => { onFetchBrandList() }, [])
    useEffect(() => { if (brandList) { setSelected(".bl-bt-selector"); setSelectedBrand(brandList[0]) } }, [brandCards])
    useEffect(() => { if (selectedBrand) { removeSelected(".bl-mod-selector[selected=\"true\"]"); onFetchModelList(1) }; }, [selectedBrand])
    useEffect(() => { if (selectedModel) { transactionTemplate.setCurrentPage(1); onFetchList(1) }; }, [selectedModel])
    useEffect(() => { updateBrandCards() }, [brandList])
    useEffect(() => { if (selectedProduct) { setDisplayVariations(onDisplayVariations()); setDisplayIntro(onDisplayIntro()); setDisplaySpecification(onDisplaySpecification()); setDisplayFeatures(onDisplayFeatures()); } }, [selectedProduct])


    return <TransactionTemplate
        controlId="txn-products"
        bind={transactionTemplate}
        onTransactionListUpdated={onTransactionListUpdated}
        imports={{ onFetchList: onFetchList, onCreateCards: onCreateProductCards, onAddNew: onAddNew, onFormClosed: onTransactionFormClosed }}
        leftSideComp={
            <div className="txn-prod-pref">
                {brandCards}
                <TransactionList controlId="txn-prod-models" bind={modelTemplate} imports={{ onFetchList: onFetchModelList, onCreateCards: onCreateModelCards, onCardsChanged: onModelCardsChanged }} />
            </div>}
        rightSideComp={<div className="txn-prod-fd">
            <div className="txnp">
                {selectedProduct ? <ListGroup>
                    <ListGroup.Item>  {displayIntro}</ListGroup.Item>
                    <ListGroup.Item  > {displayVariations}</ListGroup.Item>
                    <ListGroup.Item>  {displayFeatures}</ListGroup.Item>
                    <ListGroup.Item>   {displaySpecification}</ListGroup.Item>
                </ListGroup> : <></>}
            </div>
        </div>}
        onListTypeChanging={() => setSelectedProduct(undefined)}
    />
}