import React, { useRef, useState } from 'react';
import "./Career.scss";
import { useEffect } from 'react';
import Fetcher from '../../Helper//Fetcher';
import { MdAccessTimeFilled } from "react-icons/md";
import ImageGridCollage from '../ImageGridCollage/ImageGridCollage.jsx';
import { Helmet } from 'react-helmet';
import StringUtils from '../../Helper//StringUtils.jsx';
import { FaFacebook, FaFacebookMessenger, FaMapLocation, FaMobileButton } from 'react-icons/fa6';
import { FaPhoneAlt } from 'react-icons/fa'; import { MdAttachEmail } from "react-icons/md";
import { useOutletContext } from 'react-router-dom';

export default function Career(props) {
    const {business} = useOutletContext();

    
    console.log(business)
    const [careers, setCareers] = useState([]);
    const [hrinfs, setHrinfs] = useState([]);

    useEffect(() => {
        document.title = "Careers";
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    useEffect(() => {
        Fetcher.onFetchCareers((result) => setCareers(result));
    }, [props.businessId]);


    useEffect(() => {
        Fetcher.onFetchHRInfs((result) => setHrinfs(result));
    }, [props.businessId, careers]);


    function renderHeadingText() {

        return <div>
            <p><strong business={props.businessId}>{'JOIN OUR EXPANDING TEAM!'}</strong></p>
            <i> {'Start or advance your career together with our expanding team providing customer satisfactory. We are the team that cares about you and our customers.  '}</i>
            <i business={props.businessId}> {'  Be one of us.  '}</i>
        </div>
    }

    function renderHeadingImage() {
        let image = `/images/career/head.png?$ORIGIN_PNG$`;
        return <figure>
            <img loading="lazy"
                src={image}
                data-src-pc={image}
                data-src-tablet={image}
                alt="" />
        </figure>;
    }


    const renderListOfCareers = () => {
        const finalTags = [];
        for (let data of careers) {
            const imagesData = [];
            for (let imgData of data.careerImages) {
                imagesData.push(
                    {
                        gallery: imgData.careerId,
                        galleryClass: "venobox",
                        imagePath: `/images/career/${imgData.image}`,
                        imageIconPath: `/images/career/${imgData.image}`,
                        imageDescription: <></>
                    }
                )
            }
            finalTags.push(<div>
                <div> <MdAccessTimeFilled />     {new Date(data.career.dateFrom).toDateString() + " - " + new Date(data.career.dateTo).toDateString()}     </div>
                <div> <ImageGridCollage images={imagesData} /></div>
                <div>{data.career.description}  </div>
            </div>);
        }
        return finalTags;

    }
    const renderSocialMediaButtons = (array) => {
        const tag = [];
        for (let x of array) {
            const button = x.startsWith('https://www.facebook.com') ?
                <button className='hr_contacts_btn' ><FaFacebook className='fa-icon' />{'Follow us'}</button> :
                <button className='hr_contacts_btn' ><FaFacebookMessenger className='fa-icon' />{'Message us'}</button>;
            tag.push(<span><a target='_blank' rel='noopener noreferrer' href={x}>{button} </a></span>);
        }
        tag.push(<span><a target='_blank' rel='noopener noreferrer' >{<button className='hr_contacts_btn' ><FaMapLocation className='fa-icon' />{'Open Map'}</button>} </a></span>);
        return <div id="hr_buttons">{tag}</div>;
    }


    const renderHRInformations = () => {
        const finalTags = [];

        finalTags.push(<div id="hr_title"><strong>CONTACT HUMAN RESOURCE MANAGEMENT</strong></div>)
        for (let data of hrinfs) {
            const cArea = data.area;

            finalTags.push(<div id="area_name">{cArea}</div>);

            for (let branch of data.branches) {
                const areaTags = [], contactTags = [];
                contactTags.push(<span className='_contact'><div><MdAttachEmail className='svg_contact' /></div>  <div> {branch.email}</div></span>)
                contactTags.push(<span className='_contact'><div><FaMapLocation className='svg_contact' /></div>  <div> {branch.address}</div></span>)
                if (!StringUtils.isNullOrEmpty(branch.phoneNumber)) {
                    for (let text of StringUtils.splitByComma(branch.phoneNumber)) contactTags.push(<span className='_contact' ><div><FaPhoneAlt className='svg_contact' /></div> <div>{text}</div></span>)
                }
                if (!StringUtils.isNullOrEmpty(branch.mobileNumber)) {
                    for (let text of StringUtils.splitByComma(branch.mobileNumber)) contactTags.push(<span className='_contact' ><div><FaMobileButton className='svg_contact' /></div>  <div>{text}</div> </span>)
                }
                areaTags.push(<div id="hr_contacts">{contactTags}</div>);
                areaTags.push(renderSocialMediaButtons([branch.facebook, branch.messenger]));
                finalTags.push(<div >
                    <div>
                        <div>{branch.businessId + " - " + branch.name}</div>
                        {areaTags}
                    </div>
                </div>);
                /*    finalTags.push( <div><iframe 
     
                       title='google_map'
                       className='art_shadow'
                       src={branch.mapURI}
                       allowfullscreen=""
                       loading="lazy"
                       
                       referrerpolicy="no-referrer-when-downgrade" /></div>);  */


            }
            /*   finalTags.push(<div>
                  <div> <MdAccessTimeFilled />     {dateFrom.toDateString() + " - " + dateTo.toDateString()}     </div>
                  <hr></hr>
  
                  <div>{data.career.description}  </div>
              </div>); */
        }
        return finalTags;

    }

    if (careers.length === 0) {
        return <></>;
    }


    const fb = () => {
        const srcVal = "https://connect.facebook.net/en_US/sdk.js?hash";
        var scripts = document.getElementsByTagName("script");

        for (var i = 0; i < scripts.length; i++) {
            console.log(scripts[i].getAttribute('src'));

            if (scripts[i].getAttribute('src').startsWith(srcVal)) {
                console.log("old fb");

                /*     scripts[i].parentNode.removeChild( scripts[i] ); */
                /*     var head = document.getElementsByTagName('head')[0];
    
                    theScript= document.createElement('script');
                    theScript.src = filename;
                    theScript.type = "text/javascript"; */

                /*  return  <script src="https://connect.facebook.net/en_US/sdk.js?hash=457afa48e02ec7e1c135d37fb0d9d013" async="" crossorigin="anonymous"></script> ; */

            }
        }



        console.log("fbfbfb");

        return <script async defer crossorigin="anonymous" src='https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v19.0' nonce="FlzlQJV4"></script>;;


    }

    return ([
        <div id="main_page" className='career-main'>
            <div id="center_page">
                <div id="career_heading">
                    <div id="career_heading_text">{renderHeadingText()}  </div>
                    <div id="career_heading_img"> {renderHeadingImage()} </div>
                </div>
              
                <div id="careers">
                    <div>{renderListOfCareers()}</div>
                    <div> {renderHRInformations()}</div>
                </div>
            </div>
        </div>]
    )


}
