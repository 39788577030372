import React, { useEffect, useRef, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { RxHamburgerMenu } from 'react-icons/rx';
import { Link, useNavigate } from 'react-router-dom/dist';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Navbar, NavbarBrand, UncontrolledDropdown } from 'reactstrap';
import Select from 'react-select';
import './AppNavBar.scss';
import { Modal, Offcanvas } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { v4 as uuidv4 } from 'uuid';
export default function AppNavbar(props) {

    const [show, setShow] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleModalSearch = () => { setModalShow(true) };

    const { channel, width, logo, $ } = props;


    useEffect(() => {
        if (width > 640) {
            if (!collapsed) {
                setCollapsed(true);
            }
            if (modalShow) {
                setModalShow(false);

            }
        }
    }, [width]);



    const onClickSelectionAction = () => {
        if (show) {
            handleClose()
        }
    }

    const listOfMenu = [
        { className: 'nbb-home', linkTo: { pathname: ".", state: {} }, linkName: 'Home' },
        {
            className: 'nbb-products', linkTo: { pathname: ".", state: {} }, linkName: 'Products', subLinks: [
                { className: 'nbb-products-sub n-sub', linkTo: { pathname: `product/motorcycle`, state: { name: "motorcycle" } }, linkName: 'Motorcycles' },
                { className: 'nbb-products-sub n-sub', linkTo: { pathname: `product/powerproducts`, state: { name: "powerproducts" } }, linkName: 'Power Products' }

            ]
        },

        { className: 'nbb-store-finder', linkTo: { pathname: "storelocator", state: {} }, linkName: 'Store Finder' },
        { className: 'nbb-customer-sub n-sub', linkTo: { pathname: "customercare/faq", state: {} }, linkName: 'Frequently Asked Questions' },
        { className: 'nbb-customer-sub n-sub', linkTo: { pathname: "customercare/accountstatus", state: {} }, linkName: 'Account Status Query' },

    ]


    const createNavigations = (mobile) => {
        const navs = [];
        if (mobile) {
            for (const object of listOfMenu) {
                if (object.subLinks) {
                    const subNavs = [];
                    for (const sub of object.subLinks) {
                        subNavs.push(<DropdownItem className={sub.className} tag={Link} to={sub.linkTo} onClick={() => onClickSelectionAction()}>{sub.linkName}</DropdownItem>);
                        if (object.subLinks.indexOf(sub) !== (object.subLinks.length - 1)) {
                            subNavs.push(<DropdownItem divider />);
                        }
                    }
                    navs.push(<>
                        <UncontrolledDropdown light synav inNavbar className='nbb-un-drop'>
                            <DropdownToggle nav caret className='nbb-un-drop-tog'>
                                {object.linkName}
                            </DropdownToggle>
                            <DropdownMenu end>
                                {subNavs}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </>);
                } else {
                    navs.push(<>
                        <NavItem className='menu-collapse-navitem'>
                            <NavLink className={object.className + " nav-mobile"} tag={Link} to={object.linkTo} onClick={() => onClickSelectionAction()}>{object.linkName} </NavLink>
                        </NavItem>
                    </>);
                }
                if (listOfMenu.indexOf(object) !== (listOfMenu.length - 1)) {
                    navs.push(<DropdownItem divider />);
                }
            }


        } else {
            for (const object of listOfMenu) {
                if (object.subLinks) {
                    const subNavs = [];
                    let index = 0;
                    for (const sub of object.subLinks) {
                        subNavs.push(<DropdownItem
                            className={sub.className} tag={Link} to={sub.linkTo}
                            key={"link_" + (index++)}>{sub.linkName}</DropdownItem>);
                    }
                    navs.push(<>
                        <UncontrolledDropdown light synav inNavbar className='nbb-un-drop'>
                            <DropdownToggle nav caret className='nbb-un-drop-tog'>
                                {object.linkName}
                            </DropdownToggle>
                            <DropdownMenu end>
                                {subNavs}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </>);
                } else {
                    navs.push(<NavbarBrand className={object.className} tag={Link} to={object.linkTo} >{object.linkName}</NavbarBrand>);
                }
            }
        }
        return navs;
    }

    function Search() {
        return (
            <Autocomplete id='autocomplete'
                channel={channel}
                $={$}
                modalShow={modalShow}
                setModalShow={setModalShow}
                placeholder='Search products'
            />
        );
    }

    return <><div id='application-navigation' className='application-navigation' channel={channel} style={channel === 'VMI' ? { "--color": "58, 123, 189" } : { "--color": "194, 2, 2" }}>
        <div className='application-navigation-div-1 center-page' >
            <div className='nav-top-area'>
                <Link to="." relative="path">
                    <img className='app-nav-company-logo' src={props.logo} />
                </Link>
                <div className='nav-right' id='-search'  >
                    <div id='-ac' className='-navigation-row-s1 react-autosuggest_impl' >
                        <Search />
                    </div>
                    <button className='nr-btn-search' onClick={handleModalSearch}  >
                        <BsSearch size='1.2em' />
                    </button>
                    <button className='nr-btn' onClick={handleShow}  >
                        <RxHamburgerMenu size='1.6em' />
                    </button>
                </div>
            </div>
            <div className='nav-bottom-area'  >
                <Navbar className='navigations' light  >
                    {createNavigations(false)}
                </Navbar>
            </div>
        </div>
    </div >
        <Offcanvas show={show} onHide={handleClose} placement={'end'} name={'end'} className='nav-mobile' >
            <Offcanvas.Header className='nav-header-mobile' closeButton   >
                <Offcanvas.Title>  </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Nav navbar className='menu-collapse-nav' >
                    {createNavigations(true)}
                </Nav>
            </Offcanvas.Body>
        </Offcanvas>
        <Modal show={modalShow} size="lg" onHide={() => setModalShow(false)} id='-search'   >
            <Search />
        </Modal>
    </>
        ;
}


function Autocomplete(props) {

    const { channel, $, setModalShow, modalShow } = props;
    const [options, setOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const themeColor = channel === "VMI" ? "#3a7bbd" : '#c20202';
    const navigate = useNavigate()

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? themeColor : provided.borderColor,
            boxShadow: state.isFocused ? `0 0 0 1px ${themeColor}` : provided.boxShadow,
            '&:hover': {
                borderColor: themeColor,
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? themeColor : provided.backgroundColor,
            color: state.isFocused ? 'white' : provided.color,
            '&:active': {
                backgroundColor: themeColor,
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: themeColor,
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isFocused ? themeColor : provided.color,
            '&:hover': {
                color: themeColor,
            },
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? themeColor : provided.backgroundColor,
        }),
    };

    const fetchOptions = async (query) => {
        setIsLoading(true);
        $(`/public/search/products`, { businessId: channel, search: query },
            async (fail) => { setIsLoading(false); },
            async (error) => { setIsLoading(false); },
            async (data) => {
                const value = await data.json();
                console.log(value)
                setOptions((value).map((item) => {
                    return {
                        value: item,
                        label: `${item.brandName} ${item.modelName} ${item.additionalModelName}  `
                    }
                }));
                setIsLoading(false);
            }
        )
    };

    const handleInputChange = (newValue) => {
        setSearchQuery(newValue);
        if (newValue.length > 1) { fetchOptions(newValue); }
        else { setOptions([]); }
    };

    const customFilterOption = (option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };

    return <Select
        styles={customStyles}
        options={options}
        filterOption={customFilterOption}
        onInputChange={handleInputChange}
        inputValue={searchQuery}
        isLoading={isLoading}
        onChange={(option) => {
            const value = option.value;
            if (modalShow) { setModalShow(false) }
            navigate(`product/${value.powerProduct ? `powerproducts` : `motorcycle`}/${value.productId}`
            )
        }}
        placeholder="Search Products"
        noOptionsMessage={() => 'No options'}
    />


}
