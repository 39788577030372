import React, { memo } from 'react';
import './ManualEntry.scss';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { TbX } from "react-icons/tb";

const ManualEntry = ({ data, componentId, onRemove, onDataChanged }) => {
 
    return <div className='manual-entry-group' key={componentId}>
        <div className='imgipp'>
            <div className="piigdta">
                <InputGroup  >
                    <Form.Control className={"spec-input-desc"} type="text" defaultValue={data.value} onChange={(e) => { data.value = e.currentTarget.value; onDataChanged(); }} />
                    <Button className='piigzkclsd ' variant='danger' onClick={onRemove}><TbX /></Button>
                </InputGroup>
            </div>
        </div>
    </div>
}

export default memo(ManualEntry);
