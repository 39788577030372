

import { useEffect, useState } from "react";
import "./TransactionList.scss";

export default function TransactionList({ bind, imports, controlId  }) {

    /**
     * function states
     */

    const [transactionList, setTransactionList] = useState(undefined);
    const [cards, setCards] = useState();

    /**
     * template reference for external use 
     */

    bind.getTransactionList = () => { return transactionList };
    bind.setTransactionList = (value) => { setTransactionList(value) };
    bind.getCards = () => { return cards };
    bind.setCards = (value) => { setCards(value) };

    Object.seal(bind);

    /**
     * states effects when function states changed
     */
    useEffect(() => {  imports.onFetchList() }, [])
    useEffect(() => { setCards(imports.onCreateCards());  }, [transactionList])
    useEffect(() => {  imports.onCardsChanged()}, [cards])
    /**
     * output 
     */
    return [
        <div id={controlId} className="tx-tmp-7"  >
            <div className="contentmd-7">
                <div className="ctmd-7">
                    {cards}
                </div>

            </div>
        </div>
    ]



}