import React, { Component } from 'react';
import './AppFooter.scss';
import { LuBadgeInfo } from "react-icons/lu";
import { useOutletContext } from 'react-router-dom';
import { RiMapPin2Fill } from "react-icons/ri";
import { MdPhone } from "react-icons/md";
import { Helmet } from 'react-helmet';
import { FaMobileAlt } from "react-icons/fa";

export default function AppFooter(props) {
    const { logo, channel, mainBusiness, marketingDeptInfo, setDailyverses, verseType } = props;
    const business = channel === `VMI` ? "VIAJERO MOTORSALES, INC." : "JICJAM CYCLE, INC.";
    const mainBusinessPhones = mainBusiness ? mainBusiness.phone.map((string) => { return <span><MdPhone /> {string}</span> }) : [];
    const mainBusinessMobiles = mainBusiness ? mainBusiness.mobile.map((string) => { return <span><FaMobileAlt /> {string}</span> }) : [];
    const mainBusinessAddress = mainBusiness ? mainBusiness.address : undefined;
    const marketingAddress = marketingDeptInfo ? marketingDeptInfo.address : undefined;
    const marketingPhone = marketingDeptInfo ? marketingDeptInfo.phone.map((string) => { return <span><MdPhone /> {string}</span> }) : [];
    const marketingMobile = marketingDeptInfo ? marketingDeptInfo.mobile.map((string) => { return <span><FaMobileAlt /> {string}</span> }) : [];
    const tagline = `"YOUR RIDE STARTS HERE"`;
    const taglineu = "We ensure low monthly amortization of motorycle and powerproducts loan for our customers.";

    function copyright() {
        return `Copyright © ${new Date().getFullYear()}. Codexus Solutions, Inc.`;
    }

    function setVerseType(type) {
        verseType.current = type;
        setDailyverses(<Helmet><script async defer src={`https://dailyverses.net/get/verse.js?language=${type}`}></script></Helmet>)
    }
    return <>
        <div className='verses center-page mb-3' channel={channel}>
            <span id="dailyVersesWrapperTitle"><LuBadgeInfo className='lu-verse-badge' /> VERSE OF THE DAY</span>
            <div id="dailyVersesWrapper">
            </div>
            <div className='verse-types'>
                <button className={verseType.current === `kjv` ? "type-selected" : ""} onClick={(e) => setVerseType(`kjv`)}>KJV</button>
                <button className={verseType.current === `niv` ? "type-selected" : ""} onClick={(e) => setVerseType(`niv`)}>NIV</button>
                <button className={verseType.current === `esv` ? "type-selected" : ""} onClick={(e) => setVerseType(`esv`)}>ESV</button>
                <button className={verseType.current === `nlt` ? "type-selected" : ""} onClick={(e) => setVerseType(`nlt`)}>NLT</button>
                <button className={verseType.current === `web` ? "type-selected" : ""} onClick={(e) => setVerseType(`web`)}>WEB</button>
                <button className={verseType.current === `cuv` ? "type-selected" : ""} onClick={(e) => setVerseType(`cuv`)}>CUV</button>
                <button className={verseType.current === `cuvs` ? "type-selected" : ""} onClick={(e) => setVerseType(`cuvs`)}>CUVS</button>
            </div>
        </div>

        <div id="app-footer" className=" ff-m1-line" style={channel === 'VMI' ? { "--color": "58, 123, 189" } : { "--color": "194, 2, 2" }} >
            <div className='af-top center-page' >
                <div className='af-left'>
                    <img className="ff-img-logo" src={logo} />
                    <div className='business-main'>
                        <span className="name">{business} </span>
                        <span className="tag">MAIN OFFICE</span>
                        <span className="address">{mainBusinessAddress} </span>
                        <span className="contacts">{mainBusinessPhones} {mainBusinessMobiles}</span>
                    </div>
                </div>
                <div className='af-right'>
                    <div className="tagline">
                        <span className="taglinetext">{tagline}</span>
                        <span className="tagline-under">{taglineu}</span>
                    </div>
                    <div className="marketing">
                        <span className="marketing-text mb-2">Any questions, inquiries or you want to know the product price qoutation, contact our marketing team.</span>
                        <span className="marketing-name">MARKETING TEAM DEPARTMENT</span>
                        <span className="marketing-address">{marketingAddress}</span>
                        <span className="marketing-contacts">{marketingPhone}{marketingMobile}</span>
                    </div>
                </div>
            </div>
            <div className='center-page mt-3 ft2'>
                <span className="ff-ll "> {copyright()}</span>
            </div>
        </div>
    </>

}
