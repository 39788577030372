import React, { Suspense, useEffect, useState } from 'react';


import './AppHome.scss';
import Fetcher from '../../Helper/Fetcher';
import BasicSlider from '../BasicSlider/BasicSlider';
import { Button } from 'reactstrap';
import AutoPlayBasicSlider from '../BasicSlider/AutoPlayBasicSlider';
import { Link, useNavigate, useOutletContext } from 'react-router-dom/dist';
import { GET } from '../../../Reusables/FetchHandler';
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";
import SuspenseBody from '../../Helper/SuspenseBody';
import LoadingCard from '../../Helper/LoadingCard';
import { awaitedQueryData } from '../../Helper/PromiseWrapper.ts';
import UpdatingCard from '../../Helper/UpdatingCard.jsx';
import { SwiperSlide } from 'swiper/react';
import { AiFillTool } from "react-icons/ai"
import { FaGear } from "react-icons/fa6";
import { FaMotorcycle } from "react-icons/fa";
import { PiEngineFill } from "react-icons/pi";
export default function AppHome(props) {

    const navigate = useNavigate();
    const { width, channel, $ } = useOutletContext();
    const [cardCount, setCardCount] = useState(3.5);
    const [mobile, setMobile] = useState(width <= 76);
    const [mainSliders, setMainSliders] = useState()
    const [eventSliders, setEventSliders] = useState()
    const [promoSliders, setPromoSliders] = useState()
    const [featuredMC, setFeaturedMC] = useState()
    const [featuredPP, setFeaturedPP] = useState()

    document.title = `${channel === "VMI" ? "Viajero Motorsales Inc." : "Jicjam Cycle Inc."} - Home`;
    
    useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); getMainSlides() }, []);
    useEffect(() => { if (width <= 650) { setCardCount(1.5) } else if (width > 650) { setCardCount(3.5) } }, [width]);
    useEffect(() => { setMobile(width <= 767) }, [width]);
    useEffect(() => { if (mainSliders) { getNewsAndEventsSliders() } }, [mainSliders]);
    useEffect(() => { if (eventSliders) { getPromoSliders() } }, [eventSliders]);
    useEffect(() => { if (promoSliders) { getFeaturedMc() } }, [promoSliders]);
    useEffect(() => { if (featuredMC) { getFeaturedPp() } }, [featuredMC]);

    function createFeaturedProducts(products, title, isPowerproduct) {
        return <LazyLoadComponent>
            <div className='f-p-featured'>
                <span className='f-p-title'>{title}</span>
                <BasicSlider HTMLElements={
                    products.map((array) => {
                        const product = array.Product;
                        const brand = array.Brand;
                        const model = array.Model;
                        const variations = array.ProductVariations;
                        return <SwiperSlide className="basic-slider-material-content"
                            onClick={(e) => { navigate(`product/${isPowerproduct ? "powerproducts" : "motorcycle"}/${product.productId}`) }}                        >
                            <div className="basic-slider-material-content">
                                <div className="bslider-image-content">
                                    <LazyLoadImage
                                        className="bslider-image"
                                        src={"/public/featuredimage?" + new URLSearchParams({ productId: product.productId, imageName: variations[0].imageUUID, quality: 70 }).toString()} />
                                </div>
                                <span className="bslider-label">
                                    {`${brand.name} ${model.name} ${product.additionalModelName}  (${product.year})`}
                                </span>
                            </div>
                        </SwiperSlide>
                    })
                } cardCount={cardCount} />
            </div>
        </LazyLoadComponent>
    }


    function getMainSlides() {
        $('/public/slides', { businessId: channel, mainBannerSlider: true },
            async (fail) => { },
            async (error) => { },
            async (success) => {
                setMainSliders(await success.json())
            });
    }

    function getNewsAndEventsSliders() {
        $('/public/slides', { businessId: channel, newsAndEventsSlider: true },
            async (fail) => { },
            async (error) => { },
            async (success) => { setEventSliders(await success.json()) });
    }

    function getPromoSliders() {
        $('/public/slides', { businessId: channel, announcementsAndPromoSlider: true },
            async (fail) => { },
            async (error) => { },
            async (success) => { setPromoSliders(await success.json()) });
    }

    function getFeaturedMc() {
        $('/public/featured', { businessId: channel, category: "Motorcycle" },
            async (fail) => { },
            async (error) => { },
            async (success) => { setFeaturedMC(await success.json()) });
    }

    function getFeaturedPp() {
        $('/public/featured', { businessId: channel, category: "Powerproducts" },
            async (fail) => { },
            async (error) => { },
            async (success) => { setFeaturedPP(await success.json()) });
    }

    return <>
        <div id={"app-home"} style={channel === 'VMI' ? { "--color": "58, 123, 189" } : { "--color": "194, 2, 2" }} className={`${mobile ? "mb" : "wb"}`}>

            <div className='f-p-discover-bigslide'>
                {mainSliders ? <AutoPlayBasicSlider HTMLElements={
                    mainSliders.map((element) => {
                        return <LazyLoadImage
                            src={"/public/mgrslidercontent/image?" + new URLSearchParams({ imageUUID: mobile ? element.imageMobileUUID : element.imageWebUUID, imageType: 70 }).toString()}
                            className="autoplay-bslider-image"
                            onClick={(e) => { window.location.href = element.url }} />
                    })
                } /> : <UpdatingCard />}

            </div>

            <div className='f-p-discover'>

                <div className='f-p-d-eventnews f-p-d-card'>
                    <span className='f-p-title'>Events and News</span>
                    <div className='f-p-d-c-item'>
                        {eventSliders ?



                            <AutoPlayBasicSlider HTMLElements={
                                eventSliders.map((element) => {
                                    return <LazyLoadImage
                                        src={"/public/mgrslidercontent/image?" + new URLSearchParams({ imageUUID: mobile ? element.imageMobileUUID : element.imageWebUUID, imageType: 70 }).toString()}
                                        className="autoplay-bslider-image"
                                        onClick={(e) => { window.location.href = element.url }} />
                                })
                            } />




                            : <UpdatingCard />}

                    </div>
                </div>
                <div className='f-p-d-announcementpromos f-p-d-card'>
                    <span className='f-p-title'>Announcements and Promos</span>
                    <div className='f-p-d-c-item'>
                        {promoSliders ? <AutoPlayBasicSlider HTMLElements={
                            promoSliders.map((element) => {
                                return <LazyLoadImage
                                    src={"/public/mgrslidercontent/image?" 
                                        + new URLSearchParams({ imageUUID: mobile ? element.imageMobileUUID : element.imageWebUUID, imageType: 70 }).toString()}
                                    className="autoplay-bslider-image"
                                    onClick={(e) => { window.location.href = element.url }} />
                            })
                        } /> : <UpdatingCard />}

                    </div>
                </div>
            </div>

            <div className='basic-offers'>

                <span><AiFillTool /> Motorcycle Services</span>
                <span><FaGear /> Motorcycle Spareparts</span>
                <span><FaMotorcycle /> Brand New And Repossessed Motorcycle</span>
                <span><PiEngineFill /> Power Products</span>
            </div>
            <span className='tagline-engage'>We offer cash and installments in lowest monthly amortization!</span>
            <div className='f-p-ask'>
                <span className='f-p-asktitle'>Got some inquiries or questions? check out some </span>
                <Link to={{ pathname: `customercare/faq` }} className="f-p-ask-button" business={channel}  >
                    <button  >Frequently Asked Questions</button>
                </Link>
            </div>

            {featuredMC ?
                featuredMC.length > 0 ?
                    createFeaturedProducts(featuredMC, "Featured Motorcycles", false) :
                    <></> : <></>}

            {featuredPP ?
                featuredPP.length > 0 ?
                    createFeaturedProducts(featuredPP, "Featured Power Products", true) :
                    <></> : <></>}

        </div>
    </>
}
