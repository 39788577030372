import React, { useEffect, useState } from 'react';
import './BasicSlider.scss';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom/dist';


export default function BasicSlider({  HTMLElements,cardCount}) {

 

/*     function renderItems() {
        const tags = [];
        for (let item of items) {
              tags.push(<SwiperSlide className="basic-slider-material-content" >
                <Link to={{ pathname: item.link }} className="basic-slider-link">
                    <div className="basic-slider-material-content">
                        <div className="bslider-image-content">
                            <img loading='lazy' className="bslider-image" src={item.image} />
                        </div>
                        <span className="bslider-label">{item.label}</span>
                    </div></Link>
            </SwiperSlide>);
        }
        return tags;
    } */

    return [
        <Swiper
            loop={false}
            spaceBetween={16}
            pagination={{
                clickable: true,
            }}
            slidesPerView={ cardCount} className='basic-img-slider'>
            {HTMLElements}
        </Swiper>
    ]
}
