
import { Suspense, useRef } from "react";
import "./Brands.scss";
import TransactionTemplate from "../../Reusables/TransactionTemplate.jsx";
import { Button, Form } from "react-bootstrap";
import Card from "../Helper/Card.jsx";
import UpdatingCard from "../Helper/UpdatingCard.jsx";
import NoData from "../Helper/NoData.jsx";
import { awaitedSubmitData } from "../Helper/PromiseWrapper.ts";
import SuspenseBody from "../Helper/SuspenseBody.jsx";
import CodeShorter from "../Helper/CodeShorter.jsx";

import Toast from "./Toast.jsx";
import { ValueValidator } from "../../Reusables/ValueValidator.jsx";


export default function Brands(props) {

    function onTransactionFormClosed() {
        formDataRef.current = undefined;

    }

    const formDataRef = useRef(null);
    const transactionTemplate = {};

    const onFetchList = (page) => {
        transactionTemplate.setCards(<UpdatingCard />)
        fetch('/api/mgrproductbrand/list?' +
            new URLSearchParams({ page: page, limit: transactionTemplate.getLimit(), voided: transactionTemplate.getVoided() }).toString())
            .then(response => { return response.json() })
            .then(response => {
                transactionTemplate.setTransactionList(response.response);
                transactionTemplate.setTotalPages(Math.ceil(response.totalCount / transactionTemplate.getLimit()));
            })
    }


    function createEntryForm(title, type, data, callback) {
        const isEdit = data ? true : false;

        const toggle = (e) => {
            const checkboxes = document.querySelectorAll('#toggleGroup input[type="checkbox"]');
            if (e.currentTarget.checked) {
                checkboxes.forEach(
                    cb => {
                        if (cb !== e.currentTarget) {
                            cb.checked = false;
                        }
                    });
            } else {
                checkboxes.forEach(cb => { cb.checked = false; });
            }
        };

        transactionTemplate.createEntryForm(title,
            <>
                <Form.Group className="mb-3" >
                    <Form.Label>Brand Name</Form.Label>
                    <Form.Control className="default-focus" type="text" name="name" placeholder="Enter product type name" defaultValue={data ? data.name : ""} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Brand Icon {isEdit ? `(Optional)` : ``}</Form.Label>
                    <Form.Control type="file" name="brandimage" accept="image/png, image/jpeg, image/webp, image/jpg" />
                </Form.Group>

                <Form.Label>Brand Type</Form.Label>
                <Form.Group id="toggleGroup" className="mb-3">
                    <Form.Check inline label="Motorcycle" name="motorcycle" type="checkbox" defaultChecked={data ? !data.powerProduct : false} onChange={toggle} />
                    <Form.Check inline label="Power Product" name="powerProduct" type="checkbox" defaultChecked={data ? data.powerProduct : false} onChange={toggle} />
                </Form.Group>
            </>,
            (type === "add" ?
                <Button onClick={(e) => { onSaveAddNew(e, callback) }}>Save New</Button> :
                <Button onClick={(e) => { onSaveEdit(e, data, callback) }}>Update</Button>));
    }


    const onAddNew = () => {
        createEntryForm("New Item", "add", undefined, transactionTemplate.setNewDataCallBack)
    }

    const onSaveAddNew = (e, callback) => {
        e.target.disabled = true;
        const source = new FormData(document.getElementById("entry-form"));

        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(source.get("name"), `Brand Name`)
            .add(source.get("brandimage").size === 0 ? `` : `t`, `Brand Icon (Required)`)
            .add((source.get("motorcycle") || source.get("powerProduct")) ? `t` : ``, `Brand Type (Choose atleast 1)`)
            .validate()
            .get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {
            const map = new FormData()
            map.append("name", source.get("name"));
            map.append("brandimage", source.get("brandimage"));
            map.append("powerProduct", new Boolean(source.get("powerProduct")));
            callback(<Suspense fallback={<></>}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrproductbrand/savenew', map)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            e.target.disabled = false;
                            transactionTemplate.setToast(
                                <Toast
                                    variant="danger"
                                    onClose={() => { transactionTemplate.setToast(undefined) }}
                                    headingText={result.status + "  " + result.statusText}
                                    descriptionText={result.response} />)
                        } else {
                            onFetchList(transactionTemplate.getCurrentPage());
                            transactionTemplate.setEntryForm(undefined)
                            transactionTemplate.setToast(
                                <Toast
                                    variant="success"
                                    onClose={() => { transactionTemplate.setToast(undefined) }}
                                    headingText={"201 OK"}
                                    descriptionText={"Item has been created"} />
                            )
                        }
                    }} />
            </Suspense>)
        }
    }

    const onEdit = (data, callback) => {
        createEntryForm("Modify Item [ " + data.name + " ]", "update", formDataRef.current = data, callback)
    }

    const onSaveEdit = (e, data, callback) => {
        e.target.disabled = true;
        const source = new FormData(document.getElementById("entry-form"));

        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(source.get("name"), `Brand Name`)
            .add((source.get("motorcycle") || source.get("powerProduct")) ? `t` : ``, `Brand Type (Choose atleast 1)`)
            .validate()
            .get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {
            const map = new FormData()
            map.append("id", data.brandId);
            map.append("name", source.get("name"));
            map.append("brandimage", source.get("brandimage"));
            map.append("powerProduct", new Boolean(source.get("powerProduct")));
            map.append("imageUUID", new String(data.imageUUID));

            callback(<Suspense fallback={<UpdatingCard />}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrproductbrand/update', map)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            callback(undefined);
                            e.target.disabled = false;
                            transactionTemplate.setToast(<Toast
                                variant="danger"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={result.status + "  " + result.statusText}
                                descriptionText={result.response} />)
                        } else {
                            transactionTemplate.setTransactionList(transactionTemplate.getTransactionList().map(item => item === data ? result : item))
                            transactionTemplate.setEntryForm(undefined)
                            callback(undefined);
                            transactionTemplate.setToast(<Toast
                                variant="success"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={"201 OK"}
                                descriptionText={"Modification has been saved"} />)
                        }
                    }} />
            </Suspense>)
        }
    }


    function onUpdateStatus(data, callback, voided) {

        if (formDataRef.current) {
            transactionTemplate.setToast(<Toast
                variant="danger"
                onClose={() => { transactionTemplate.setToast(undefined) }}
                headingText={"405 Method Not Allowed"}
                descriptionText={"Your requested action is not allowed for currently editing data"} />)
            return;
        }

        const map = new FormData()
        map.append("id", data.brandId);
        map.append("voided", voided);
        callback(<Suspense fallback={<UpdatingCard />}>
            <SuspenseBody
                wrappedPromiseData={awaitedSubmitData('/api/mgrproductbrand/updatestatus', map)}
                onCreateBody={(result) => {
                    if (result.fail) {
                        transactionTemplate.setToast(<Toast
                            variant="danger"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={result.status + "  " + result.statusText}
                            descriptionText={result.response} />)
                    } else {
                        transactionTemplate.setToast(<Toast
                            variant="success"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={"201 OK"}
                            descriptionText={"Modification has been saved"} />)
                        let pageNo = transactionTemplate.getCurrentPage();
                        if (transactionTemplate.getTransactionList().length === 1) { transactionTemplate.setCurrentPage(pageNo = transactionTemplate.getCurrentPage() - 1) }
                        onFetchList(pageNo);
                    }
                }}
            />
        </Suspense>)
    }

    const onUnvoid = (data, callback) => {
        onUpdateStatus(data, callback, false)
    }


    const onVoid = (data, callback) => {
        onUpdateStatus(data, callback, true)
    }

    function onCreateCards() {
        if (!transactionTemplate.getTransactionList()) { return <UpdatingCard /> }
        return transactionTemplate.getTransactionList().length === 0 ? NoData() : <div className="cardlist" key={"empty-list"}>
            {transactionTemplate.getTransactionList().map((data) => {
                return <Card
                    controlKey={data.brandId}
                    isRounded={false}
                    item={{ title: data.name, description: <small className="text-muted"><b>{CodeShorter(data.brandId, 12) + " (" + (data.powerProduct ? "Power Product " : "Motorcycle ") + "Brand)"}</b></small>, data: data }}
                    voided={data.voided} onEdit={onEdit} onVoidOrDelete={onVoid} onUnvoid={onUnvoid}
                    icon={'/public/mgrproductbrand/image?' + new URLSearchParams({ id: data.brandId, imageType: 90 }).toString()}
                    removeButtonText="Void" />;
            })}
        </div>
    }

    return <TransactionTemplate controlId="txn-brand" bind={transactionTemplate} imports={{ onFetchList: onFetchList, onCreateCards: onCreateCards, onAddNew: onAddNew, onFormClosed: onTransactionFormClosed }} />
}