import React, { memo, useEffect, useRef, useState } from 'react';
import './ProductFeaturesGroup.scss';
import { Button, Form } from 'react-bootstrap';
import { TbX } from "react-icons/tb";
import { RiFindReplaceLine } from "react-icons/ri";

const ProductFeaturesGroup = ({ data, componentId, onRemove, onDataChanged }) => {

    const input = useRef();
    const [image, setImage] = useState();

    useEffect(() => { setImage(data.defaultImage) }, [])
    useEffect(() => { if (!data.value) { data.value = {} } }, [])

    return <div className='imagefeature-info-group' key={componentId}>
        <Button className='piigzkclsd ' variant='danger' onClick={onRemove}>                    <TbX /></Button>
        <div className='imgipp'>
            <Form.Control className='piigckipt' ref={input} type="file" accept="image/png, image/jpeg, image/webp, image/jpg"
                onChange={(e) => {
                    const file = e.target.files[0];
                    data.file = file;
                    if (file && file.type.startsWith('image/')) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            const image = e.target.result;
                            setImage(image);
                            onDataChanged();
                        }
                        reader.readAsDataURL(file);
                    }
                }} />
            <button type="button" className='piigzkimg ' onClick={(e) => { if (input.current) input.current.click() }}>
                {image ? [<img src={image ? image : undefined} />, <div className='img-change-text'><RiFindReplaceLine /></div>] : <div className='img-change-text-default'><RiFindReplaceLine /></div>}</button>
            <div className="piigdta">

                <Form.Group className="mb-2 image-info-group-control" >
                    <Form.Control className="default-focus" type="text" placeholder="Feature Name"
                        defaultValue={data.value ? data.value.featureName : ""}
                        onChange={(e) => { (data.value ? data.value : data.value = {}).featureName = e.currentTarget.value; onDataChanged(); }} />
                </Form.Group>

                <Form.Group className="image-info-group-control" >
                    <Form.Control as="textarea" rows={2} placeholder="Description"
                        defaultValue={data.value ? data.value.featureDescription : ""}
                        onChange={(e) => { (data.value ? data.value : data.value = {}).featureDescription = e.currentTarget.value; onDataChanged(); }} />
                </Form.Group>
            </div>
        </div> </div>
}

export default memo(ProductFeaturesGroup);
