import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './AutoPlayBasicSlider.scss';
export default function AutoPlayBasicSlider({ HTMLElements }) {



    /*    function renderItems() {
           const tags = [];
   
           for (let item of HTMLElements) {
               tags.push(<SwiperSlide className="autoplay-basic-slider-material-content">
                   <div className="autoplay-basic-slider-material-content">
                       <div className="autoplay-bslider-image-content">
                           <img loading='lazy' className="autoplay-bslider-image" src={item.image} />
                       </div>
   
                   </div></SwiperSlide>);
           }
           return tags;
       } */

    return [
        <Swiper
            loop={true}

            effect={'fade'}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
                delay: 3500,
                disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="autoplay-basic-slider" >
            {HTMLElements.map((HTMLElement) => {
                return <SwiperSlide className="autoplay-basic-slider-material-content">
                    <div className="autoplay-basic-slider-material-content">
                        {HTMLElement}
                    </div>
                </SwiperSlide>
            })}
        </Swiper>
    ]
}
