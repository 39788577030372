

export function awaitedQueryData(url: String) { return wrapPromise(getData(url)) }
 

export function awaitedSubmitData(url: String, value: FormData) { return wrapPromise(submitData(url, value)) }

export async function getData(url: any) {
    try {
        const response = await fetch(url, { method: 'GET'  });
        if (!response.ok) {
            if (response.status >= 400 && response.status < 500) {
                const failPromise = { fail: true, status: response.status, statusText: response.statusText, response: "" };
                const apiMessage = await response.json();
                failPromise.response = apiMessage.response;
                return failPromise;
            } else {
                const failPromise = { fail: true, status: response.status, statusText:"Server Error", response: "" };
                return failPromise;
            }
        } else {
            return response.json();
        }
    } catch (error) {
        return { error: error };
    }
}

 

export async function submitData(url: any, value: FormData) {
    try {
        const response = await fetch(url, { method: 'POST', body: value });
        if (!response.ok) {

            if (response.status >= 400 && response.status < 500) {
                const failPromise = { fail: true, status: response.status, statusText: response.statusText, response: "" };
                const apiMessage = await response.json();
                failPromise.response = apiMessage.response;
                return failPromise;
            } else {
                const failPromise = { fail: true, status: response.status, statusText:"Server Error", response: "" };
                return failPromise;
            }
        } else {
            return response.json();
        }
    } catch (error) {
        return { error: error };
    }
}

export function wrapPromise(promise: Promise<any>) {

    let status = "pending", result: any, suspend = promise.then(
        (r: any) => { status = "success"; result = r; },
        (e: any) => { status = "error"; result = e; });

    return {
        read() {
            if (status === "pending") { throw suspend; }
            else if (status === "error") { throw result; }
            else if (status === "success") { return result; }
        },
    };
}



