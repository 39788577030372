import React, { Suspense, useEffect, useRef, useState } from 'react';
import './Store.scss';
import { Helmet } from 'react-helmet';
import { Outlet, useNavigate } from 'react-router-dom/dist';
import InfoCreator from './Component/Helper/InfoCreator.jsx';
import AppFooter from './Component/ClientPages/AppFooter/AppFooter.jsx';
import AppNavbar from './Component/ClientPages/AppNavBar/AppNavbar.jsx';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import UpdatingCard from './Component//Helper/UpdatingCard.jsx';
import SuspenseBody from './Component//Helper/SuspenseBody.jsx';
import { awaitedQueryData } from './Component//Helper/PromiseWrapper.ts';
import { GET } from './Reusables/FetchHandler.jsx';
import Toast from './Reusables/Toast.jsx';
import { Badge, Button } from 'react-bootstrap';




export default function Store(props) {

    const { channel, width } = props;
    const logo = channel === 'VMI' ? `/images/logo/viajero.png?$ORIGIN_PNG$` : `/images/logo/jicjam.png?$ORIGIN_PNG$`;
    const [compareCountMC, setCompareCountMC] = useState(0);
    const [compareCountPP, setCompareCountPP] = useState(0);
    const [dailyverses, setDailyverses] = useState(<Helmet>
        <script async defer src="https://dailyverses.net/get/verse.js?language=kjv" ></script>
    </Helmet>)
    const verseType = useRef("kjv");
    const navigate = useNavigate()
    const onStorageUpdate = (event) => { if (event.key.startsWith("compare-")) { updateCompareCount() } }
    const [toastView, setToastView] = useState();
    const toastTimer = useRef();
    const [compareTrayVisible, setCompareTrayVisible] = useState();
    const [isUserViewingCompare, setIsUserViewingCompare] = useState();
    const [mainBusiness, setMainBusiness] = useState();
    const [marketingDeptInfo, setMarketingDeptInfo] = useState();

    useEffect(() => {
        onLoad();
        return () => { onUnload() };
    }, [])

    useEffect(() => {
        updateCompareCount()
    }, [isUserViewingCompare])

    useEffect(() => {
        clearInterval(toastTimer.current);
        if (toastView) {
            toastTimer.current = setInterval(() => {
                clearInterval(toastTimer.current);
                setToastView(undefined);
            }, 10000);
        }
    }, [toastView])

    function onLoad() {
        getMainBusinessInfo()
        getMarketingDeptInfo()
        updateCompareCount();

        window.addEventListener("storage", onStorageUpdate);
    }

    function onUnload() {
        localStorage.removeItem('acc-form-data')
        window.removeEventListener("storage", onStorageUpdate)
    }

    function getMainBusinessInfo() {
        $(`/public/business/main`, { businessId: channel },
            async (fail) => { },
            async (error) => { },
            async (success) => { setMainBusiness(await success.json()) })
    }

    function getMarketingDeptInfo() {
        $(`/public/marketingdept/main`, { businessId: channel },
            async (fail) => { },
            async (error) => { },
            async (success) => { setMarketingDeptInfo(await success.json()) })
    }



    function _$$(url, search, searchString, onFail, onSuccess) {
        return <LazyLoadComponent>
            <Suspense fallback={<UpdatingCard />}>
                <SuspenseBody
                    wrappedPromiseData={awaitedQueryData(`${url}?` + new URLSearchParams(search).toString() + (searchString ? `&${searchString}` : ``))}
                    onCreateBody={(result) => { if (result.fail) { onFail(result) } else { return onSuccess(result) } }} />
            </Suspense>
        </LazyLoadComponent>
    }

    function _$(url, search, onFail, onSuccess) {
        return _$$(url, search, undefined, onFail, onSuccess)
    }

    function $(url, search, onFailed, onError, onSuccess) {
        GET(url + (search ? `?${new URLSearchParams(search).toString()}` : ``), onFailed, onSuccess, onError)
    }

    function toast(title, message, type) {
        setToastView(
            <Toast variant={type ? type : "danger"} className='store-toast'
                onClose={() => { clearInterval(toastTimer.current); setToastView(undefined) }}
                headingText={title}
                descriptionText={message} />
        )
    }

    function addToCompare(productId, isPowerproduct) {
        const key = `compare-${channel}-${isPowerproduct ? "powerproduct" : "motorcycle"}`;
        const compareStorage = localStorage.getItem(key);
        const compareStorageArray = (compareStorage !== null) ? JSON.parse(compareStorage) : [];
        var currentStorageLength = 0;;
        const btn = <Button className='mt-4 toast-btn' onClick={(e) => { navigate(isPowerproduct ? "product-compare-pp" : "product-compare-mc") }}>
            Open Compare Page
        </Button>;

        compareStorageArray.forEach((e) => { if (e !== null) { currentStorageLength = currentStorageLength+1 } })
     
        if (currentStorageLength >= 3) {
            toast(`Maximum Reached - ${isPowerproduct ? "Power Product" : "Motorcycle"}`,
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {"The maximum count of items to be compared is reached. Only 3 products can be compared."}
                    {btn}
                </div>)
        } else {

            if (!compareStorageArray.includes(productId)) {

                if (compareStorageArray.includes(null)) {
                    const index = compareStorageArray.indexOf(null)
                    compareStorageArray[index] = productId;
                    localStorage.setItem(key, JSON.stringify(compareStorageArray));
                } else {
                    compareStorageArray.push(productId);
                    localStorage.setItem(key, JSON.stringify(compareStorageArray));

                }
                currentStorageLength = 0;
                compareStorageArray.forEach(element => { currentStorageLength = currentStorageLength + (element ? 1 : 0) });
                toast(`Added - ${isPowerproduct ? "Power Product" : "Motorcycle"}`,
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {"Product added for compare"}
                        {btn}
                    </div>

                    , "success ")
            } else {
                toast(``, <div style={{ display: "flex", flexDirection: "column" }}>
                    {"Already in a compare list"}
                    {btn}
                </div>, "success ")
            }
        }

        updateCompareCount()

    }

    function updateCompareCount() {
        var totalCount = 0;
        ["powerproduct", "motorcycle"].forEach((e) => {
            const key = `compare-${channel}-${e}`;
            const compareStorage = localStorage.getItem(key);
            const compareStorageArray = (compareStorage !== null) ? JSON.parse(compareStorage) : [];
            var currentStorageLength = 0;
            compareStorageArray.forEach(element => { currentStorageLength = currentStorageLength + (element ? 1 : 0) });
            totalCount = totalCount + currentStorageLength;
            if (e === "motorcycle") {
                setCompareCountMC(currentStorageLength)
            } else {
                setCompareCountPP(currentStorageLength)
            }
        })

        setCompareTrayVisible(totalCount !== 0)
    }

    function onCompareView(isViewing) {
        setIsUserViewingCompare(isViewing)
    }

    return <>
        <Helmet>
            <link rel="icon" href={`/${channel === 'VMI' ? "head_viajero" : "head_jicjam"}/favicon.ico`} />
            <link rel="apple-touch-icon" href={`/${channel === 'VMI' ? "head_viajero" : "head_jicjam"}/logo192.png`} />
            <link rel="manifest" href={`/${channel === 'VMI' ? "head_viajero" : "head_jicjam"}/manifest.json`} />
        </Helmet>

        <div id='store' style={channel === 'VMI' ? { "--color": "58, 123, 189" } : { "--color": "194, 2, 2" }}
            hastray={isUserViewingCompare ? "false" : compareTrayVisible ? "true" : "false"}>
            {dailyverses}
            <AppNavbar  {...props} logo={logo} channel={channel} $={$} />
            <div className='center-page' style={width > 960 ? { paddingTop: "130px" } : { paddingTop: "70px" }}>
                <Outlet context={{ ...props, addToCompare, onCompareView, toast, _$$, _$, $ }} />
            </div>,
            <AppFooter
                logo={logo}
                channel={channel}
                mainBusiness={mainBusiness}
                marketingDeptInfo={marketingDeptInfo}
                setDailyverses={setDailyverses}
                verseType={verseType} />
            {toastView}
            {isUserViewingCompare ? <></> :
                <div id="fi3bc5f" className='store-compare' show={compareTrayVisible ? "true" : "false"}>
                    {compareCountMC > 0 ? <button className='nr-btn-compare-mc' onClick={() => { navigate("product-compare-mc") }}  >
                        Motorcycle Compare  <Badge bg="primary">{compareCountMC}</Badge>
                    </button> : <></>}
                    {compareCountPP > 0 ? <button className='nr-btn-compare-pp' onClick={() => { navigate("product-compare-pp") }}  >
                        Power Product Compare  <Badge bg="primary">{compareCountPP}</Badge>
                    </button> : <></>}
                </div>}
        </div>
    </>

}
