

import "./LogIn.scss";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { POST, GET } from "../../Reusables/FetchHandler";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { PiFileLockLight } from "react-icons/pi";

const LogIn = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    document.title = `Viajero Group of Companies - admin`;
 
    useEffect(() => {
        const checkLoginStatus = async () => {
            GET("/api/mgr/claim",
                async (failedResponse) => { },
                async (successResponse) => { navigate("../admin/contentslider/binding") },
                async (errorResponse) => { });
        };
        checkLoginStatus();
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append("authRequest", new Blob([JSON.stringify({ username: username, password: password })], { type: "application/json" }))
        POST("/api/v1/login", formData,
            async (failedResponse) => {
                setMessage((await failedResponse.json()).message)
            },
            async (successResponse) => {
                navigate("../admin/contentslider/binding")
            },
            async (errorResponse) => {
                setMessage(JSON.stringify((await errorResponse.json())))
            }
        );
    };

    return (
        <div id="ctrlpanel-login">
            <div className="c-center">
                <form onSubmit={handleLogin}>
                    <h4><PiFileLockLight /> LOGIN</h4>
                    <div>
                        <FloatingLabel controlId="floatingUsername" label="Employee Identification Number">
                            <Form.Control
                                placeholder="Employee Identification Number"
                                type="text"
                                value={username}
                                onChange={(e) => {
                                    e.target.value = e.target.value.toUpperCase();
                                    setUsername(e.target.value);
                                }}
                                required />
                        </FloatingLabel>
                    </div>
                    <div>
                        <FloatingLabel controlId="floatingPassword" label="Password">
                            <Form.Control type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required />
                        </FloatingLabel>
                    </div>
                    <Button variant="primary" type="submit">Login</Button>
                </form>
            </div>
            {message && <p>{message}</p>}
        </div>
    );
};

export default LogIn;
