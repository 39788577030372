import React, { memo, useEffect, useRef, useState } from 'react';
import './Image.scss';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { TbX } from "react-icons/tb";
import { RiFindReplaceLine } from "react-icons/ri";

const Image = ({ data, componentId, onRemove, onDataChanged }) => {

    const input = useRef();
    const [image, setImage] = useState();

    useEffect(() => { setImage(data.defaultImage) }, [])

    return <div className='image-entry' key={componentId}><div className='imgipp'>
        <Form.Control className='piigckipt' ref={input} type="file" accept="image/png, image/jpeg, image/webp, image/jpg"
            onChange={(e) => {
                const file = e.target.files[0];
                data.file = file;
                if (file && file.type.startsWith('image/')) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const image = e.target.result;
                        setImage(image);
                        onDataChanged();
                    }
                    reader.readAsDataURL(file);
                }
            }} />
        <button
            type="button"
            className='piigzkimg '
            onClick={(e) => { if (input.current) input.current.click() }}>
            {image ?
                [<img src={image ? image : undefined} />, <div className='img-change-text'><RiFindReplaceLine /></div>] :
                <div className='img-change-text-default'><RiFindReplaceLine /></div>}</button>

        <Button
            className='rem-button'
            variant='danger'
            onClick={onRemove}>
            <TbX /></Button>



    </div> </div>

}

export default memo(Image);
