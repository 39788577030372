import React, { useEffect, useState } from 'react';
import { FaMotorcycle, FaTools } from 'react-icons/fa';
import { GiFullMotorcycleHelmet } from "react-icons/gi";
import { json, Link, useOutletContext } from 'react-router-dom/dist';
import Select from 'react-select';
import './StoreLocator.scss';
import { Badge, Button } from 'reactstrap';
import Fetcher from '../../Helper/Fetcher';
import { default as GoogleMap } from '../../../Reusables/Map';
import { Tab, Tabs } from 'react-bootstrap';
import { FaFacebookMessenger, FaSquareFacebook } from "react-icons/fa6";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function StoreLocator(props) {

 

    const { width, channel, _$, $, _$$ } = useOutletContext();
    const [mobileView, setMobileView] = useState(width <= 767);
    const [branches, setBranches] = useState()
    const [selectedBranch, setSelectedBranch] = useState()
    const [branchInfo, setBranchInfo] = useState()
    const [tags, setTags] = useState()
    const [images, setImages] = useState()

    document.title = `${channel === "VMI" ? "Viajero Motorsales Inc." : "Jicjam Cycle Inc."} - Store Finder`;

    useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); getBranches() }, [])
    useEffect(() => { setMobileView(width <= 767) }, [width])
    useEffect(() => { if (branches) { setSelectedBranch(branches[0].options[0].value) } }, [branches])
    useEffect(() => { if (selectedBranch) { getBranchInfo(); getImages(); } }, [selectedBranch])
    useEffect(() => { if (branchInfo) { getTags() } }, [branchInfo])

    function getBranches() {
        return $(`/public/store/branches/min`, { businessId: channel },
            async (failed) => { },
            async (error) => { },
            async (success) => {
                const result = await success.json();
                const options = new Map();
                const groupedOptions = []
                result.map((value) => {
                    var branches = options.get(value.BusinessArea[0].businessAreaName)
                    if (!branches) { branches = [] }
                    branches.push({ value: value, label: value.name })
                    options.set(value.BusinessArea[0].businessAreaName, branches)
                });
                for (const [key, value] of options.entries()) { groupedOptions.push({ label: key, options: value }) }
                if (groupedOptions.length !== 0) { setBranches(groupedOptions) }
            })
    }

    function getBranchInfo() {
        return $(`/public/store/branches/full`, { branchId: selectedBranch.branchId },
            async (failed) => { },
            async (error) => { },
            async (success) => { setBranchInfo(await success.json()) })
    }

    function getTags() {
        return $(`/public/store/branch/tags`, { tags: branchInfo.tags },
            async (failed) => { },
            async (error) => { },
            async (success) => { setTags(await success.json()) })
    }

    function getImages() {
        return $(`/public/mgrbranch/getimages`, { branchId: selectedBranch.branchId },
            async (failed) => { },
            async (error) => { },
            async (success) => { setImages(await success.json()) })
    }

    return <>
        <div id="store-locator" style={channel === 'VMI' ? { "--color": "58, 123, 189" } : { "--color": "194, 2, 2" }} mobile={mobileView ? "true" : "false"}>
            <div className='left-top-control'>
                <div className='sl-selector'>
                    <span>Branches</span>
                    {branches ? <Select className='select'
                        formatGroupLabel={(data) => {
                            return <div className="selection-group"  >
                                <span className='group-name'> {data.label}</span>
                                <span className='group-count'  >{data.options.length}</span>
                            </div>
                        }}
                        defaultValue={branches[0].options[0]}
                        options={branches}
                        onChange={(option) => { setSelectedBranch(option.value) }} /> : <></>}
                </div>
                <div className='sl-information '>
                    {branchInfo ? <>
                        <section className='branchinfo'>
                            <LazyLoadImage alt={branchInfo.imageUUID} src={`/public/mgrbranch/getimage?${new URLSearchParams({ branchId: branchInfo.branchId, imageUUID: branchInfo.imageUUID, type: "icon", quality: 70 })}`} />
                            <div>{selectedBranch.Business[0].businessName}</div>
                            <div>{(branchInfo.name.toUpperCase() + " BRANCH")}</div>
                            <desc>{selectedBranch.Business[0].description}</desc>
                        </section>

                        <Tabs defaultActiveKey="contact" id="tabs"  >

                            <Tab className='tab' eventKey="contact" title="Contact"  >
                                <section className='directcontact'>
                                    <label>Directly contact us</label>
                                    <div className='directcontatct-group'>
                                        <label>Telephone</label>
                                        {branchInfo.phoneNumbers.map((number) => {
                                            return <span>{number}</span>
                                        })}
                                    </div>
                                    <div className='directcontatct-group'>
                                        <label>Mobile</label>
                                        {branchInfo.mobileNumbers.map((number) => {
                                            return <span>{number}</span>
                                        })}
                                    </div>
                                    <div className='directcontatct-group'>
                                        <label>Via email</label>
                                        {branchInfo.emailAddress.map((emailaddress) => {
                                            return <span>{emailaddress}</span>
                                        })}
                                    </div>
                                </section>
                                <section className='socmed'>
                                    <label>You may also follow or contact us on social media for more updates</label>
                                    <a target='_blank' rel='noopener noreferrer' href={branchInfo.facebook}>
                                        <div className='smedia'><FaSquareFacebook /> {branchInfo.facebook.substring(branchInfo.facebook.lastIndexOf('/') + 1)}</div>
                                    </a>
                                    <a target='_blank' rel='noopener noreferrer' href={branchInfo.messenger}>
                                        <div className='smedia'><FaFacebookMessenger /> {branchInfo.messenger.substring(branchInfo.messenger.lastIndexOf('/') + 1)}</div>
                                    </a>
                                </section>
                                {<div   >{branchInfo.BusinessAreabusinessAreaName}</div>}
                            </Tab>

                            <Tab className='tab' eventKey="tags" title="Related">
                                {tags ?
                                    <section className='branchTags'>
                                        {tags.map((tag) => {
                                            return <div className='tag-group'>
                                                {tag.imageUUID ?
                                                    <LazyLoadImage alt={tag.imageUUID} src={`/public/mgrbusinesstag/image?${new URLSearchParams({ id: tag.imageUUID, imageType: 70 })}`} />
                                                    : <alternate>
                                                        {tag.tagName.charAt(0)}
                                                    </alternate>
                                                }
                                                <span>{tag.tagName}</span>
                                            </div>

                                        })}
                                    </section> : <></>}
                            </Tab>

                            <Tab className='tab' eventKey="images" title="Images">
                                {images ?
                                    <section className='branchImages'>
                                        {images.map((image) => {
                                            return <LazyLoadImage alt={image.imageUUID} src={`/public/mgrbranch/getimage?${new URLSearchParams({
                                                branchId: selectedBranch.branchId,
                                                imageUUID: image.imageUUID, type: "pictures", quality: 80
                                            })}`} />
                                        })}
                                    </section> : <></>}
                            </Tab>

                        </Tabs></>
                        : <></>}

                </div>
            </div>

            <div className='right-bottom-control'>
                <GoogleMap controlId={"sl-map"} source={branchInfo ? branchInfo.mapURI : undefined} />
            </div>

        </div>
        <div id='store-bottom-msg' style={channel === 'VMI' ? { "--color": "58, 123, 189" } : { "--color": "194, 2, 2" }}>
            We sincerely thank you for your continued patronage and trust in our services. Your loyalty is the cornerstone of our success, and we are committed to exceeding your expectations with every interaction. We look forward to serving you for many years to come.
        </div>
    </>
}
