import { useEffect, useState } from "react";
import Store from "./Store";
import Landing from "./Landing";
import AppHome from './Component/ClientPages/AppHome/AppHome';
import { BrowserRouter as Router, Navigate, Routes, Route } from 'react-router-dom/dist';
import ProductSpecs from "./Component/ClientPages/ProductSpecification/ProductSpecs";
import ProductList from "./Component/ClientPages/Products/ProductList";
import ProductCompare from "./Component/ClientPages/ProductCompare/ProductCompare";
import Services from "./Component/ClientPages/ComServices/Services";
import Career from "./Component/ClientPages/Career/Career";
import StoreLocator from "./Component/ClientPages/StoreLocator/StoreLocator";
import StoreLocatorInformation from "./Component/ClientPages/StoreLocator/StoreLocatorInformation";
import FAQ from "./Component/ClientPages/FAQ/FAQ";
import AccountStatus from "./Component/ClientPages/AccountStatus/AccountStatus";
import { createContext } from 'react';

// admin pages
import ContentSliderMaintenance from "./Component/AdminPages/ContentSliderMaintenance";
import ContentSliderBinding from "./Component/AdminPages/ContentSliderBinding";
import Admin from "./Component/AdminPages/Admin";
import ProductTypes from "./Component/AdminPages/ProductTypes";
import Brands from "./Component/AdminPages/Brands";
import Models from "./Component/AdminPages/Models";
import Product from "./Component/AdminPages/Product";
import Branches from "./Component/AdminPages/Branches";
import Business from "./Component/AdminPages/Business";
import BusinessArea from "./Component/AdminPages/BusinessArea";
import BusinessTag from "./Component/AdminPages/BusinessTag";
import FAQCategory from "./Component/AdminPages/FAQCategory";
import FAQAdmin from "./Component/AdminPages/FAQAdmin";
import LogIn from "./Component/AdminAuth/LogIn";
import { v4 as uuidv4 } from 'uuid';



export default function SiteRouter() {



    const [clientSizeWidth, setClientSizeWidth] = useState(document.body.clientWidth);
    const dateFormat = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };



    useEffect(() => {


        window.addEventListener("resize", function () {
            setClientSizeWidth(document.body.clientWidth)
        });

    }, []);

    useEffect(() => { }, [clientSizeWidth]);


    return <>
        <Router>
            <Routes>

                <Route path='/' element={<Landing />} />

                <Route path='viajero' element={<Store channel="VMI" width={clientSizeWidth} />}   >
                    <Route index element={<AppHome />} />
                    <Route path='product/:type/:productCode' static={false} element={<ProductSpecs key={"vmi-mc-spec"} />} />
                    <Route path='product/motorcycle' element={<ProductList category="Motorcyle" poweproduct={false} key={"vmi-mc"} />} />
                    <Route path='product/powerproducts' element={<ProductList category="Powerproducts" poweproduct={true} key={"vmi-pp"} />} />
                    <Route path='product-compare-pp' element={<ProductCompare poweproduct={true} />} />
                    <Route path='product-compare-mc' element={<ProductCompare poweproduct={false} />} />
                    <Route path='services' element={<Services />} />
                    <Route path='careers' element={<Career />} />
                    <Route path='storelocator' element={<StoreLocator />} />
                    <Route path='storelocator/:locationName' element={<StoreLocatorInformation />} />
                    <Route path='customercare/faq' element={<FAQ />} />
                    <Route path='customercare/accountstatus' element={<AccountStatus />} />
                </Route>

                <Route path='jicjam' element={<Store channel="JCI" width={clientSizeWidth} />} >
                    <Route index element={<AppHome />} />
                    <Route path='product/:type/:productCode' element={<ProductSpecs key={"jc-mc-spec"} />} />
                    <Route path='product/motorcycle' element={<ProductList category="Motorcyle" poweproduct={false} key={"jc-mc"} />} />
                    <Route path='product/powerproducts' element={<ProductList category="Powerproducts" poweproduct={true} key={"jc-pp"} />} />
                    <Route path='product-compare-pp' element={<ProductCompare poweproduct={true} />} />
                    <Route path='product-compare-mc' element={<ProductCompare poweproduct={false} />} />
                    <Route path='services' element={<Services />} />
                    <Route path='careers' element={<Career />} />
                    <Route path='storelocator' element={<StoreLocator />} />
                    <Route path='storelocator/:locationName' element={<StoreLocatorInformation />} />
                    <Route path='customercare/faq' element={<FAQ />} />
                    <Route path='customercare/accountstatus' element={<AccountStatus />} />
                </Route>

                <Route path='admin' element={<Admin dateFormat={dateFormat} />} >
                    <Route index element={<ContentSliderBinding />} />
                    <Route path='contentslider/maintenance' element={<ContentSliderMaintenance />} />
                    <Route path='contentslider/binding' element={<ContentSliderBinding />} />
                    <Route path='producttypes' element={<ProductTypes />} />
                    <Route path='productbrands' element={<Brands />} />
                    <Route path='productmodels' element={<Models />} />
                    <Route path='products' element={<Product />} />
                    <Route path='branches' element={<Branches />} />
                    <Route path='business' element={<Business />} />
                    <Route path='businessarea' element={<BusinessArea />} />
                    <Route path='businesstag' element={<BusinessTag />} />
                    <Route path='faqcategory' element={<FAQCategory />} />
                    <Route path='faq' element={<FAQAdmin />} />
                </Route>

                <Route path='controlpanel' element={<LogIn />} >
                    <Route index element={<LogIn />} />
                </Route>

            </Routes>
        </Router >
    </>


}