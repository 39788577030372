


export default class StringUtils {

    static splitByComma(textString) {
        let array = textString.split(", ");
       return array;
    }

    static isNullOrEmpty(textString) {
        if (textString === undefined) { return true };
        if (textString === null) { return true };
        if (textString.trim() === "") { return true };
        return false;
    }

}


