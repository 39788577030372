import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import './FAQ.scss';
import Fetcher from '../../Helper//Fetcher';
import { Accordion, AccordionContext, Button, Card, InputGroup, ListGroup, Modal, Spinner, useAccordionButton } from 'react-bootstrap';
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";
import Select from 'react-select';
import { useOutletContext } from 'react-router-dom';


function Header({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey),);
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
        <div className={`toggle ${isCurrentEventKey ? "active" : ""}`} onClick={decoratedOnClick}  >
            {children}
            {isCurrentEventKey ? <FiMinus /> : <FiPlus />}
        </div>
    );
}

export default function FAQ(props) {


    const { channel, $ } = useOutletContext();
    const [categories, setCategories] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [qnAs, setQnAs] = useState();

    document.title = `${channel === "VMI" ? "Viajero Motorsales Inc." : "Jicjam Cycle Inc."} - Frequently Asked Questions`;

    useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); getCategories() }, [])
    useEffect(() => { if (selectedCategory) { getQAndA() } }, [selectedCategory])

    function getCategories() {
        return $(`/public/faq/categories`, undefined,
            async (failed) => { },
            async (error) => { },
            async (success) => {
                setCategories((await success.json()).map((value) => { return { value: value, label: value.name.toUpperCase() } }))
            })
    }

    function getQAndA() {
        return $(`/public/faq/qnas`, { catId: selectedCategory.catId },
            async (failed) => { },
            async (error) => { },
            async (success) => {
                setQnAs(await success.json())
            })
    }


    return <div id='customer-faq' style={channel === 'VMI' ? { "--color": "58, 123, 189" } : { "--color": "194, 2, 2" }}>
        <span>FREQUENTLY ASKED QUESTIONS</span>
        <div className='sl-selector'>
            <InputGroup >
                <InputGroup.Text>CATEGORY</InputGroup.Text>
                {categories ? <Select className='select'
                    defaultValue={categories[0]}
                    options={categories}
                    onChange={(option) => { setSelectedCategory(option.value) }} /> : <></>}
            </InputGroup>

        </div>
        <Accordion >
            {qnAs ?
                qnAs.map((data) => {
                    return <Card>
                        <Header eventKey={data.faqId}>
                            <Card.Body>
                                <span>Q</span>{data.question}
                            </Card.Body>
                        </Header>
                        <Accordion.Collapse eventKey={data.faqId}>
                            <Card.Body className='preserve'>
                                <span>A</span>{data.answer}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                })
                : <></>}
        </Accordion>
    </div>

}

