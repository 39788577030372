import React, { memo } from 'react';
import './ManualEntry.scss';
import SuspenseBody from '../Component/Helper/SuspenseBody';
import { Suspense } from 'react';
import { awaitedQueryData } from '../Component/Helper/PromiseWrapper.ts';
import UpdatingCard from '../Component/Helper/UpdatingCard';


export default function Map({ source, controlId, width, height  }) {

    return<iframe id={controlId}
        width={width}
        height={height}
        loading="lazy"
        allowFullScreen
        src={source} />;
 
}

