
import { Suspense, useRef } from "react";
import "./BusinessTag.scss";
import TransactionTemplate from "../../Reusables/TransactionTemplate.jsx";
import { Button, Form } from "react-bootstrap";
import Card from "../Helper/Card.jsx";
import UpdatingCard from "../Helper/UpdatingCard.jsx";
import NoData from "../Helper/NoData.jsx";
import { awaitedSubmitData, awaitedQueryData } from "../Helper/PromiseWrapper.ts";
import SuspenseBody from "../Helper/SuspenseBody.jsx";
import Select from 'react-select';
import Toast from "./Toast.jsx";
import { v4 as uuidv4 } from 'uuid';
import { FaTags } from "react-icons/fa6";
import { ValueValidator } from "../../Reusables/ValueValidator.jsx";
export default function BusinessTag(props) {

    function onTransactionFormClosed() {
        formDataRef.current = undefined;
        formDataImageRef.current = undefined;
    }


    const onFetchList = (page) => {
        transactionTemplate.setCards(<UpdatingCard />)
        fetch('/api/mgrbusinesstag/list?' +
            new URLSearchParams({ page: page, limit: transactionTemplate.getLimit(), voided: transactionTemplate.getVoided() }).toString())
            .then(response => { return response.json() })
            .then(response => {
                transactionTemplate.setTransactionList(response.response);
                transactionTemplate.setTotalPages(Math.ceil(response.totalCount / transactionTemplate.getLimit()));
            })
    }


    function createEntryForm(title, type, data, callback) {
        const isEdit = data ? true : false;

        formDataRef.current = data ? { ...data } : {};
        formDataImageRef.current = new Blob();

        transactionTemplate.createEntryForm(title,
            <>
                <Form.Group className="mb-3" >
                    <Form.Label>Tag ID</Form.Label>
                    <Form.Control className="default-focus" type="text" name="name"
                        onChange={(e) => formDataRef.current.tagId = e.currentTarget.value}
                        placeholder="Enter tag id or abbreviation" defaultValue={data ? formDataRef.current.tagId : ""} />
                </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Tag Name</Form.Label>
                    <Form.Control type="text"
                        onChange={(e) => formDataRef.current.tagName = e.currentTarget.value}
                        placeholder="Enter tag name" defaultValue={data ? formDataRef.current.tagName : ""} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Tag Icon {isEdit ? `(Optional)` : ``}</Form.Label>
                    <Form.Control type="file" accept="image/png, image/jpeg, image/webp, image/jpg"
                        onChange={(e) => { formDataImageRef.current = e.target.files[0] }} />
                </Form.Group>

            </>,
            (type === "add" ?
                <Button onClick={(e) => { onSaveAddNew(e, callback) }}>Save New</Button> :
                <Button onClick={(e) => { onSaveEdit(e, data, callback) }}>Update</Button>));
    }

    const onAddNew = () => {
        createEntryForm("New Item", "add", undefined, transactionTemplate.setNewDataCallBack)
    }

    const onSaveAddNew = (e, callback) => {
        e.target.disabled = true;

        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(formDataRef.current.tagId, `Tag ID`)
            .add(formDataRef.current.tagName, `Tag Name`)
            .validate()
            .get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {
            const request = new FormData()
            request.append("businesstag", new Blob([JSON.stringify(formDataRef.current)], { type: "application/json" }));
            request.append("image", formDataImageRef.current);

            callback(<Suspense fallback={<></>}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrbusinesstag/savenew', request)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            e.target.disabled = false;
                            transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                        } else {
                            onFetchList(transactionTemplate.getCurrentPage());
                            transactionTemplate.setEntryForm(undefined)
                            transactionTemplate.setToast(<Toast variant="success" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={"201 OK"} descriptionText={"Item has been created"} />)
                        }
                    }} />
            </Suspense>)
        }
    }

    const onEdit = (data, callback) => {
        createEntryForm("Modify Item [ " + data.tagName + " ]", "update", data, callback)
    }

    const onSaveEdit = (e, data, callback) => {
        e.target.disabled = true;

        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(formDataRef.current.tagId, `Tag ID`)
            .add(formDataRef.current.tagName, `Tag Name`)
            .validate()
            .get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {

            const map = new FormData()
            map.append("oldID", data.tagId);
            map.append("newID", formDataRef.current.tagId);
            map.append("tagName", formDataRef.current.tagName);
            map.append("tagImage", formDataImageRef.current);
            map.append("imageUUID", formDataRef.current.imageUUID);

            callback(<Suspense fallback={<UpdatingCard />}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrbusinesstag/update', map)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            callback(undefined);
                            e.target.disabled = false;
                            transactionTemplate.setToast(<Toast
                                variant="danger"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={result.status + "  " + result.statusText}
                                descriptionText={result.response} />)
                        } else {
                            transactionTemplate.setTransactionList(transactionTemplate.getTransactionList().map(item => item === data ? result : item))
                            transactionTemplate.setEntryForm(undefined)
                            callback(undefined);
                            transactionTemplate.setToast(<Toast
                                variant="success"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={"201 OK"}
                                descriptionText={"Modification has been saved"} />)
                        }
                    }} />
            </Suspense>)
        }
    }


    function onUpdateStatus(data, callback, voided) {

        if (formDataRef.current) {
            transactionTemplate.setToast(<Toast
                variant="danger"
                onClose={() => { transactionTemplate.setToast(undefined) }}
                headingText={"405 Method Not Allowed"}
                descriptionText={"Your requested action is not allowed for currently editing data"} />)
            return;
        }

        const map = new FormData()
        map.append("id", data.tagId);
        map.append("voided", voided);
        callback(<Suspense fallback={<UpdatingCard />}>
            <SuspenseBody
                wrappedPromiseData={awaitedSubmitData('/api/mgrbusinesstag/updatestatus', map)}
                onCreateBody={(result) => {
                    if (result.fail) {
                        transactionTemplate.setToast(<Toast
                            variant="danger"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={result.status + "  " + result.statusText}
                            descriptionText={result.response} />)
                    } else {
                        transactionTemplate.setToast(<Toast
                            variant="success"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={"201 OK"}
                            descriptionText={"Modification has been saved"} />)
                        let pageNo = transactionTemplate.getCurrentPage();
                        if (transactionTemplate.getTransactionList().length === 1) { transactionTemplate.setCurrentPage(pageNo = transactionTemplate.getCurrentPage() - 1) }
                        onFetchList(pageNo);
                    }
                }}
            />
        </Suspense>)
    }

    const onUnvoid = (data, callback) => {
        onUpdateStatus(data, callback, false)
    }


    const onVoid = (data, callback) => {
        onUpdateStatus(data, callback, true)
    }

    function onCreateCards() {
        if (!transactionTemplate.getTransactionList()) { return <UpdatingCard /> }
        return transactionTemplate.getTransactionList().length === 0 ? NoData() : <div className="cardlist" key={"empty-list"}>
            {transactionTemplate.getTransactionList().map((data) => {
                console.log(data.imageUUID)
                return <Card
                    controlKey={data.tagId}
                    isRounded={false}
                    item={{ title: data.tagName, description: <small className="text-muted"><b>{data.tagId}</b></small>, data: data }}
                    voided={data.voided} onEdit={onEdit} onVoidOrDelete={onVoid} onUnvoid={onUnvoid}
                    isSvg={data.imageUUID ? false : true}
                    icon={data.imageUUID ? '/public/mgrbusinesstag/image?'
                        + new URLSearchParams({ id: data.imageUUID, imageType: 90, genkey: uuidv4() }).toString()
                        : <FaTags />}
                    removeButtonText="Void" />;
            })}
        </div>
    }


    const formDataRef = useRef();
    const formDataImageRef = useRef();
    const transactionTemplate = {};


    return <TransactionTemplate key={uuidv4()} controlId="txn-businesstag" bind={transactionTemplate} imports={{ onFetchList: onFetchList, onCreateCards: onCreateCards, onAddNew: onAddNew, onFormClosed: onTransactionFormClosed }} />
}