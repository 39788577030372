
import { Suspense, useEffect, useRef, useState } from "react";
import "./Branches.scss";
import TransactionTemplate from "../../Reusables/TransactionTemplate.jsx";
import { Button, Card as ImgCard, Form, InputGroup, Tab, Tabs, Badge } from "react-bootstrap";
import UpdatingCard from "../Helper/UpdatingCard.jsx";
import NoData from "../Helper/NoData.jsx";
import { awaitedSubmitData, awaitedQueryData } from "../Helper/PromiseWrapper.ts";
import SuspenseBody from "../Helper/SuspenseBody.jsx";
import Toast from "./Toast.jsx";
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";
import TransactionList from "../../Reusables/TransactionList.jsx";
import { ValueValidator } from "../../Reusables/ValueValidator.jsx";
import Select from 'react-select';
import DynamicEntry from "../Helper/DynamicEntry.jsx";

import { v4 as uuidv4 } from 'uuid';
import Card from "../Helper/Card.jsx";
import ListGroup from 'react-bootstrap/ListGroup';
import { TbEditCircle } from "react-icons/tb";
import { TbMapSearch } from "react-icons/tb";
import ManualEntry from "../../Reusables/ManualEntry.jsx";
import Map from "../../Reusables/Map.jsx";
import Image from "../../Reusables/Image.jsx";

export default function Branches(props) {

    const onFetchBusinessList = () => {
        setBusinessCards(<UpdatingCard />)
        setSelectedBusiness(undefined);
        fetch('/api/mgrbusiness/activelist')
            .then(response => { return response.json() })
            .then(response => {
                setBusinessList(response);
            })
    }

    const onFetchAreaList = () => {
        if (selectedBusiness) {
            areaTemplate.setTransactionList(undefined)
            setSelectedArea(undefined)
            setSelectedBranch(undefined)
            removeSelected(".ctmd-md-nav[selected=\"true\"]");
            fetch('/api/mgrbusinessarea/activelist')
                .then(response => { return response.json() })
                .then(response => {
                    areaTemplate.setTransactionList(response);
                    if (response.length === 0) {
                        transactionTemplate.setTransactionList([])
                        transactionTemplate.setTotalPages(0);
                        document.querySelector(".ctmd-md-nav").setAttribute("selected", "true");
                    }
                })
        }
    }

    const onFetchList = (page) => {
        if (selectedArea) {
            removeSelected(".ctmd-md-nav[selected=\"true\"]");
            transactionTemplate.setTransactionList(undefined)
            setSelectedBranch(undefined)
            fetch('/api/mgrbranch/list?' +
                new URLSearchParams({ businessId: selectedBusiness.businessId, businessAreaId: selectedArea.businessAreaId, page: page, limit: transactionTemplate.getLimit(), voided: transactionTemplate.getVoided() }).toString())
                .then(response => { return response.json() })
                .then(response => {
                    transactionTemplate.setTransactionList(response.response);
                    transactionTemplate.setTotalPages(Math.ceil(response.totalCount / transactionTemplate.getLimit()));
                })
        } else {
            transactionTemplate.setTransactionList([])
            transactionTemplate.setTotalPages(0);
            document.querySelector(".ctmd-md-nav").setAttribute("selected", "true");
        }
    }



    const onDisplayImages = () => {

        const createEmptyImgCard = (length) => {
            const elements = []; for (let i = 0; i < length; i++) { elements.push(<ImgCard className="image-card"></ImgCard>) }
            return elements;
        }


        if (selectedBranch) {
            return <Suspense fallback={<UpdatingCard />}>
                <SuspenseBody
                    wrappedPromiseData={awaitedQueryData('/public/mgrbranch/getimages?' + new URLSearchParams({
                        branchId: selectedBranch.branchId
                    }).toString())}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                            return <div>Fetching error</div>
                        } else {
                            prodDetImages.current = result;
                            return <div className="txnp-image">
                                {txnpGroup("Images", prodDetImages.current.length, onEditImages)}
                                <div className="txnp-body txnp-var-grid">
                                    {prodDetImages.current.map((data) => {
                                        return <ImgCard className="image-card">
                                            <ImgCard.Img variant="top" src={'/public/mgrbranch/getimage?' + new URLSearchParams({ branchId: selectedBranch.branchId, type: "pictures", imageUUID: data.imageUUID, quality: 80, genkey: uuidv4() }).toString()} />

                                        </ImgCard>;
                                    })}
                                    {prodDetImages.current.length < 2 ? <>{createEmptyImgCard(2 - prodDetImages.current.length)}</> : <></>}
                                </div>
                            </div>
                        }
                    }
                    }
                />
            </Suspense >
        }
    }
    const onDisplayMap = () => {


        if (selectedBranch) {
            return <div className="txnp-map">
                {txnpGroup("Map", undefined, (e) => onEdit(selectedBranch, setDisplayMap))}
                <Map controlId={"grid-map"} width={"100%"} height={"400"} source={selectedBranch.mapURI ? selectedBranch.mapURI : undefined} />
            </div>

        }
    }


    const txnpGroup = (text, count, action) => {
        return <div className="listgroup-header   mb-2">
            <span>{text}</span>
            <Badge bg="primary" pill>{count}</Badge>
            <div className="listgroup-control">
                <Button className="ml-auto " onClick={action} disabled={selectedBranch.voided}><TbEditCircle />
                    Edit
                </Button>
            </div>
        </div>
    }
    
    const loadDefaultFocus = (e) => {
        const element = document.getElementsByClassName("default-focus");
        if (element.length !== 0) { element[0].focus() }
    }

    function createBasicInfoEntry(defaultData) {
        const isEdit = defaultData ? true : false;

        formBasicInfoRef.current = defaultData ? { ...defaultData } : {};
        formBasicInfoRef.current.businessAreaId = selectedArea.businessAreaId;
        formBasicInfoRef.current.businessId = selectedBusiness.businessId;
        if (!(formBasicInfoRef.current.phoneNumbers)) { formBasicInfoRef.current.phoneNumbers = [] }
        if (!(formBasicInfoRef.current.mobileNumbers)) { formBasicInfoRef.current.mobileNumbers = [] }
        if (!(formBasicInfoRef.current.emailAddress)) { formBasicInfoRef.current.emailAddress = [] }
        if (!(formBasicInfoRef.current.tags)) { formBasicInfoRef.current.tags = [] }

        formBranchImageRef.current = {};

        return <Tab eventKey="pg=1" title="Basic Info">
            <div>

                <Form.Group className="mb-3" >
                    <Form.Label>Viajero Location ID</Form.Label>
                    <Form.Control className="default-focus"
                        placeholder="Enter viajero location ID mapped in main database (requested from Codexus)"
                        defaultValue={formBasicInfoRef.current.vglocationId}
                        onChange={(e) => formBasicInfoRef.current.vglocationId = new String(e.currentTarget.value)}
                        onLoad={loadDefaultFocus} />
                    <Form.Text className="text-muted">Location ID must be requested from Codexus if not known</Form.Text></Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Branch Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter branch name"
                        defaultValue={formBasicInfoRef.current.name}
                        onChange={(e) => formBasicInfoRef.current.name = new String(e.currentTarget.value)} />
                </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter address"
                        defaultValue={formBasicInfoRef.current.address}
                        onChange={(e) => formBasicInfoRef.current.address = new String(e.currentTarget.value)} />
                </Form.Group>


                <Form.Group className="mb-3">
                    <Form.Label>Branch Logo {isEdit ? `(Optional)` : ``}</Form.Label>
                    <Form.Control type="file" accept="image/png, image/jpeg, image/webp, image/jpg"
                        onChange={(e) => { formBranchImageRef.current = e.target.files[0] }} />
                </Form.Group>

                <Form.Group className="mb-3 pg1f ">
                    <Form.Label>Phone Number</Form.Label>
                    <DynamicEntry
                        buttonText={"Add phone number"}
                        defaultValues={formBasicInfoRef.current.phoneNumbers.map((data) => { return { key: uuidv4(), data: { key: data.key, value: data } } })}
                        onCreateRenderer={(object, onRemove, onDataChanged) =>
                            <ManualEntry key={object.key} componentId={object.key} data={object.data} onRemove={onRemove} onDataChanged={onDataChanged} />}
                        onChanged={(values) => { formBasicInfoRef.current.phoneNumbers = values.map((element) => element.data.value) }} />
                </Form.Group>


                <Form.Group className="mb-3 pg1f ">
                    <Form.Label>Mobile Number</Form.Label>
                    <DynamicEntry
                        buttonText={"Add mobile number"}
                        defaultValues={formBasicInfoRef.current.mobileNumbers.map((data) => { return { key: uuidv4(), data: { key: data.key, value: data } } })}
                        onCreateRenderer={(object, onRemove, onDataChanged) =>
                            <ManualEntry key={object.key} componentId={object.key} data={object.data} onRemove={onRemove} onDataChanged={onDataChanged} />}
                        onChanged={(values) => { formBasicInfoRef.current.mobileNumbers = values.map((element) => element.data.value) }} />
                </Form.Group>


                <Form.Group className="mb-3 pg1f ">
                    <Form.Label>Email Address</Form.Label>
                    <DynamicEntry
                        buttonText={"Add email address"}
                        defaultValues={formBasicInfoRef.current.emailAddress.map((data) => { return { key: uuidv4(), data: { key: data.key, value: data } } })}
                        onCreateRenderer={(object, onRemove, onDataChanged) =>
                            <ManualEntry key={object.key} componentId={object.key} data={object.data} onRemove={onRemove} onDataChanged={onDataChanged} />}
                        onChanged={(values) => { formBasicInfoRef.current.emailAddress = values.map((element) => element.data.value) }} />
                </Form.Group>


                <Suspense fallback={<UpdatingCard />}>
                    <SuspenseBody
                        wrappedPromiseData={awaitedQueryData('/api/mgrbusinesstag/activelist')}
                        onCreateBody={(result) => {
                            const options = result.map((e) => { return { value: e.tagId, label: e.tagName } })
                            let defaults = undefined;
                            if (formBasicInfoRef.current.tags) { defaults = options.map((element) => { for (const id of formBasicInfoRef.current.tags) { if (element.value === id) { return element } } }); }
                            return <Form.Group className="mb-3" >
                                <Form.Label>Tags</Form.Label>
                                <Select
                                    isMulti
                                    options={options}
                                    menuPlacement="top"
                                    defaultValue={defaults}
                                    onChange={(option) => formBasicInfoRef.current.tags = option.map((obj) => obj.value)} />
                            </Form.Group>
                        }} />
                </Suspense>



                <Form.Group className="mb-3" >
                    <Form.Label>Facebook Link</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter facebook link"
                        defaultValue={formBasicInfoRef.current.facebook}
                        onChange={(e) => formBasicInfoRef.current.facebook = new String(e.currentTarget.value)} />
                </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Messenger Link</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter messenger link"
                        defaultValue={formBasicInfoRef.current.messenger}
                        onChange={(e) => formBasicInfoRef.current.messenger = new String(e.currentTarget.value)} />
                </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Google Map Source</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter map source (Read Instruction Below)"
                        defaultValue={formBasicInfoRef.current.mapURI}
                        onChange={(e) => {
                            if (e.currentTarget.value.trim().startsWith("<iframe")) {
                                const iframeString = e.currentTarget.value;
                                const parser = new DOMParser();
                                const doc = parser.parseFromString(iframeString, 'text/html');
                                const iframe = doc.querySelector('iframe');
                                const src = iframe ? iframe.getAttribute('src') : null;
                                e.currentTarget.value = src
                            }
                            document.getElementById('form-map').src = e.currentTarget.value;
                            formBasicInfoRef.current.mapURI = new String(e.currentTarget.value)
                        }} />
                    <Form.Text className="text-muted">Go to Google Map then share a coordinate then select 'Embed a map' then click 'COPY HTML' and paste it here.</Form.Text>
                </Form.Group>
                <Map controlId={"form-map"} width={"100%"} height={"400"} source={formBasicInfoRef.current.mapURI ? formBasicInfoRef.current.mapURI : undefined} />
            </div>
        </Tab>

    }



    function createImagesEntry(defaultImages) {
        formImagesRef.current = defaultImages ? defaultImages : [];
        return <Tab eventKey="pg=5" title="Images" className="branch-image">
            <DynamicEntry
                defaultValues={formImagesRef.current.map((data) => {
                    return {
                        key: data.imageUUID,
                        data: {
                            defaultImage: '/public/mgrbranch/getimage?' + new URLSearchParams({ branchId: selectedBranch.branchId, imageUUID: data.imageUUID, type: "pictures", quality: 80, genkey: uuidv4() }).toString(),
                            key: data.imageUUID,
                            value: data.variationName,
                            objectId: data.variationId,
                            imageUUID: data.imageUUID
                        }
                    }
                })}
                onCreateRenderer={(object, onRemove, onDataChanged) => {
                    return <Image
                        key={object.key}
                        componentId={object.key}
                        data={object.data}
                        onRemove={onRemove}
                        onDataChanged={onDataChanged} />
                }}
                onChanged={(values) => { formImagesRef.current = values }} />
        </Tab>
    }

    function onAddNew() {
        const title = "New Item";
        const callback = transactionTemplate.setNewDataCallBack;
        transactionTemplate.createEntryForm(title,
            <Tabs id="form-tab" defaultActiveKey={"pg=1"} className="mb-3"  >
                {createBasicInfoEntry()}
                {createImagesEntry()}
            </Tabs>,
            <Button onClick={(e) => { onSaveAddNew(e, callback) }}>Save New</Button>)
    }

    function onSaveAddNew(e, callback) {
        e.target.disabled = true;

        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(formBasicInfoRef.current.vglocationId, `Viajero Location ID`)
            .add(formBasicInfoRef.current.name, `Branch Name`)
            .add(formBasicInfoRef.current.address, `Address`)
            .add(formBasicInfoRef.current.phoneNumbers, `Phone Number`)
            .add(formBasicInfoRef.current.mobileNumbers, `Mobile Number`)
            .add(formBasicInfoRef.current.emailAddress, `Email Address`)
            .add(formBasicInfoRef.current.tags, `Tags`)
            .add(formBasicInfoRef.current.facebook, `Facebook Link`)
            .add(formBasicInfoRef.current.messenger, `Messenger Link`)
            .add(formBasicInfoRef.current.mapURI, `Google Map Source`)
            .add(formBranchImageRef.current, `Branch Logo`)
            .add(formImagesRef.current.length === 0 ? `` : `1`, `Images (Requires atleast 1)`)
            .validate()
            .get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {
            const request = new FormData();
            for (const item of formImagesRef.current) { request.append("images", item.data.file); }
            request.append("branch", new Blob([JSON.stringify(formBasicInfoRef.current)], { type: "application/json" }));
            request.append("branchIcon", formBranchImageRef.current);

            callback(<Suspense fallback={<></>}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrbranch/savenew', request)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            e.target.disabled = false;
                            transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                        } else {
                            onFetchList(transactionTemplate.getCurrentPage());
                            transactionTemplate.setEntryForm(undefined)
                            transactionTemplate.setToast(<Toast variant="success" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={"201 OK"} descriptionText={"Item has been created"} />)
                        }
                    }} />
            </Suspense>)
        }
    }



    function onEditImages(e) {
        const title = "Edit Images";
        const callback = setDisplayImages;
        transactionTemplate.createEntryForm(title,
            <Tabs id="form-tab" defaultActiveKey={"pg=5"} className="mb-3"  >
                {createImagesEntry(prodDetImages.current)}
            </Tabs>,
            <Button onClick={(e) => {

                const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
                    .add(formImagesRef.current.length === 0 ? `` : `1`, `Images (Requires atleast 1)`)
                    .validate()
                    .get();

                if (!isValid) {
                    e.target.disabled = false;
                }
                else {
                    const request = new FormData(), variationsData = [];
                    for (const item of formImagesRef.current) { variationsData.push({ imageUUID: item.data.imageUUID, branchId: selectedBranch.branchId }) }
                    for (const item of formImagesRef.current) { request.append("images", item.data.file ? item.data.file : new Blob()); }
                    request.append("branchId", selectedBranch.branchId);
                    request.append("branchImages", new Blob([JSON.stringify(variationsData)], { type: "application/json" }));
                    callback(<Suspense fallback={<UpdatingCard />}>
                        <SuspenseBody
                            wrappedPromiseData={awaitedSubmitData('/api/mgrbranch/updateimages', request)}
                            onCreateBody={(result) => {
                                if (result.fail) {
                                    e.target.disabled = false;
                                    transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                                } else {
                                    transactionTemplate.setEntryForm(undefined)
                                }
                                return onDisplayImages();
                            }} />
                    </Suspense>)
                }
            }}>Update</Button>)
    }



    function onEdit(selectedBranch, callback) {

        if (selectedBranch) {
            formBasicInfoRef.current = selectedBranch;
            const title = "Update Item";
            transactionTemplate.createEntryForm(title,
                <Tabs id="form-tab" defaultActiveKey={"pg=1"} className="mb-3"  >
                    {createBasicInfoEntry(selectedBranch)}
                </Tabs>,
                <Button onClick={(e) => { onSaveEdit(e, callback) }}>Update</Button>)
        }
    }

    const onSaveEdit = (e, callback) => {
        e.target.disabled = true;

        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(formBasicInfoRef.current.vglocationId, `Viajero Location ID`)
            .add(formBasicInfoRef.current.name, `Branch Name`)
            .add(formBasicInfoRef.current.address, `Address`)
            .add(formBasicInfoRef.current.phoneNumbers, `Phone Number`)
            .add(formBasicInfoRef.current.mobileNumbers, `Mobile Number`)
            .add(formBasicInfoRef.current.emailAddress, `Email Address`)
            .add(formBasicInfoRef.current.tags, `Tags`)
            .add(formBasicInfoRef.current.facebook, `Facebook Link`)
            .add(formBasicInfoRef.current.messenger, `Messenger Link`)
            .add(formBasicInfoRef.current.mapURI, `Google Map Source`)
            .add(formBasicInfoRef.current.imageUUID, `Branch Logo`)
            .add(formBranchImageRef.current, `Branch Logo`)
            .validate()
            .get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {
            const request = new FormData()
            request.append("branch", new Blob([JSON.stringify(formBasicInfoRef.current)], { type: "application/json" }));
            request.append("icon", formBranchImageRef.current);

            callback(<Suspense fallback={<UpdatingCard />}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrbranch/updatebasicinfo', request)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            e.target.disabled = false;
                            transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                        } else {
                            onFetchList(transactionTemplate.getCurrentPage());
                            transactionTemplate.setEntryForm(undefined)
                            transactionTemplate.setToast(<Toast variant="success" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={"201 OK"} descriptionText={"Item has been created"} />)
                        }
                    }} />
            </Suspense>)
        }
    }

    const onUnvoid = (data, callback) => {
        onUpdateStatus(data, callback, false)
    }


    const onVoid = (data, callback) => {
        onUpdateStatus(data, callback, true)
    }

    function onUpdateStatus(data, callback, voided) {

        if (formBasicInfoRef.current) {
            transactionTemplate.setToast(<Toast
                variant="danger"
                onClose={() => { transactionTemplate.setToast(undefined) }}
                headingText={"405 Method Not Allowed"}
                descriptionText={"Your requested action is not allowed for currently editing data"} />)
            return;
        }

        const map = new FormData()
        map.append("branchId", data.branchId);
        map.append("voided", voided);
        callback(<Suspense fallback={<UpdatingCard />}>
            <SuspenseBody
                wrappedPromiseData={awaitedSubmitData('/api/mgrbranch/updatestatus', map)}
                onCreateBody={(result) => {
                    if (result.fail) {
                        transactionTemplate.setToast(<Toast
                            variant="danger"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={result.status + "  " + result.statusText}
                            descriptionText={result.response} />)
                    } else {
                        transactionTemplate.setToast(<Toast
                            variant="success"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={"201 OK"}
                            descriptionText={"Modification has been saved"} />)
                        let pageNo = transactionTemplate.getCurrentPage();
                        if (transactionTemplate.getTransactionList().length === 1) { transactionTemplate.setCurrentPage(pageNo = transactionTemplate.getCurrentPage() - 1) }
                        onFetchList(pageNo);
                    }
                }}
            />
        </Suspense>)
    }




    function onCreateBranchesCards() {
        if (!transactionTemplate.getTransactionList()) { return <UpdatingCard /> }
        return transactionTemplate.getTransactionList().length === 0 ? NoData() :
            <div className="cardlist">
                {transactionTemplate.getTransactionList().map((branch) => {

                    function displayTypes() {
                        return <div className="type-names"><Suspense fallback={<>...</>}>
                            {branch.tags.map((tagId) => {
                                return <LazyLoadComponent>
                                    <SuspenseBody
                                        wrappedPromiseData={awaitedQueryData('/api/mgrbusinesstag/get?' + new URLSearchParams({ id: tagId }).toString())}
                                        onCreateBody={(result) => {
                                            if (result.fail) {
                                                transactionTemplate.setToast(<Toast variant="danger" onClose={() => { transactionTemplate.setToast(undefined) }} headingText={result.status + "  " + result.statusText} descriptionText={result.response} />)
                                                return <></>
                                            } else {
                                                return <Badge >{result.tagName}</Badge>
                                            }
                                        }}
                                    />
                                </LazyLoadComponent>
                            }
                            )}
                        </Suspense>
                        </div>
                    }

                    return <Card isRounded={false}
                        item={{
                            title: <>{selectedBusiness.businessName + " " + branch.name} <small className="text-muted"> {" (" + selectedArea.businessAreaName + ")"} </small> </>,
                            description: <small className="text-muted"><b>{branch.vglocationId}</b></small>,
                            data: branch
                        }}
                        voided={branch.voided}
                        onEdit={onEdit}
                        onVoidOrDelete={onVoid}
                        onUnvoid={onUnvoid}
                        icon={'/public/mgrbranch/getimage?' + new URLSearchParams({
                            branchId: branch.branchId,
                            imageUUID: branch.imageUUID,
                            type: "icon",
                            quality: 90,
                            genkey: uuidv4()
                        }).toString()}
                        removeButtonText="Void"
                        onClick={(e) => {
                            removeSelected("div.card[selected=\"true\"]");
                            e.currentTarget.setAttribute("selected", "true");
                            setSelectedBranch(branch)
                        }}
                        addtlDesc={<div className="addtl-card-inf">
                            {displayTypes(branch)}
                            {branch.classification ? <span > <Badge className="other-class" >{branch.classification} </Badge></span> : <></>}
                        </div>}
                    />;
                })}
            </div>
    }

    function onCreateAreaCards() {
        if (!areaTemplate.getTransactionList()) { return <UpdatingCard /> }
        return areaTemplate.getTransactionList().length === 0 ? <></> :
            <div className="list-selector">
                {areaTemplate.getTransactionList().map((area) => {
                    return <button className="bl-area-selector" onClick={
                        (e) => {
                            const prevSelected = document.querySelectorAll(".bl-area-selector[selected=\"true\"]");
                            if (prevSelected.length !== 0) { prevSelected[0].removeAttribute("selected") }
                            e.currentTarget.setAttribute("selected", "true");
                            setSelectedArea(area)
                        }}>


                        <TbMapSearch />

                        {area.businessAreaName}</button>;
                })}
            </div>
    }

    function onArealCardsChanged() {
        if (areaTemplate.getTransactionList()) {
            const prevSelected = document.querySelectorAll(".bl-area-selector");
            if (prevSelected.length !== 0) { prevSelected[0].setAttribute("selected", "true") };
            setSelectedArea(areaTemplate.getTransactionList()[0])
        } else {
            transactionTemplate.setTransactionList([])
        }
    }

    function removeSelected(selector) {
        const elements = document.querySelectorAll(selector);
        if (elements.length !== 0) { elements[0].removeAttribute("selected") }
    }

    function setSelected(selector) {
        const elements = document.querySelectorAll(selector);
        if (elements.length !== 0) { elements[0].setAttribute("selected", "true") };
    }

    function onTransactionFormClosed() {
        formBasicInfoRef.current = undefined;



        formBranchImageRef.current = undefined;
        formImagesRef.current = undefined;


    }

    function onTransactionListUpdated(transactionList) {
        prodDetSpecifications.current = [];
        prodDetOtherSpecifications.current = [];
        prodDetIntroduction.current = undefined;
        prodDetImages.current = [];
        prodDetFeatures.current = [];
        if (transactionList) {
            if (transactionList.length !== 0) {
                if (selectedBranch) {

                } else {
                    setSelectedBranch(transactionList[0])
                    setSelected("div.card");

                }
            }
        }
    }

    function updateBusinessCards() {
        if (!businessList) {
            setBusinessCards(undefined)
        } else {
            setBusinessCards(<div className="list-selector business-selector">
                {businessList.map((data) => {
                    return <button className="bl-business-selector" variant="light" onClick={(e) => {
                        removeSelected(".bl-business-selector[selected=\"true\"]");
                        e.currentTarget.setAttribute("selected", "true");
                        setSelectedBusiness(data)
                    }}>
                        <LazyLoadImage src={'/public/mgrbusiness/image?' + new URLSearchParams({ id: data.imageUUID, imageType: 90 }).toString()}
                        /> </button>;
                })}
            </div>)
        }
    }

    const formBasicInfoRef = useRef();
    const formImagesRef = useRef();
    const formBranchImageRef = useRef();

    const prodDetSpecifications = useRef([]);
    const prodDetOtherSpecifications = useRef([]);
    const prodDetIntroduction = useRef();
    const prodDetImages = useRef([]);
    const prodDetFeatures = useRef([]); // Array

    const areaTemplate = {};
    const transactionTemplate = {};
    const [businessCards, setBusinessCards] = useState();
    const [businessList, setBusinessList] = useState();
    const [selectedBusiness, setSelectedBusiness] = useState();
    const [selectedArea, setSelectedArea] = useState();
    const [selectedBranch, setSelectedBranch] = useState();
    const [displayImages, setDisplayImages] = useState();
    const [displayMap, setDisplayMap] = useState();


    useEffect(() => { onFetchBusinessList() }, [])
    useEffect(() => { if (businessList) { setSelected(".bl-business-selector"); setSelectedBusiness(businessList[0]) } }, [businessCards])
    useEffect(() => { if (selectedBusiness) { removeSelected(".bl-area-selector[selected=\"true\"]"); onFetchAreaList(1) }; }, [selectedBusiness])
    useEffect(() => { if (selectedArea) { transactionTemplate.setCurrentPage(1); onFetchList(1) }; }, [selectedArea])
    useEffect(() => { updateBusinessCards() }, [businessList])
    useEffect(() => { if (selectedBranch) { setDisplayImages(onDisplayImages()); setDisplayMap(onDisplayMap()); } }, [selectedBranch])

    return <TransactionTemplate
        controlId="txn-branches"
        bind={transactionTemplate}
        onTransactionListUpdated={onTransactionListUpdated}
        imports={{ onFetchList: onFetchList, onCreateCards: onCreateBranchesCards, onAddNew: onAddNew, onFormClosed: onTransactionFormClosed }}
        leftSideComp={
            <div className="txn-branch-pref">
                {businessCards}
                <TransactionList controlId="txn-branch-areas" bind={areaTemplate} imports={{ onFetchList: onFetchAreaList, onCreateCards: onCreateAreaCards, onCardsChanged: onArealCardsChanged }} />
            </div>}
        rightSideComp={<div className="txn-branch-fd">

            <div className="txnp">
                {selectedBranch ? <ListGroup>
                    <ListGroup.Item  > {displayImages}</ListGroup.Item>
                    <ListGroup.Item  > {displayMap}</ListGroup.Item>
                </ListGroup> : <></>}
            </div>
        </div>}

        onListTypeChanging={() => setSelectedBranch(undefined)}
    />
}