

import { useEffect, useRef, useState } from "react";
import "./TransactionTemplate.scss";
import Pagination from "./Pagination.jsx";
import { TbPlus, TbSwitch2, TbSwitch, TbX } from "react-icons/tb";
import { Button, Form, InputGroup } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import { CONNECT } from "./FetchHandler.jsx";
import { useNavigate } from "react-router-dom";
import AdminContext from "../Component/AdminPages/AdminContext.jsx";
import { useContext } from "react";

export default function TransactionTemplate({ key, bind, imports, controlId, leftSideComp, rightSideComp, onTransactionListUpdated, onListTypeChanging }) {

    const { value, setValue } = useContext(AdminContext);

    function createEntryForm(title, formBody, formAction, formId) {
        if (formRef.current) { formRef.current.reset() }
        setEntryForm(<div className="entry-form-pane" key={uuidv4()}>
            <div className="entry-form-title">
                <span>{title}</span> <button className="entry-form-close" onClick={(e) => setEntryForm(undefined)}><TbX /></button>
            </div>
            <div className="entry-form-md">
                <Form id={formId ? formId : "entry-form"} ref={formRef} onSubmit={(e) => { e.preventDefault(); document.getElementById("entry-form-button").click() }}>
                    <>{formBody}</>
                </Form >
            </div>
            <div className="entry-form-action">
                <> {formAction}</>
            </div>
        </div>)
    }


    /**
      * internal references
      */
    const toastTimer = useRef(undefined);
    const formRef = useRef(null);
    const navigate = useNavigate();

    /**
     * function states
     */
    const [limit, setLimit] = useState(10);
    const [maxButtons, setMaxButtons] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [voided, setVoided] = useState(false);
    const [transactionList, setTransactionList] = useState(undefined);
    const [cards, setCards] = useState();
    const [entryForm, setEntryForm] = useState();
    const [toast, setToast] = useState();
    const [newDataCallback, setNewDataCallBack] = useState(false);

    /**
     * template reference for external use 
     */
    bind.createEntryForm = createEntryForm;
    bind.getLimit = () => { return limit };
    bind.setLimit = (value) => { setLimit(value) };
    bind.getMaxButtons = () => { return maxButtons }
    bind.setMaxButtons = (value) => { setMaxButtons(value) };
    bind.getTransactionList = () => { return transactionList };
    bind.setTransactionList = (value) => { setTransactionList(value) };
    bind.getCards = () => { return cards };
    bind.setCards = (value) => { setCards(value) };
    bind.getEntryForm = () => { return entryForm };
    bind.setEntryForm = (value) => { setEntryForm(value) }
    bind.getToast = () => { return toast };
    bind.setToast = (value) => { setToast(value) };
    bind.getNewDataCallback = () => { return newDataCallback };
    bind.setNewDataCallBack = (value) => { setNewDataCallBack(value) };
    bind.getTotalPages = () => { return totalPages };
    bind.setTotalPages = (value) => { setTotalPages(value) };
    bind.getCurrentPage = () => { return currentPage };
    bind.setCurrentPage = (value) => { setCurrentPage(value) };
    bind.getVoided = () => { return voided };
    bind.setVoided = (value) => { setVoided(value) };
    Object.seal(bind);

    /**
     * states effects when function states changed
     */
    useEffect(() => {
        setCurrentPage(1);
        imports.onFetchList(1)
    }, [voided])

    useEffect(() => {
        setCards(imports.onCreateCards())

    }, [transactionList])

    useEffect(() => {
        if (onTransactionListUpdated) { onTransactionListUpdated(transactionList) }

    }, [cards])

    useEffect(() => {
        if (entryForm === undefined) {

            formRef.current = undefined
            imports.onFormClosed()
        }
        else {

            const element = document.getElementsByClassName("default-focus");
            if (element.length !== 0) { element[0].focus() }
        }
    }, [entryForm])


    useEffect(() => {
        clearInterval(toastTimer.current);
        if (toast) {
            toastTimer.current = setInterval(() => {
                clearInterval(toastTimer.current);
                setToast(undefined);
            }, 10000);
        }
    }, [toast])

    useEffect(() => {
        CONNECT(
            async (failedResponse) => { navigate("/controlpanel") },
            async (successResponse) => { setValue(controlId) },
            async (errorResponse) => { navigate("/controlpanel") }
        )
    }, [])


    /**
     * output 
     */
    return [
        <div key={key} id={controlId} className="tx-tmp-8" >
            <div className="contentmd">
                <div className="ctmd">
                    <div className="ctmd-left"> {leftSideComp}</div>
                    <div className="ctmd-center">
                        <div className="ctmd-center-nav">
                            <div className="ctmd-md-nav">
                                <Button className="create-new card-button" variant="primary" onClick={imports.onAddNew}><TbPlus />Add New</Button>
                                <InputGroup>
                                    <Button className="switch-voided switch-type card-button" variant="primary" disabled={voided} onClick={(e) => {
                                        if (onListTypeChanging) { onListTypeChanging() }
                                        setVoided(true);
                                    }}><TbSwitch />Voided List </Button>
                                    <Button className="switch-active switch-type card-button" variant="primary" disabled={!voided} onClick={(e) => {
                                        if (onListTypeChanging) { onListTypeChanging() }
                                        setVoided(false);
                                    }}><TbSwitch2 />Active list </Button></InputGroup>
                            </div>
                        </div>
                        <div className="ctmd-center-top">
                            <div className="ctmd-md">{cards}</div>
                        </div>
                        <div className="ctmd-center-bottom">
                            <div className={ (transactionList ? (transactionList.length === 0 ? " empty" : "paginationmd") : "")}>
                                <Pagination maxButtons={maxButtons} currentPage={currentPage} totalPages={totalPages} onSetPageNo={setCurrentPage} onPageChange={(pageNo) => { imports.onFetchList(pageNo) }} />
                            </div>
                        </div>
                    </div>
                    <div className={(transactionList ? (transactionList.length === 0 ? " empty" : "ctmd-right") : "")}>{rightSideComp}</div>
                </div>
                {entryForm}
                {newDataCallback}
            </div>
        </div>,
        <>{toast}</>
    ]


}