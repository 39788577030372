import { useEffect, useState } from "react";
import './ImageGridCollage.scss';
import '../VenoBox/venobox.min.scss';
import VenoBox from '../VenoBox/venobox.min.jsx';
export default function ImageGridCollage(props) {

    const [images] = useState(props.images);
    let count = images.length;

    useEffect(() => {
        if (count !== 0)
            new VenoBox({ fitView: true, numeration: true, infinigall: true, share: true, spinner: 'rotating-plane' })
    }, [images]);


    const renderImage = (index, hidden) => {

        const
            gallery = images[index].gallery,
            galleryClass = images[index].galleryClass + " " + (hidden ? "indexHidden" : ""),
            imagePath = images[index].imagePath,
            imageIconPath = images[index].imageIconPath,
            rel = count > 5 & index === 4 ? <div id="vbox-related"><p>{"+" + (count - 5)}</p></div> : <></>,
            figure = <figure><img loading="lazy" src={imageIconPath} alt="" />{rel}</figure>;

        return <a className={galleryClass} data-gall={gallery} href={imagePath}>{figure} </a>;
    }

    const renderGrid = () => {
        const tags = [];
        switch (count) {
            case 1:
                tags.push(
                    [
                        <div>{renderImage(0, false)}</div>
                    ])
                break;
            case 2:
                tags.push(
                    [
                        <div>{renderImage(0, false)}</div>,
                        <div>{renderImage(1, false)}</div>
                    ])
                break;
            case 3:
                tags.push(
                    [
                        <div>{renderImage(0, false)}</div>,
                        <section>
                            <div>{renderImage(1, false)}</div>
                            <div>{renderImage(2, false)}</div>
                        </section>
                    ])
                break;
            case 4:
                tags.push(
                    [
                        <div>{renderImage(0, false)}</div>,
                        <div>{renderImage(1, false)}</div>,
                        <div>{renderImage(2, false)}</div>,
                        <div>{renderImage(3, false)} </div>
                    ])
                break;
            case 5:
                tags.push(
                    [
                        <section>
                            <div>{renderImage(0, false)}</div>
                            <div>{renderImage(1, false)}</div>
                        </section>,
                        <section>
                            <div>{renderImage(2, false)}</div>
                            <div>{renderImage(3, false)}</div>
                            <div>{renderImage(4, false)}</div>
                        </section>,
                    ])
                break;
            default:
                const hiddenTags = [];

                for (let index = 5; index < count; index++) {
                    hiddenTags.push(<div>{renderImage(index, true)}</div>);
                }

                tags.push(
                    [
                        <section section="top">
                            <div>{renderImage(0, false)}</div>
                            <div>{renderImage(1, false)}</div>
                        </section>,
                        <section section="bottom">
                            <div>{renderImage(2, false)}</div>
                            <div>{renderImage(3, false)}</div>
                            <div>{renderImage(4, false)}</div>
                        </section>,
                        <section className="indexHidden">
                            {hiddenTags}
                        </section>
                    ])
        }
        return <div id="imageGridCollage" count={count > 5 ? "5+" : count}>{tags}</div>;
    }


    if (count !== 0) return renderGrid();

}
