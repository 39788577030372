export default class Fetcher {
    static async fetch(url, callback) { fetch(url).then(response => response.json()).then(jsonresponse => callback(jsonresponse)) };

    static onFetchProductSpecification(productId, df1c04eb2) { this.fetch(`/13d3e2ac00${productId}34az500983b`, df1c04eb2) };
    static onFetchProductIntroduction(productId, df1c04eb2) { this.fetch(`/13d3e2ac00${productId}34az500984b`, df1c04eb2) };
    static onFetchProductFeatures(productId, df1c04eb2) { this.fetch(`/13d3e2ac00${productId}35az500985c`, df1c04eb2) };
    static onFetchStoreBranches(businessId, df1c04eb2) { this.fetch(`/478c6060caa911f93b2970df956504de/${businessId}`, df1c04eb2) };
    static onFetchBranchImages(vglocationId, df1c04eb2) { this.fetch(`/d714b7b23cb48090b342ad4a92067d0b${vglocationId}`, df1c04eb2) };
    static onFetchProductData(productIds, df1c04eb2) { this.fetch(`/pdata${productIds}`, df1c04eb2) };
    static onFetchMainSlides(locationId, df1c04eb2) { this.fetch(`/f4d5c52946e476c66dfc36433c82aa78${locationId}`, df1c04eb2) };
    static onFetchNewsAndEventsSlides(locationId, df1c04eb2) { this.fetch(`/f4d5c52946e476c66dfc36433c82aa79${locationId}`, df1c04eb2) };
    static onFetchAnnouncementsAndPromoSlides(locationId, df1c04eb2) { this.fetch(`/f4d5c52946e476c66dfc36433c82aa80${locationId}`, df1c04eb2) };
    static onFetchCareers(df1c04eb2) { this.fetch(`/926fd4c147731ce7b755dd12d9bf2c58`, df1c04eb2) };
    static onFetchHRInfs(df1c04eb2) { this.fetch(`/3ea6f662488e0cd22a58db13303a9bca`, df1c04eb2) };
    static onFetchURL(url, df1c04eb2) { this.fetch(`/url=${url}`, df1c04eb2) };
    static onFetchIndexes(df1c04eb2) { this.fetch(`/indexList`, df1c04eb2) };
    static onFetchDealedImages(df1c04eb2) { this.fetch(`/actprodimgs`, df1c04eb2) };
    static onFetchFeatured(locationId, df1c04eb2) { this.fetch(`/featured/q/${locationId}`, df1c04eb2) };
    static onFetchPowerproducts(locationId, df1c04eb2) { this.fetch(`/powerproducts/q/${locationId}`, df1c04eb2) };
    static onFetchProductInformations(productId, df1c04eb2) { this.fetch(`/f4pdtcuirmairoz${productId}`, df1c04eb2) };
    static onFetchProductList(locationId, category, search, df1c04eb2) { this.fetch(`/productlist&${locationId}/${category}/search?${search}`, df1c04eb2) };
    static onFetchTypes(locationId, category, df1c04eb2) { this.fetch(`/q/${locationId}/types=${category}`, df1c04eb2) };
    static onFetchBrands(locationId, category, df1c04eb2) { this.fetch(`/q/${locationId}/brands=${category}`, df1c04eb2) };
    static onFetchEnginedisplacements(locationId, category, df1c04eb2) { this.fetch(`/q/${locationId}/enginedisplacements=${category}`, df1c04eb2) };
    static onFetchFAQCategories(df1c04eb2) { this.fetch(`/faqcatlist`, df1c04eb2) };
    static onFetchFAQuestions(categoryId, df1c04eb2) { this.fetch(`/faqQuestionlist=${categoryId}`, df1c04eb2) };
    static onFetchFAQAnswer(faqId, df1c04eb2) { this.fetch(`/faqAnswerOf=${faqId}`, df1c04eb2) };

    static onFetchCustomerInfor(engineNo, customerLastname, df1c04eb2) { this.fetch(`/engineno=${engineNo}/customer=${customerLastname}`, df1c04eb2) };
    static onFetchCustomerPaySched(customerAccountNumber, closed, factor, df1c04eb2) { this.fetch(`/accountNumber=${customerAccountNumber}/closedAcc=${closed}/factor=${factor}`, df1c04eb2) };
    static onFetchCustomerAdjustment(customerAccountNumber, monthsPaid, closed, amortization, term, factor, lineNoOfAccelarationAdj, df1c04eb2) {
        
        this.fetch(`/accountAdjustments=${customerAccountNumber}/paidmonths=${monthsPaid}/trm=${term}/amr=${amortization}/closedAcc=${closed}/factor=${factor}/adj=${lineNoOfAccelarationAdj}`, df1c04eb2)
    };
    static onFetchMaturity(customerAccountNumber, overdueAmount, surchargeFactor, df1c04eb2) { this.fetch(`/customermaturity=${customerAccountNumber}/overdueAmount=${overdueAmount}/surchargeFactor=${surchargeFactor}`, df1c04eb2) };
    
    static onFetchPerLocationSliderContents(  df1c04eb2) { this.fetch(`/query/list=perlocationcontents`, df1c04eb2) };
}
