

import './Admin.scss';
import React, { useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { TbSlideshow } from "react-icons/tb";
import { Outlet, useLocation, useNavigate } from 'react-router-dom/dist';
import { IoIosImage } from "react-icons/io";
import { GiCardboardBoxClosed } from "react-icons/gi";
import { SiSuzuki } from "react-icons/si";
import { FaMotorcycle } from "react-icons/fa";
import { BsFillBuildingsFill } from "react-icons/bs";
import { GiCardRandom } from "react-icons/gi";
import { MdWork } from "react-icons/md";
import { RiQuestionnaireFill } from "react-icons/ri";
import { Label } from 'reactstrap';
import { Button, Modal } from 'react-bootstrap';
import { BiSolidCategoryAlt } from "react-icons/bi";
import { CONNECT, GET, POST } from '../../Reusables/FetchHandler';
import { RiLogoutCircleRLine } from "react-icons/ri";
import AdminContext from './AdminContext';
import { FaTags } from "react-icons/fa6";
import { LiaMapMarkedSolid } from "react-icons/lia";
import { PiSuitcaseSimpleThin } from "react-icons/pi";
import { Helmet } from 'react-helmet';

export default function Admin(props) {
    const {pathname} = useLocation();

    function removeSelected(selector) {
        const elements = document.querySelectorAll(selector);
        if (elements.length !== 0) { elements[0].removeAttribute("selected") }
    }

    function navigateToPath(e, path, title) {
        removeSelected("a.ps-menu-button[selected=\"true\"]");
        e.currentTarget.setAttribute("selected", "true");
        setTitle(title)
        navigate(path);
    };

    const [title, setTitle] = useState();
    const navigate = useNavigate();
    const [value, setValue] = useState();

     

    useEffect(() => {
        if (value) {
            const selectedMenu = document.querySelector(`.ps-menu-button[selected="true"]`)
            if (!selectedMenu) {
                const menu = document.querySelector(`.ps-menu-button[reference="${value}"]`)
                menu.click();
            }
        }
    }, [value])



    return (<AdminContext.Provider value={{ value, setValue }}>
        <Helmet>
            <link rel="icon" href={`/head_landing/favicon.ico`} />
            <link rel="apple-touch-icon" href={`/head_landing/logo192.png`} />
            <link rel="manifest" href={`/head_landing/manifest.json`} />
        </Helmet>
        <div id='pg-admin' className='pg-admin'    >
            <div id="pg-admin-content">
                <div className='pga-sidebar' >
                    <Sidebar  >
                        <Menu  >
                            <Label className='m-type-head'>VIAJERO MOTORSALES, INC.
                                <Label className='m-type-head-m'>WEBSITE CONTROL PANEL</Label></Label>



                            <Label className='m-type-group'>Slider Related</Label>
                            <MenuItem className="ad-menu" reference={"txn-contentbinding"} onClick={(e) => navigateToPath(e, "contentslider/binding", "CONTENT BINDING")}><TbSlideshow className='m-icn' />  Content Binding</MenuItem>
                            <MenuItem className="ad-menu" reference={"txn-contentslidermaintenance"} onClick={(e) => navigateToPath(e, "contentslider/maintenance", "CONTENT")}><IoIosImage className='m-icn' /> Content</MenuItem>


                            <Label className='m-type-group'>Product Related</Label>
                            <MenuItem className="ad-menu" reference={"txn-products"} onClick={(e) => navigateToPath(e, "products", "PRODUCTS")}><GiCardboardBoxClosed className='m-icn' /> Products</MenuItem>
                            <MenuItem className="ad-menu" reference={"txn-models"} onClick={(e) => navigateToPath(e, "productmodels", "MODELS")}><FaMotorcycle className='m-icn' /> Models</MenuItem>
                            <MenuItem className="ad-menu" reference={"txn-brand"} onClick={(e) => navigateToPath(e, "productbrands", "BRANDS")}><SiSuzuki className='m-icn' /> Brands</MenuItem>
                            <MenuItem className="ad-menu" reference={"txn-producttypes"} onClick={(e) => navigateToPath(e, "producttypes", "TYPES")}><GiCardRandom className='m-icn' />Types</MenuItem>

                            <Label className='m-type-group'>Company Related</Label>
                            <MenuItem className="ad-menu" reference={"txn-branches"} onClick={(e) => navigateToPath(e, "branches", "BRANCHES")}> <BsFillBuildingsFill className='m-icn' /> Branches </MenuItem>
                            <MenuItem className="ad-menu" reference={"txn-business"} onClick={(e) => navigateToPath(e, "business", "BUSINESS")}> <PiSuitcaseSimpleThin className='m-icn' /> Business </MenuItem>
                            <MenuItem className="ad-menu" reference={"txn-businessArea"} onClick={(e) => navigateToPath(e, "businessarea", "BUSINESS AREA")}> <LiaMapMarkedSolid className='m-icn' /> Business Area </MenuItem>
                            <MenuItem className="ad-menu" reference={"txn-businesstag"} onClick={(e) => navigateToPath(e, "businesstag", "BUSINESS TAG")}> <FaTags className='m-icn' /> Business Tag </MenuItem>

                            <Label className='m-type-group'>Costumer Care</Label>
                            <MenuItem className="ad-menu" reference={"txn-faq"} onClick={(e) => navigateToPath(e, "faq", "FREQUENTLY ASKED QUESTION")}><RiQuestionnaireFill className='m-icn' /> FAQ </MenuItem>
                            <MenuItem className="ad-menu" reference={"txn-faqcategory"} onClick={(e) => navigateToPath(e, "faqcategory", "FREQUENTLY ASKED QUESTION - CATEGORY")}><BiSolidCategoryAlt className='m-icn' /> FAQ Category </MenuItem>

                        </Menu>
                    </Sidebar>
                </div>
                <div className='pga-md-view'>
                    <div className='pga-account' >
                        <Label>{title}</Label>

                        <Button onClick={(e) => {

                            POST('/api/v1/logout', undefined,
                                async (failedResponse) => {

                                },
                                async (successResponse) => {
                                    navigate("/controlpanel")
                                },
                                async (errorResponse) => {

                                }
                            );

                        }}><RiLogoutCircleRLine /> <span>LOG OUT</span></Button>
                    </div>
                    <div className='pga-content' >
                        <div id="contentoutlet">
                            <><Outlet context={{ ...props }} /></>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </AdminContext.Provider>


    );

}
