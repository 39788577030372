import React from 'react';
import './LoadingCard.scss'; // Import CSS for styling

const LoadingCard = () => {
  return (
    <div className="loading-card">
      <div className="thumbnail-loading"></div>
    
     {/*  <div className="info-loading">
        <div className="title-loading"></div>
        <div className="details-loading"></div>
      </div> */}
    </div>
  );
};

export default LoadingCard;
