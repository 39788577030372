import React, { memo } from 'react';
import './ProductManualSpec.scss';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { TbX } from "react-icons/tb";

const ProductManualSpec = ({ data, componentId, onRemove, onDataChanged }) => {
    return <div className='spec-info-group' key={componentId}>
        <div className='imgipp'>
            <div className="piigdta">
                <InputGroup  >
                    <Form.Control className={"spec-input-group"} type="text" defaultValue={data.group ? data.group : data.group = "OTHERS"} onChange={(e) => { data.group = e.currentTarget.value; onDataChanged(); }} />
                    <Form.Control className={"spec-input-tag"} type="text" defaultValue={data.key} onChange={(e) => { data.key = e.currentTarget.value; onDataChanged(); }} />
                    <Form.Control className={"spec-input-desc"} type="text" defaultValue={data.value} onChange={(e) => { data.value = e.currentTarget.value; onDataChanged(); }} />
                    <Button className='piigzkclsd ' variant='danger' onClick={onRemove}><TbX /></Button>
                </InputGroup>
            </div>
        </div>
    </div>
}

export default memo(ProductManualSpec);
