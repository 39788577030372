import React from 'react';
import './NoData.scss';
import { FaBoxOpen } from "react-icons/fa6";
import { Button } from 'react-bootstrap';

const NoData = (calltoaction, calltoactionText) => {
    const button = calltoaction === undefined ? <></> : <Button style={{ width: "95%", borderRadius: "24px" }} onClick={calltoaction}>{calltoactionText}</Button>;
    return (<div className='no-data' key={"no-data"}>
        <div className="no-data-container">
            <div className="no-data-content">
                <FaBoxOpen />
                <p>NO DATA AVAILABLE</p>
                {button}
            </div>
        </div>
    </div>
    );
};
export default NoData;
