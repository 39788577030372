

export default function CodeShorter( string, maxLength) {
        if (string.length <= maxLength) {
            return string; // Return the original string if it's shorter than the maxLength
        }
        const ellipsis = '...';
        const partLength = (maxLength - ellipsis.length) / 2;
        const start = string.substring(0, partLength);
        const end = string.substring(string.length - partLength);

        return start + ellipsis + end;
 
}