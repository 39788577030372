import React from 'react';
import './Pagination.scss';
import { TbArrowLeft, TbArrowRight } from "react-icons/tb";
import { InputGroup } from 'react-bootstrap';

const Pagination = ({ maxButtons, currentPage, totalPages, onSetPageNo, onPageChange }) => {

  const getPageNumbers = () => {

    let startPage, endPage;

    if (totalPages <= maxButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const maxPagesBeforeCurrentPage = Math.floor(maxButtons / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxButtons / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        startPage = totalPages - maxButtons + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }
    return [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
  };

  const pages = getPageNumbers();

  if (totalPages === 0) {
  } else {
    return (
      <nav className="pagination">
 
          <InputGroup>
            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
              <button className="page-link" onClick={() => { onSetPageNo(currentPage - 1); onPageChange(currentPage - 1) }} disabled={currentPage === 1}>
                <TbArrowLeft />
              </button>
            </li>
            {pages.map(page => (
              <li key={page} className={`page-item ${currentPage === page && 'active'}`}>
                <button className="page-link" onClick={() => { onSetPageNo(page); onPageChange(page) }}>
                  {page}
                </button>
              </li>
            ))}
            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
              <button className="page-link" onClick={() => { onSetPageNo(currentPage + 1); onPageChange(currentPage + 1) }} disabled={currentPage === totalPages}>
                <TbArrowRight />
              </button>
            </li>
          </InputGroup>
     
      </nav>
    );
  }
};

export default Pagination;
