import React, { useState, useEffect } from 'react';

import './ProductSpecs.scss';
import { useParams, useOutletContext, useLocation } from 'react-router-dom/dist';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import { Badge } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import Slider from 'react-slick';

export default function ProductSpecs(props) {

    const { pathname } = useLocation()
    const { addToCompare, width, channel, _$, $, toast } = useOutletContext();
    const [mobileView, setMobileView] = useState(width <= 767);
    const { type, productCode } = useParams();
    const [product, setProduct] = useState();
    const [variations, setVariations] = useState();
    const [introduction, setIntroduction] = useState();
    const [specs, setSpecs] = useState();
    const [highlights, setHighlights] = useState();
    const [selectedVariation, setSelectedVariation] = useState();
    const [logo, setLogo] = useState();

    document.title = `${channel === "VMI" ? "Viajero Motorsales Inc." : "Jicjam Cycle Inc."} - Product ${productCode}`;

    useEffect(() => { reset() }, [pathname])
    useEffect(() => { start() }, [])
    useEffect(() => { start() }, [productCode])
    useEffect(() => { if (product) setLogo(getProductLogo()) }, [product])
    useEffect(() => { if (product) getVariation() }, [product])
    useEffect(() => { if (product) setIntroduction(getIntroduction()) }, [variations])
    useEffect(() => { if (product) setSpecs(getSpecs()) }, [highlights])
    useEffect(() => { if (product) setHighlights(getHighlights()) }, [introduction])
    useEffect(() => { setMobileView(width <= 767) }, [width])
    useEffect(() => { if (variations) document.getElementById(variations[0].variationId).click() }, [variations])

    function getProductLogo() {
        return <LazyLoadImage className='logo' src={'/public/mgrproduct/getimage?'
            + new URLSearchParams({ productId: productCode, imageName: product.imageUUID, type: "m", quality: 70 }).toString()} />
    }

    function start() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getProduct()
    }

    function reset() {
        setProduct(undefined)
        setVariations(undefined)
        setIntroduction(undefined)
        setSpecs(undefined)
        setHighlights(undefined)
        setSelectedVariation(undefined)
        setLogo(undefined)
    }

    function getProduct() {
        $(`/public/productinfo/basic`, { productId: productCode },
            async (promise_fail) => { toast("message", "type") },
            async (any_error) => { toast("message", "type") },
            async (promise_success) => { setProduct(await promise_success.json()) }
        )
    }

    function getVariation() {
        $(`/public/productinfo/variations`, { productId: productCode },
            async (promise_fail) => { toast("message", "type") },
            async (any_error) => { toast("message", "type") },
            async (promise_success) => { setVariations(await promise_success.json()) }
        )
    }

    function getIntroduction() {
        return _$('/public/productinfo/introduction', { productId: productCode }, (fail) => { },
            (introduction) => {
                return <div className='inf-introduction'>
                    <span className="tagLine">{introduction.tagLine}</span>
                    <span className='description preserve'>{introduction.texts}</span>
                </div>
            })
    }

    function getSpecs() {
        return <LazyLoadComponent>
            <div className='inf-specs' id='_spec'>
                <label className='mb-2 mt-2 tg-tt'>SPECIFICATION</label>
                {
                    _$('/public/productinfo/specs', { productId: productCode }, (fail) => { },
                        (specs) => {
                            const res = [];
                            let currentGroup = "";
                            for (const spec of specs) {
                                if (spec.group !== currentGroup) {
                                    currentGroup = spec.group;
                                    res.push(<div className='spec-grp mt-3'>{spec.group}</div>)
                                }
                                if(spec.key!=='REGISTERED DISPLACEMENT'){
                                res.push(<div className='spec-dta'>  <div className='spec-key'>{spec.key}</div><div className='spec-value'>{spec.value}</div></div>)
                            }  }
                            return res;
                        })
                }</div>
        </LazyLoadComponent>
    }

    function getHighlights() {

        return <LazyLoadComponent>
            <div className='inf-highlights' id="_highlights">
                <label className='mb-2 mt-2 tg-tt'>PRODUCT HIGHLIGHTS</label>
                <div className='dta-highlights' mobile={mobileView ? "true" : "false"}>
                    {_$('/public/productinfo/highlights', { productId: productCode }, (fail) => { },
                        (features) => {
                            return features.map((feature) => {
                                return <div className='hl-group' >
                                    <div className='hl-group-data' >
                                        <LazyLoadImage className='logo' src={'/public/mgrproduct/getimage?'
                                            + new URLSearchParams({
                                                productId: productCode,
                                                imageName: feature.imageUUID,
                                                type: "f",
                                                quality: 70
                                            }).toString()} />
                                        <div className='ft-inf'>
                                            <div className='ft-name'>{feature.featureName}</div>
                                            <div className='ft-desc'>{feature.featureDescription}</div>
                                        </div>
                                    </div>
                                </div>
                            })
                        })}</div>
            </div>
        </LazyLoadComponent>
    }

    function renderVariationsSelector() {
        if (variations) {
            return <div className='inf-variations'>
                <div className='content'>
                    {
                        variations.map((variation) => {
                            return <button id={variation.variationId} className="var-select"
                                onClick={(e) => {
                                    const param = { productId: productCode, imageName: variation.imageUUID, type: "v", quality: 70 }
                                    setSelectedVariation("/public/mgrproduct/getimage?" + new URLSearchParams(param).toString());
                                    const el = document.querySelectorAll('.selected')[0];
                                    if (el) { el.classList.remove("selected") }
                                    e.currentTarget.classList.add("selected")
                                }}
                            >{variation.variationName}</button>
                        })}
                </div>
            </div>
        }
    }

    return <div id="product-information"
        style={channel === 'VMI' ? { "--color": "58, 123, 189", "--xw": `${width > 1200 ? 1200 : width}px` } : { "--color": "194, 2, 2", "--xw": `${width > 1200 ? 1200 : width}px` }}

    >
        <div className='inf-variations'>
            {logo}
            <div className='variation-view' motorcycle={type === "powerproducts" ? "false" : "true"} mobile={mobileView ? "true" : "false"}>
                {selectedVariation ? <LazyLoadImage src={selectedVariation} /> : <></>}
            </div>
            <div className='variation-selector'>
                {renderVariationsSelector()}
            </div>
        </div>
        {product ? <div className='inf-product'>
            <div className='inf-f1'>
                <span>{product.brandName + " " + product.modelName + " " + product.additionalModelName}</span>
                <span>{product.modelCode + " (" + product.year + ") "} </span>
            </div>
            <div className='inf-badge'>
                {product.Types.map((text) => {
                    return <Badge>{text} </Badge>
                })}
            </div>
            <div className='inf-f2'>
                {highlights ? <button onClick={(e) => { document.getElementById('_highlights').scrollIntoView({ behavior: 'smooth' }); }}>Highlights</button> : <></>}
                {specs ? <button onClick={(e) => { document.getElementById('_spec').scrollIntoView({ behavior: 'smooth' }); }}>Specifications</button> : <></>}
                <button onClick={(e) => { addToCompare(product.productId, type === "powerproducts") }}>Compare</button>
            </div>
        </div> : <></>}
        {introduction}
        {highlights}
        {specs}
    </div>

}



