import Toast from "../Component/AdminPages/Toast";

export class ValueValidator {

    #datas = [];
    #isPassed = true;
    #setToast;

    constructor(setToast) {
        this.#setToast = setToast;
    }

    add(oData, cFieldName) {
        this.#datas.push([oData, cFieldName])
        return this;
    }
 

    validate() {
     
        if (this.#datas.length === 0) { return false; }
        const toastMessage = [];
        this.#datas.forEach(element => {
            const oData = element[0];
            const cFieldName = element[1]; 
            var lisPassed = true;
            if (oData) {
                if (typeof oData === 'string') { if (oData.trim().length === 0) { this.#isPassed = lisPassed = false; } }
                else if (oData instanceof Map) { if (oData.size === 0) { this.#isPassed = lisPassed = false; } }
                else if (typeof oData === 'object') {
                    if (oData instanceof String) { if (oData.trim().length === 0) { this.#isPassed = lisPassed = false; } }
                    else if (oData.length === 0) { this.#isPassed = lisPassed = false; }
                }
            } else { this.#isPassed = lisPassed = false; }
            if (!lisPassed) { toastMessage.push(`Check value for "${cFieldName}"`) }
        });

        if (toastMessage.length !== 0) {
            this.#setToast(
                this.#createToast(<div style={{ display: "flex", flexDirection: "column" }}>{toastMessage.map((message) => { return <div>{message}</div> })}</div>))
        }
        return this;
    }

    get() {
        if (this.#isPassed === undefined) { this.#setToast(this.#createToast(<div>{`unvalidated`}</div>)) }
        return this.#isPassed;
    }


    #createToast(message) {
        return <Toast
            variant="danger"
            onClose={() => { this.#setToast(undefined) }}
            headingText={`Input Validation`}
            descriptionText={message} />
    }



}