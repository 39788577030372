import { useEffect, useState } from "react";


export default function SuspenseBody({  wrappedPromiseData , onCreateBody }) {
 
    const data = wrappedPromiseData.read();
    const [body, setBody] = useState(undefined);

    useEffect(() => { setBody(onCreateBody(data)) }, [data])

    return (<>{ body }</>);
    
}