import React, { useState, useEffect, useRef, Suspense, useMemo } from 'react';
import { Link, useParams, useLocation, useOutletContext, useSearchParams, useNavigate } from 'react-router-dom/dist';
import './ProductList.scss';
import Pagination from "../../../Reusables/Pagination.jsx";
import { FaFilter } from "react-icons/fa";
import Fetcher from '../../Helper//Fetcher';

import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import { IoIosColorPalette } from "react-icons/io";
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import UpdatingCard from '../../Helper/UpdatingCard.jsx';
import SuspenseBody from '../../Helper/SuspenseBody.jsx';
import { awaitedQueryData } from '../../Helper/PromiseWrapper.ts';


export default function ProductList({ poweproduct }) {

    const { addToCompare, width, channel, _$$ } = useOutletContext();
    const location = useLocation();
    const query = useMemo(() => new URLSearchParams(location.search), [location.key]);
    const [mobileView, setMobileView] = useState(width <= 1025);
    const [limit] = useState(mobileView ? 10 : 12);
    const [maxButtons] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const [products, setProducts] = useState();
    const [filterPanel, setFilterPanel] = useState();
    const [canvas, setCanvas] = useState();
    const [nonCanvas, setNonCanvas] = useState();
    const filterRef = useRef(new Map());

    document.title = `${channel === "VMI" ? "Viajero Motorsales Inc." : "Jicjam Cycle Inc."} - ${poweproduct ? "Power Products" : "Motorcycles"}`;

    useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); distributeParams(); setFilterPanel(createFilterPanel()); }, [])
    useEffect(() => { setProducts(searchProduct("")); }, [location.search, location.key]);
    useEffect(() => { setMobileView(width <= 1025) }, [width])
    useEffect(() => { if (mobileView) { setNonCanvas(undefined) } else { setNonCanvas(filterPanel) }; if (!products) { setProducts(searchProduct("")); } }, [filterPanel])
    useEffect(() => { if (mobileView) { setNonCanvas(undefined) } else { setNonCanvas(filterPanel) } }, [mobileView])
    useEffect(() => { updateURL() }, [currentPage])

    function searchProduct(search) {
        if (query.size === 0) { updateURL() }
        return _$$('/public/products', { businessId: channel, powerproduct: poweproduct, limit: limit, search: search, ...query.entries }, query.toString(), (fail) => { },
            (responsePaginatedList) => {
                setTotalPages(Math.ceil(responsePaginatedList.totalCount / limit))
                return <div className='p-items-pane'>{
                    responsePaginatedList.response.map(
                        (product) => {
                            return <div key={product.productId} className='p-item-card'  >
                                <div className="p-item-img-wrap" motorcycle={poweproduct ? "false" : "true"}>
                                    <LazyLoadImage className='p-item-img'
                                        src={"/public/mgrproduct/getimage?"
                                            + new URLSearchParams({
                                                productId: product.productId,
                                                imageName: product.variationImageUUID,
                                                type: "v",
                                                quality: 70
                                            }).toString()} />
                                </div>
                                <div className='p-item-desc'>
                                    <span className='name'>{product.brandName.toUpperCase() + " " + product.modelName.toUpperCase() + " " + product.additionalModelName.toUpperCase()}</span>
                                    <span className='code'>{product.modelCode}</span>
                                    <span className='year mb-2'>{product.year}</span>
                                    <span className='tagline'>{product.tagLine}</span>
                                </div>
                                <div className='p-item-buttons' mobile={mobileView ? "true" : "false"} >
                                    <button channel={channel} onClick={(e) => navigate(`${product.productId}`)}>Explore</button>
                                    <button channel={channel} onClick={(e) => addToCompare(product.productId, poweproduct)}>Compare</button>
                                </div>
                            </div>
                        }
                    )
                }</div>
            }
        )
    }



    function createFilterPanel() {
        return <div className={'product-list-filter-wrapper' + (mobileView ? `-offcanvas` : '')}    >
            <form   >
                <LazyLoadComponent>
                    <Suspense fallback={<UpdatingCard />}>
                        <SuspenseBody
                            wrappedPromiseData={awaitedQueryData('/public/binded/brands?' +
                                new URLSearchParams({
                                    businessId: channel,
                                    powerproduct: poweproduct
                                }).toString())}
                            onCreateBody={(result) => {
                                if (result.fail) {
                                    // error toast
                                } else {
                                    const productBrands = result;
                                    if (productBrands.length > 1) {
                                        return <div className='mb-3'>
                                            <label className="fl-lbl" channel={channel}>BRANDS</label>
                                            {productBrands.map(brand => {
                                                return <Form.Check
                                                    type="switch"
                                                    id={brand.brandId}
                                                    label={brand.name}
                                                    name={"brand"}
                                                    value={brand}
                                                    defaultChecked={filterRef.current.get(brand.brandId)}
                                                    className='filterBox'
                                                    onChange={e => { filterRef.current.set(brand.brandId, e.currentTarget.checked); updateURL() }}
                                                />
                                            })}
                                        </div>
                                    }
                                }
                            }} />
                    </Suspense>
                </LazyLoadComponent>
                <LazyLoadComponent>
                    <Suspense fallback={<UpdatingCard />}>
                        <SuspenseBody
                            wrappedPromiseData={awaitedQueryData('/public/binded/type?' +
                                new URLSearchParams({
                                    businessId: channel,
                                    powerproduct: poweproduct
                                }).toString())}
                            onCreateBody={(result) => {
                                if (result.fail) {
                                    // error toast
                                } else {
                                    const productTypes = result;

                                    return <div className='mb-3'>
                                        <label className="fl-lbl" channel={channel}>TYPES</label>
                                        {productTypes.map(productType => {
                                            return <Form.Check
                                                type="switch"
                                                id={productType.typeId}
                                                label={productType.name}
                                                name={"type"}
                                                value={productType}
                                                defaultChecked={filterRef.current.get(productType.typeId)}
                                                onChange={e => { filterRef.current.set(productType.typeId, e.currentTarget.checked); updateURL() }}
                                                className='filterBox'
                                            />
                                        })}

                                    </div>


                                }
                            }} />
                    </Suspense>
                </LazyLoadComponent>


            </form>
        </div>
    }

    function updateURL() {
        let forRemove = [];
        for (let [key, value] of query.entries()) { forRemove.push([key, value]) }
        for (let [key, value] of forRemove) { query.delete(key, value) }
        for (let checkbox of document.querySelectorAll(".form-check-input")) { if (checkbox.checked) { query.append(checkbox.name, checkbox.id) } }
        query.append("page", currentPage)
        navigate("?" + query.toString())
    }

    function distributeParams() {
        query.forEach((value, key, parent) => {
            if (key === "limit") {
                setCurrentPage(value)
            } else {
                filterRef.current.set(value, true);
            }
        })
    }



    function createCanvas() {
        return <Offcanvas

            show={true}
            onHide={e => setCanvas(undefined)}
            onShow={e => { }} >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>FILTERS</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body >
                <div className="off-cvns">
                    {filterPanel}
                </div>

            </Offcanvas.Body>
        </Offcanvas>

    }
    return <div id="product-list" className='center-page'   >

        <div className='product-list-filter-div'  >
            {nonCanvas}
            {canvas}
        </div>

        <div className='information'>
            <button className='filter-button' onShow={e => setNonCanvas(undefined)} onClick={(e) => setCanvas(createCanvas())}>
                <FaFilter /> Filter
            </button>

            <div>
                {products}
            </div>

            <div className='paginationmd' channel={channel}>
                <Pagination maxButtons={maxButtons} currentPage={currentPage} totalPages={totalPages} onSetPageNo={setCurrentPage} onPageChange={() => { }} />
            </div>
        </div>

    </div>


}
