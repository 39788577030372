import React, { useState } from 'react';
import "./Services.scss";
import { useEffect } from 'react';
import { FaCheckCircle } from "react-icons/fa";
import { Button } from 'reactstrap';
import { Card, CardTitle, CardText } from 'reactstrap';


export default function Services(props) {


    const viajeroServicesWithImage = [
        { image: 'SERVICE1.jpg', name: 'MOTORCYCLE TUNE UP', type: 'Maintenance' },
        { image: 'SERVICE2.jpg', name: 'MOTORCYCLE CHANGE OIL', type: 'Maintenance' },
        { image: 'TOPOVERHAULING.jpg', name: 'CLUTCH REPAIR AND TOP OVERHAULING', type: 'Repair' },
        { image: 'SERVICE4.jpg', name: 'CRANKSHAFT ALIGNMENT', type: 'Repair' },
        { image: 'SERVICE5.jpg', name: 'TELESCOPIC ALIGNMENT', type: 'Repair' },
        { image: 'SERVICE6.jpg', name: 'CYLINDER BLOCK REBORING', type: 'Upgrade' },
        { image: 'SERVICE7.jpg', name: 'RIVETING CLUTCH HOUSING', type: 'Upgrade' }
    ];

    const viajeroServices = [
        'F.I. Injector Cleaning (Kawasaki, Suzuki, Honda, Yamaha)',
        'F.I. Re-Setting',
        'Battery Analyzer',
        'Suzuki Diagnostic System (SDS) - II',
        'Continuously Variable Transmission Cleaning (CVT)'
    ];

    const jicjamServices = [
        'F.I. Re-Setting',
        'Smart Key Activation',
        'Battery Analyzer',
        'Inspect Fuel Line',
        'F.I. Cleaning',
        'MCS'
    ];

    const jicjamServicesWithImage = [
        { image: 'IMG_20191023_102609-scaled.jpg', name: 'MOTORCYCLE TUNE UP', type: 'Maintenance' },
        { image: 'IMG_20191023_102630-scaled.jpg', name: 'MOTORCYCLE CHANGE OIL', type: 'Maintenance' },
        { image: 'IMG_20191023_102647-scaled.jpg', name: 'CLUTCH REPAIR AND TOP OVERHAULING', type: 'Repair' },
        { image: 'IMG_20191023_102718-scaled.jpg', name: 'CRANKSHAFT ALIGNMENT', type: 'Repair' },
        { image: 'IMG_20191025_105432-scaled.jpg', name: 'TELESCOPIC ALIGNMENT', type: 'Repair' },
        { image: 'IMG_20191025_105544-scaled.jpg', name: 'CYLINDER BLOCK REBORING', type: 'Upgrade' },
        { image: 'IMG_20191025_105636_1-scaled.jpg', name: 'RIVETING CLUTCH HOUSING', type: 'Upgrade' }
    ];



    const createContent = () => {
        const values = [];

        for (const value of viajeroServicesWithImage) {
            const image = `/images/services/${value.image}`;
            values.push(
                <>
                    <div className='services-data' >
                        <span className='services-text'>{value.name}</span>
                        <span className='services-text-sm'>{value.type}</span>
                        <img className='services-img' src={image} />
                    </div>
                </ >
            );
        }
        return values;

    };

    const createOtherServicesContent = () => {
        const values = [];
        for (const value of viajeroServices) {
            values.push(<div className='flex f-row'><div className='facheck-a'>
                <FaCheckCircle /></div>  <span className='services-text-head-mini'> {value}</span></div>)

        }
        return values;
    };

    const otherOfferedServices = () => {
        const servicesOffered = [
            ['Third-Party Liability (TPL) Insurance', 'Motorcycle insurance is highly recommended, apply for an insurance now.', 'Viajero Insurance Corporation'],
            ['Motorcycle Financing', 'Motorcycle insurance is highly recommended, apply for an insurance now.', 'South Point Credit And Finance Corporation'],
            ['Water Refilling station', 'Motorcycle insurance is highly recommended, apply for an insurance now.', 'Water World Marketing'],
            ['System and Software Development', 'Custom and quality software for your business. Your desired software is our pleasure.', 'Codexus Solutions, Inc']];
        const res = [];

        servicesOffered.forEach((element) => {
            const arr = element;


            res.push(<Card body className='div-card'>
                <CardTitle className='ftr-x'>{arr[0].toUpperCase()}</CardTitle>
                <hr></hr>
                <CardText className='frt-0'>{arr[1]}</CardText>
                <CardText className='frt-1'>Offered By {arr[2]}</CardText>
                <div className='div-card-body'><Button color='primary' className='btn-a'>Learn More</Button></div>

            </Card >);
        });
        return res;
    }
    return <div className='flex f-column'>
        <div className='services-div-head'><span className='services-text-head'>We got your back for your motorcycle needs</span></div>
        <div className='services-div-head-mini'>
            <div className='flex f-row'><div className='facheck-a'><FaCheckCircle /></div>  <span className='services-text-head-mini'> Maintenance</span></div>
            <div className='flex f-row'><div className='facheck-a'><FaCheckCircle /></div>  <span className='services-text-head-mini'> Repair</span></div>
            <div className='flex f-row'><div className='facheck-a'> <FaCheckCircle /></div> <span className='services-text-head-mini'> Upgrade</span></div>
            <div className='flex f-row'><div className='facheck-a'> <FaCheckCircle /></div> <span className='services-text-head-mini'> Check Up</span></div>
        </div>
        <div className='services-div-common'>
            <span className='services-text-common'>What are common services we provide?</span>
            <span className='services-text-common-sm'>Tune Up. Change Oil. Overhaul And Many More</span>
        </div>
        <div className='services-div-main'>
            <div className='services-div-data'  >{createContent()}</div></div>
        <div className='services-div-others-mini'>
            <div className='div-1200 services-div-common'>
                <span className='services-text-common'>What other services we provide?</span>
            </div>
            <div className='div-1200 services-o-div '>
                <div className='services-o-text'>
                    {createOtherServicesContent()}
                </div>
            </div>
        </div>
        <div className='services-div-common-bot'>
            <span className='services-text-common-bot'>Schedule your service now or visit us to the nearest shop near you</span>
            <Button color='primary' className='services-button-common-bot'>Locate Stores</Button>
        </div>
        <section className='md-landing div-full '>
            <div className='div-full-content'>
                <div className='div-inner  flex f-column'>
                    <div className='div-inner-header-text'>Other Services Offered By Our Sister's Company</div>
                    <div className='md-offered-services'>
                        {otherOfferedServices()}
                    </div>
                </div>
            </div>
        </section>
    </div >
}