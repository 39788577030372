export async function POST(url, value, onFailed, onSuccess) {
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: value,
        });
        if (!response.ok) { onFailed(response) }
        else { onSuccess(response) }
    } catch (error) {
        onFailed(error)
    }
}


export async function GET(url, onFailed, onSuccess, onError) {
    try {
        const response = await fetch(url, { method: 'GET' });
        if (!response.ok) { onFailed(response) }
        else { onSuccess(response) }
    } catch (error) {
        onError(error)
    }
} 

export async function CONNECT( onFailed, onSuccess, onError) {
    try {
        const response = await fetch("/api/mgr/claim", { method: 'GET' });
        if (!response.ok) { onFailed(response) }
        else { onSuccess(response) }
    } catch (error) {
        onError(error)
    }
} 
 