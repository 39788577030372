import { useEffect, useMemo, useRef, useState } from "react";
import Fetcher from '../../Helper/Fetcher';
import { Alert, Button, ButtonGroup, Card, Form } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { FaCheckCircle } from "react-icons/fa";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { FaSearch } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { FaRegCircle } from "react-icons/fa";
import { LuInfo } from "react-icons/lu";
import './AccountStatus.scss';
import { IoExit } from "react-icons/io5";
import { RiRefreshFill } from "react-icons/ri";
import { json, useNavigate, useOutletContext } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

export default function AccountStatus(props) {

    const [validated, setValidated] = useState(false);
    const [totalPaidPercentage, setTotalPaidPercentage] = useState(0);
    const [totalAmortizationBal, setTotalAmortizationBal] = useState(0);
    const [showScheduleInfo, setShowScheduleInfo] = useState(false);
    const [matured, setMatured] = useState(false);
    const [maturity, setMaturity] = useState({ daysMatured: 0, totalMaturityBalance: 0.0 });
    const [scheduleItem, setScheduleItem] = useState(null);
    const [unpaidSchedules, setUnpaidSchedules] = useState([]);
    const dateFormat = { year: 'numeric', month: 'short', day: 'numeric' };
    const numberFormat = { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 };
    const handleCloseScheduleInfo = () => setShowScheduleInfo(false);
    const { channel, _$, $, toast } = useOutletContext();
    const navigate = useNavigate();
    const [customer, setCustomer] = useState();
    const [paySchedules, setPaySchedules] = useState();
 
    const [paySchedView, setPaySchedView] = useState();
    const [adjustmentView, setAdjustmentView] = useState();
    const [paidPercentage, setPaidPercentage] = useState();


    document.title = `${channel === "VMI" ? "Viajero Motorsales Inc." : "Jicjam Cycle Inc."} - Customer Account Status`;

    useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }, []);
    useEffect(() => { if (customer) {  getPaymentSchedules() } }, [customer])
    useEffect(() => { if (paySchedules) { calculatePaidPercentage(); getAdjustments() } }, [paySchedules])
    useEffect(() => { if (unpaidSchedules.length !== 0) { calculateOverDueMaturity(); } }, [unpaidSchedules]);
    useEffect(() => { renderTotalPaidPercentage(); }, [totalPaidPercentage]);
    useEffect(() => {
        if (localStorage.getItem('acc-form-data')) {
            const ref = JSON.parse(localStorage.getItem('acc-form-data'))
            $(`/public/customer`, ref,
                async (fail) => { toast("NO ACCOUNT", "Account not found") },
                async (onError) => { toast("SERVER ERROR", "") },
                async (success) => {
                    setCustomer(await success.json())
                }
            )
        }
    }, []);

    function renderTotalPaidPercentage() {
        setPaidPercentage(<div className="pay-sched-m2" >
            <Card.Title>YOU ARE</Card.Title>
            <div role="progressbar"
                aria-valuenow={"" + totalPaidPercentage}
                aria-valuemin="0" aria-valuemax="100"
                style={{ '--value': totalPaidPercentage }}></div>
            <Card.Title className="mt-2">PAID</Card.Title>
        </div>)
    }


    function calculatePaidPercentage() {
        if (paySchedules.schedulesWithPaymentInfos.length !== 0) {
            let totalPaid = 0, totalLength = paySchedules.schedulesWithPaymentInfos.length, notPaid = [];
            for (let item of paySchedules.schedulesWithPaymentInfos) {
                if (item.prinicipalBalance === 1) {
                    totalPaid++;
                } else {
                    notPaid.push(item);
                }
            }
            setTotalPaidPercentage(Math.floor((totalPaid / totalLength) * 100))
            setUnpaidSchedules(notPaid)

        }
    }

    function calculateOverDueMaturity() {
        let maturedAcc = false;
        let totalAmortBalance = 0;
        const currentDate = new Date(customer.now);

        if (unpaidSchedules.length !== 0) {
            const lastDueSched = unpaidSchedules[unpaidSchedules.length - 1];
            const lastDueDate = new Date(lastDueSched.dueDate);

            for (let item of unpaidSchedules) {
                totalAmortBalance += item.amortizationbalance;
            }

            if (lastDueDate.getTime() < currentDate.getTime()) {
                maturedAcc = true;

                Fetcher.onFetchMaturity(
                    customer.accountNumner,
                    totalAmortBalance,
                    customer.factor,
                    (result) => setMaturity(result));
            }
        }

        setTotalAmortizationBal(totalAmortBalance);
        setMatured(maturedAcc)

    }


    function createControl(controlId, label, placeholder, invalidText) {
        return <Form.Group className="mb-3" as={Col} >
            <FloatingLabel controlId={controlId} label={label} className="mb-3"            >
                <Form.Control required type="text" placeholder={placeholder} pattern=".{2,}" />
                <Form.Control.Feedback id={"feed" + controlId} type="invalid">{invalidText}</Form.Control.Feedback>
            </FloatingLabel>
        </Form.Group>
    }


    function onClose() {
        localStorage.removeItem('acc-form-data');
        setCustomer()
        navigate(".")
    }

    function onReload() {
        window.location.reload();
    }


 

    function noToZero(number) {
        return number === 0 ? "" : new Number(number).toLocaleString('en-US', numberFormat)
    }

    function renderScheduleInfo() {
        if (scheduleItem !== null) {
            return <>
                <Table className="sched-inf-table" >
                    <tbody>
                        <tr  >
                            <td>Status</td>
                            <td >  <strong>{scheduleItem.prinicipalBalance === 1 ? "PAID" : "UNPAID"} </strong>    </td>
                        </tr>
                        <tr  >
                            <td>Due Date</td>
                            <td>{new Date(scheduleItem.dueDate).toLocaleDateString("en-US", dateFormat)}</td>
                        </tr>
                        <tr  >
                            <td>Recommended Pay Date</td>
                            <td> {new Date(scheduleItem.recommendedPayDate).toLocaleDateString("en-US", dateFormat)} </td>
                        </tr>
                        <tr  >
                            <td>Amortization</td>
                            <td>{noToZero(scheduleItem.amortization)}</td>
                        </tr>
                        <tr >
                            <td style={{ paddingLeft: "16px" }}>Paid Amortization</td>
                            <td>{noToZero(scheduleItem.paidamortization)}</td>
                        </tr>
                        <tr  >
                            <td style={{ paddingLeft: "16px" }}>Amortization Balance</td>
                            <td>{noToZero(scheduleItem.amortizationbalance)}</td>
                        </tr>
                        <tr  >
                            <td style={{ paddingLeft: "16px" }}>Paid Surcharge</td>
                            <td>{noToZero(scheduleItem.paidsurcharge)}</td>
                        </tr>
                        <tr style={{ border: '1px solid transparent' }}>
                            <td style={{ paddingLeft: "16px" }}>Surcharge Balance</td>
                            <td>{noToZero(scheduleItem.surchargebalance)}</td>
                        </tr>
                        <tr hideNoValue={scheduleItem.prinicipalBalance === 1 ? "true" : "false"}>
                            <td style={{ border: 'none', backgroundColor: '#009751', color: '#fff', borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px', padding: '12px' }}>
                                Amount Due
                            </td>
                            <td style={{ textAlign: 'right', border: 'none', backgroundColor: '#009751', color: '#fff', borderTopRightRadius: '12px', borderBottomRightRadius: '12px', padding: '12px' }}>
                                {noToZero((scheduleItem.surchargebalance + scheduleItem.amortizationbalance))}
                            </td>

                        </tr>
                    </tbody>
                </Table>
            </>
        }
    }

    function getPaymentSchedules() {
        setPaySchedView(_$(`/public/customer/paymentschedules`,
            { customerAccountNumber: customer.accountNumner, closed: customer.closed, factor: customer.factor },
            (fail) => { <>q</> },
            (payScheds) => {
                setPaySchedules(payScheds)

                if (payScheds !== null) {
                    if (payScheds.length !== 0) {
                        const tableRows = [];
                        let month = 1;
                        for (let item of payScheds.schedulesWithPaymentInfos) {
                            tableRows.push(
                                <tr className="pay-table-row">
                                    <td >  <span>{item.prinicipalBalance === 1 ? <FaCheckCircle /> : <FaRegCircle />} </span> <span> {month}</span>  </td>
                                    <td> {new Date(item.recommendedPayDate).toLocaleDateString("en-US", dateFormat)} </td>
                                    <td>{noToZero(item.surchargebalance + item.amortizationbalance)}</td>
                                    <td><button role="info-button" onClick={(e) => { setScheduleItem(item); setShowScheduleInfo(true); }} ><LuInfo /></button></td>
                                </tr>)
                            month++;
                        }

                        return <div className="pay-sched-inf">
                            <Card.Title>PAYMENT SCHEDULES</Card.Title>
                            <Table hover size="lg" className="pay-table">
                                <thead>
                                    <tr>
                                        <th>Month</th>
                                        <th></th>
                                        <th colSpan={2} >Total Amount Due</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRows}
                                </tbody>
                            </Table>
                        </div>
                    }
                }
            }))
    }

    function getAdjustments() {
        setAdjustmentView(_$(`/public/customer/adjustments`,
            {
                customerAccountNumber: customer.accountNumner,
                amortization: customer.amortization,
                monthsPaid: paySchedules.schedulesWithPaymentInfos.length,
                term: customer.term,
                closed: customer.closed,
                factor: customer.factor,
                lineNoOfAccelarationAdj: paySchedules.accelerationAjdLine
            },
            (fail) => { },
            (adjustments) => {
                if (adjustments !== null) {
                    if (adjustments.length !== 0) {
                        const tableRows = [];
                        for (let item of adjustments) {
                            tableRows.push(
                                <tr className="adj-table-row">
                                    <td> {new Date(item.docDate).toLocaleDateString("en-US", dateFormat)} </td>
                                    <td>{item.reference}</td>
                                    <td>{noToZero(item.amount)}</td>
                                </tr>)
                        }
                        return <div>
                            <Card.Title>ACCOUNT ADJUSTMENTS</Card.Title>
                            <Table hover className="adj-table" >
                                <thead>
                                    <tr>
                                        <th>Posted Date</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRows}
                                </tbody>
                            </Table>
                        </div>
                    }
                }
            }))
    }

    function createForm() {
        return <div>
            <Form noValidate validated={validated} className="acc-form">
                {createControl(
                    "controlEngineNo",
                    "Motorcycle Engine Number",
                    "Motorcycle Engine Number",
                    "Please provide an engine number of your motorcycle found in your OR/CR.")}
                {createControl(
                    "controlLastName",
                    "Customer Last Name",
                    "Customer Last Name",
                    ""
                )}
                <Button className="btn-submit" variant="primary" type="button" onClick={
                    (event) => {
                        event.preventDefault();
                        var success = true;
                        var toastMessage = "";
                        const controlEngineNo = document.getElementById('controlEngineNo');
                        const controlLastName = document.getElementById('controlLastName');

                        if (controlEngineNo.validity.valueMissing) {
                            success = false;
                            toastMessage = "Please provide your motorcycle engine number."
                        } else if (controlLastName.validity.valueMissing) {
                            success = false;
                            toastMessage = "Please provide your lastname related to your motorcycle."
                        } else if (controlLastName.validity.patternMismatch) {
                            success = false;
                            toastMessage = "Please provide at least two or more characters"
                        }

                        if (success) {
                            $(`/public/customer`, { engineno: controlEngineNo.value, customer: controlLastName.value },
                                async (fail) => { toast("NO ACCOUNT", "Account not found") },
                                async (onError) => { toast("SERVER ERROR", "") },
                                async (success) => {
                                    localStorage.setItem('acc-form-data', JSON.stringify({ engineno: controlEngineNo.value, customer: controlLastName.value }));
                                    setCustomer(await success.json())
                                }
                            )
                        } else {
                            toast("Check Entry", toastMessage)
                        }
                    }
                } >Search Account</Button>
            </Form>
        </div>

    }





    function createKeyValue(label, value) {
        return <tr className="acc-status-cust-tuple">
            <td> {label} </td>
            <td> {value} </td>
        </tr>
    }


    function renderCustomerInfo() {
        if (customer !== null) {
            if (customer.accountNumner !== null) {


                const currentDate = new Date(customer.now);
                const dateOnlyCurrent = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                let maturityTag = undefined,
                    billingStatement = undefined,
                    billingStatementTotal = 0,
                    forBilling = [],
                    currentNotPaid = undefined,
                    hasPrevious = false,
                    currentTag = undefined;

                if (unpaidSchedules.length !== 0) {


                    if (matured) {
                        billingStatementTotal += maturity.totalMaturityBalance;
                        maturityTag = <>
                            <tr>
                                <td colSpan={3} style={{ border: 'none' }}><strong>MATURITY </strong></td>
                            </tr>
                            <tr>
                                <td> </td>
                                <td>  {maturity.daysMatured + " days maturity"}</td>
                                <td style={{ textAlign: 'right' }}> {new Number(maturity.totalMaturityBalance).toLocaleString('en-US', numberFormat)}</td>
                            </tr>
                        </>
                    }

                    for (let notPaid of unpaidSchedules) {

                        const dueDate = new Date(notPaid.dueDate);
                        const dateOnlyDue = new Date(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate());
                        if (dateOnlyDue.getTime() <= dateOnlyCurrent.getTime()) {
                            if (!hasPrevious) {
                                forBilling.push(<tr>
                                    <td colSpan={3} style={{ border: 'none' }}><strong>PREVIOUS</strong> </td>
                                </tr>);
                                hasPrevious = true;
                            }
                            forBilling.push(<tr>
                                <td colSpan={3} style={{ border: 'none' }}>Previous balance of {dateOnlyDue.toLocaleString('en-US', dateFormat)}:</td>
                            </tr>)
                            forBilling.push(<tr>
                                <td>  </td>
                                <td>  Amortization</td>
                                <td style={{ textAlign: 'right' }}>   {new Number(notPaid.amortizationbalance).toLocaleString('en-US', numberFormat)}</td>
                            </tr>)
                            if (notPaid.surchargebalance > 0) {
                                forBilling.push(<tr>
                                    <td>  </td>
                                    <td>    Surcharge</td>
                                    <td style={{ textAlign: 'right' }}>  {new Number(notPaid.surchargebalance).toLocaleString('en-US', numberFormat)}</td>
                                </tr>)
                            }
                            billingStatementTotal += notPaid.amortizationbalance + notPaid.surchargebalance;
                        }
                        if (currentNotPaid === undefined) {
                            if (dateOnlyDue.getTime() >= currentDate.getTime()) {
                                forBilling.push(<tr>
                                    <td colSpan={3} style={{ border: 'none' }}><strong>CURRENT</strong> </td>
                                </tr>)
                                forBilling.push(<tr>
                                    <td colSpan={3} style={{ border: 'none' }}>Current balance due on {dateOnlyDue.toLocaleString('en-US', dateFormat)}:</td>
                                </tr>)
                                forBilling.push(<tr>
                                    <td> </td>
                                    <td>   Amortization</td>
                                    <td style={{ textAlign: 'right' }}>  {new Number(notPaid.amortizationbalance).toLocaleString('en-US', numberFormat)}</td>
                                </tr>)
                                if (notPaid.surchargebalance > 0) {
                                    forBilling.push(<tr>
                                        <td> </td>
                                        <td>   Surcharge</td>
                                        <td style={{ textAlign: 'right' }}>   {new Number(notPaid.surchargebalance).toLocaleString('en-US', numberFormat)}</td>
                                    </tr>)
                                }
                                currentNotPaid = notPaid;
                                currentTag = "Recommended pay date is " + new Date(notPaid.recommendedPayDate).toLocaleString('en-US', dateFormat) + ". Please pay your balance on or before " + new Date(notPaid.dueDate).toLocaleString('en-US', dateFormat) + " to get a rebate."
                                billingStatementTotal += notPaid.amortizationbalance + notPaid.surchargebalance;
                            }
                        }
                    }

                    forBilling.push(maturityTag);

                    forBilling.push(
                        <tr style={{ border: '1px solid transparent' }}  >
                            <td colSpan={2} style={{ border: 'none', backgroundColor: '#009751', color: '#fff', borderTopLeftRadius: '0.3rem', borderBottomLeftRadius: '1rem', padding: '12px', width: '100%' }}>
                                TOTAL AMOUNT DUE
                            </td>
                            <td style={{ textAlign: 'right', border: 'none', backgroundColor: '#009751', color: '#fff', borderTopRightRadius: '1rem', borderBottomRightRadius: '0.3rem', padding: '12px' }}>
                                {new Number(billingStatementTotal).toLocaleString('en-US', numberFormat)}
                            </td>
                        </tr>
                    );

                    billingStatement = <Card className="a-s-c-i-card">
                        <Card.Header><strong>Statement Of Account</strong></Card.Header>
                        <Card.Body className="a-s-c-i-1">
                            <Card.Text>
                                <Table className="bill-table" >
                                    <tbody  >
                                        {forBilling}
                                    </tbody>
                                </Table>
                                {currentTag === undefined ? <></> : <p style={{ color: '#006435' }}>{currentTag}</p>}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }

                return <>
                    <Card className="a-s-c-i-card" >
                        <Card.Header ><strong>Customer Information</strong></Card.Header>
                        <Card.Body className="a-s-c-i-1">
                            <Table size="sm">
                                <tbody>
                                    {createKeyValue('Customer', customer.customerName)}
                                    {createKeyValue('Address', customer.address)}
                                    {createKeyValue('Barangay', customer.barangay)}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                    <Card className="a-s-c-i-card" >
                        <Card.Header><strong>Product Loan Information </strong></Card.Header>
                        <Card.Body className="a-s-c-i-1">
                            <Table size="sm">
                                <tbody>
                                    {createKeyValue('Product', customer.make + " " + customer.desc)}
                                    {createKeyValue('Model', customer.model)}
                                    {createKeyValue('Color', customer.color)}
                                    {createKeyValue('Engine No.', customer.engineNumber)}
                                    {createKeyValue('Chassis No.', customer.chassisNumber)}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {billingStatement}
                </>
            }
        }
    }

    return <div className='acc-status-page' style={channel === 'VMI' ? { "--color": "58, 123, 189" } : { "--color": "194, 2, 2" }}  >
        <span>CUSTOMER ACCOUNT STATUS</span>
        {customer ?
            <ButtonGroup className="acc-btngroup" style={{ width: "fit-content", gap: '2px' }}  >
                <Button variant="primary"
                    onClick={(e) => { onReload() }}><RiRefreshFill /><br />  <span  >Refresh</span></Button>
                <Button variant="danger"
                    onClick={(e) => { onClose() }}><IoExit /><br />  <span >Exit</span></Button>
            </ButtonGroup>
            : createForm()}
        {customer ?
            <div className="acc-status-main mt-3">
                <div className="acc-status-cust-inf">{renderCustomerInfo()}</div>
                <Card className="a-s-c-i-card">
                    <Card.Header><strong>My Account</strong></Card.Header>
                    <Card.Body className="a-s-c-i-1">
                        <div className='acc-status-and-paysched' >
                            <div className="pay-sched-account" >
                                <div className="pay-sched-m1">
                                    <Card.Title>ACCOUNT INFORMATION</Card.Title>
                                    <Table size="sm">
                                        <tbody>
                                            {createKeyValue('Account No.', customer.accountNumner)}
                                            {createKeyValue('Account Status', customer.closed ? "CLOSED" : "ACTIVE")}
                                            {createKeyValue('Amortization', new Number(customer.amortization).toLocaleString('en-US', numberFormat))}
                                            {createKeyValue('Loan Term', customer.term + " Months")}
                                            {createKeyValue('Loan Amount', new Number(customer.term * customer.amortization).toLocaleString('en-US', numberFormat))}
                                            {createKeyValue('Total Loan Paid', new Number((customer.term * customer.amortization) - totalAmortizationBal).toLocaleString('en-US', numberFormat))}
                                            {createKeyValue('Total Loan Balance', new Number(totalAmortizationBal).toLocaleString('en-US', numberFormat))}
                                        </tbody>
                                    </Table>
                                </div>
                                {paidPercentage}

                            </div>
                            {paySchedView}
                            {adjustmentView}
                        </div>
                    </Card.Body>
                </Card>
            </div> : <></>}
        <Modal show={showScheduleInfo} onHide={handleCloseScheduleInfo} className="pay-modal">
            <Modal.Header closeButton   />
            <Modal.Title className="modal-title-acc">Schedule and Payment Information</Modal.Title>
            <Modal.Body >{renderScheduleInfo()}</Modal.Body>
        </Modal>
    </div>

}
