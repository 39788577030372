
import { Suspense, useEffect, useRef, useState } from "react";
import "./ContentSliderBinding.scss";
import TransactionTemplate from "../../Reusables/TransactionTemplate.jsx";
import { Badge, Button, Form, ListGroup } from "react-bootstrap";
import Card from "../Helper/Card.jsx";
import UpdatingCard from "../Helper/UpdatingCard.jsx";
import NoData from "../Helper/NoData.jsx";
import { awaitedQueryData, awaitedSubmitData } from "../Helper/PromiseWrapper.ts";
import SuspenseBody from "../Helper/SuspenseBody.jsx";
import Toast from "./Toast.jsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { GET } from "../../Reusables/FetchHandler.jsx";
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import { formatDateToMMMDDYYYY, formatDateToYYYYMMDD } from "../../Reusables/DateFormatter.jsx";
import { ValueValidator } from "../../Reusables/ValueValidator.jsx";

export default function ContentSliderBinding(props) {



    const onFetchBusinessList = () => {
        setBusinessCards(<UpdatingCard />)

        GET('/api/mgrbusiness/activelist',
            async (failedResponse) => {
                console.log(failedResponse)
            },
            async (successResponse) => {
                setBusinessList(await successResponse.json())
            },
            async (error) => {
                console.log(error)
            }
        )

    }


    const onFetchList = (page) => {
        if (selectedBusiness) {

            removeSelected(".ctmd-md-nav[selected=\"true\"]");
            transactionTemplate.setTransactionList(undefined)
            transactionTemplate.setCards(<UpdatingCard />)
            const parameters = "?" + new URLSearchParams({
                businessId: selectedBusiness.businessId,
                page: page,
                limit: transactionTemplate.getLimit(),
                voided: transactionTemplate.getVoided()
            }).toString();

            GET('/api/mgrperlocationslidercontent/list' + parameters,
                async (failedResponse) => {
                    console.log(failedResponse)
                },
                async (successResponse) => {
                    const response = await successResponse.json();
                    transactionTemplate.setTransactionList(response.response);
                    transactionTemplate.setTotalPages(Math.ceil(response.totalCount / transactionTemplate.getLimit()));
                },
                async (error) => {
                    console.log(error)
                }
            )
        } else {
            transactionTemplate.setTransactionList([])
            transactionTemplate.setTotalPages(0);
        }
    }

    function createEntryForm(title, type, data, callback) {
        formDataRef.current = data ? { ...data } : { businessId: selectedBusiness.businessId, voided: false };
        transactionTemplate.createEntryForm(title,
            <>
                <Suspense fallback={<UpdatingCard />}>
                    <SuspenseBody
                        wrappedPromiseData={awaitedQueryData('/api/mgrslidercontent/activelist')}
                        onCreateBody={(result) => {
                            const options = result.map((e) => {
                                return {
                                    value: e.contentId, label: <div className="card-image-content-group"  >
                                        <div className="cicgg-p">
                                            <span className="ggp">{e.description}</span>
                                            <span className="ggp">{""}</span>
                                        </div>
                                        <div className="cicgg-i">
                                            <LazyLoadImage className="ciciimg" src={'/public/mgrslidercontent/image?' + new URLSearchParams({ imageUUID: e.imageWebUUID, imageType: 80 }).toString()} />
                                            <LazyLoadImage className="ciciimg" src={'/public/mgrslidercontent/image?' + new URLSearchParams({ imageUUID: e.imageMobileUUID, imageType: 80 }).toString()} />
                                        </div>
                                        <div className="cicgg-p">
                                            <span >{"Upon clicking, follow link " + e.url}</span>
                                        </div>
                                    </div>
                                }
                            })
                            let defaults = undefined;
                            if (formDataRef.current.contentId) {
                                defaults = options.map(
                                    (element) => { if (element.value === formDataRef.current.contentId) { return element } });
                            }
                            return <Form.Group className="mb-3  " >
                                <Form.Label>Content (Can select only one)</Form.Label>
                                <Select
                                    options={options}
                                    menuPlacement="bottom"
                                    defaultValue={defaults}
                                    isSearchable={false}
                                    onChange={(option) => formDataRef.current.contentId = option.value}
                                />
                            </Form.Group>
                        }} />
                </Suspense>


                <Form.Group controlId="BannerName" className="mb-3" >
                    <Form.Label>Date (from)</Form.Label>
                    <Form.Control type="date" name="fromDate"
                        defaultValue={formDataRef.current ? formatDateToYYYYMMDD(new Date(formDataRef.current.from)) : undefined}
                        onChange={(e) => formDataRef.current.from = new Date(e.currentTarget.value)} />
                </Form.Group>


                <Form.Group controlId="BannerName" className="mb-3" >
                    <Form.Label>Date (to)</Form.Label>
                    <Form.Control type="date" name="toDate"
                        defaultValue={formDataRef.current ? formatDateToYYYYMMDD(new Date(formDataRef.current.to)) : undefined}
                        onChange={(e) => formDataRef.current.to = new Date(e.currentTarget.value)} />
                </Form.Group>

                <Form.Check type="checkbox" label="Show in Main slider"
                    defaultChecked={formDataRef.current ? formDataRef.current.mainBannerSlider : undefined}
                    onChange={(e) => formDataRef.current.mainBannerSlider = Boolean(e.currentTarget.checked)} />

                <Form.Check type="checkbox" label="Show in News and Events slider"
                    defaultChecked={formDataRef.current ? formDataRef.current.newsAndEventsSlider : undefined}
                    onChange={(e) => formDataRef.current.newsAndEventsSlider = Boolean(e.currentTarget.checked)} />

                <Form.Check className="mb-3" type="checkbox" label="Show in Announcement and Promotions slider"
                    defaultChecked={formDataRef.current ? formDataRef.current.announcementsAndPromoSlider : undefined}
                    onChange={(e) => formDataRef.current.announcementsAndPromoSlider = Boolean(e.currentTarget.checked)} />

            </>,
            (type === "add" ?
                <Button onClick={(e) => { onSaveAddNew(e, callback) }}>Save New</Button> :
                <Button onClick={(e) => { onSaveEdit(e, data, callback) }}>Update</Button>));
    }


    const onAddNew = () => {
        createEntryForm("New Item", "add", undefined, transactionTemplate.setNewDataCallBack)
    }

    const onSaveAddNew = (e, callback) => {
        e.target.disabled = true;

        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(formDataRef.current.contentId, `Content`)
            .add(formDataRef.current.from, `Date (from)`)
            .add(formDataRef.current.to, `Date (to)`)
            .add(formDataRef.current.mainBannerSlider || formDataRef.current.newsAndEventsSlider || formDataRef.current.announcementsAndPromoSlider, `Select atleast 1 of the slider`)
            .validate()
            .get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {

            const request = new FormData()
            request.append("perLocationSliderContent", new Blob([JSON.stringify(formDataRef.current)], { type: "application/json" }));

            callback(<Suspense fallback={<></>}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrperlocationslidercontent/onsavenew', request)}
                    onCreateBody={(result) => {
                        console.log(result)
                        if (result.fail) {
                            e.target.disabled = false;
                            transactionTemplate.setToast(
                                <Toast
                                    variant="danger"
                                    onClose={() => { transactionTemplate.setToast(undefined) }}
                                    headingText={result.status + "  " + result.statusText}
                                    descriptionText={result.response} />)
                        } else {

                            onFetchList(transactionTemplate.getCurrentPage());
                            transactionTemplate.setEntryForm(undefined)
                            transactionTemplate.setToast(
                                <Toast
                                    variant="success"
                                    onClose={() => { transactionTemplate.setToast(undefined) }}
                                    headingText={"201 OK"}
                                    descriptionText={"Item has been created"} />
                            )
                        }
                    }} />
            </Suspense>)
        }
    }


    const onEdit = (data, callback) => {
        createEntryForm("Modify Item [ " + data.businessId + "@" + data.contentId + " ]", "update", data, callback)
    }

    const onSaveEdit = (e, data, callback) => {
        e.target.disabled = true;

        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(formDataRef.current.contentId, `Content`)
            .add(formDataRef.current.from, `Date (from)`)
            .add(formDataRef.current.to, `Date (to)`)
            .add(formDataRef.current.mainBannerSlider || formDataRef.current.newsAndEventsSlider || formDataRef.current.announcementsAndPromoSlider, `Select atleast 1 of the slider`)
            .validate()
            .get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {

            const request = new FormData()
            request.append("oldContentId", data.contentId);
            request.append("perLocationSliderContent", new Blob([JSON.stringify(formDataRef.current)], { type: "application/json" }));

            callback(<Suspense fallback={<UpdatingCard />}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrperlocationslidercontent/update', request)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            callback(undefined);
                            e.target.disabled = false;
                            transactionTemplate.setToast(<Toast
                                variant="danger"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={result.status + "  " + result.statusText}
                                descriptionText={result.response} />)
                        } else {

                            transactionTemplate.setTransactionList(transactionTemplate.getTransactionList().map(item => item === data ? result : item))
                            transactionTemplate.setEntryForm(undefined)
                            setSelectedContentBinding(result)
                            callback(undefined);
                            transactionTemplate.setToast(<Toast
                                variant="success"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={"201 OK"}
                                descriptionText={"Modification has been saved"} />)
                        }
                    }} />
            </Suspense>)
        }
    }


    function onUpdateStatus(data, callback, voided) {

        if (formDataRef.current) {
            transactionTemplate.setToast(<Toast
                variant="danger"
                onClose={() => { transactionTemplate.setToast(undefined) }}
                headingText={"405 Method Not Allowed"}
                descriptionText={"Your requested action is not allowed for currently editing data"} />)
            return;
        }

        const map = new FormData()
        map.append("businessId", data.businessId);
        map.append("contentId", data.contentId);
        map.append("voided", voided);
        callback(<Suspense fallback={<UpdatingCard />}>
            <SuspenseBody
                wrappedPromiseData={awaitedSubmitData('/api/mgrperlocationslidercontent/updatestatus', map)}
                onCreateBody={(result) => {
                    if (result.fail) {
                        transactionTemplate.setToast(<Toast
                            variant="danger"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={result.status + "  " + result.statusText}
                            descriptionText={result.response} />)
                    } else {
                        transactionTemplate.setToast(<Toast
                            variant="success"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={"201 OK"}
                            descriptionText={"Modification has been saved"} />)
                        let pageNo = transactionTemplate.getCurrentPage();
                        if (transactionTemplate.getTransactionList().length === 1) { transactionTemplate.setCurrentPage(pageNo = transactionTemplate.getCurrentPage() - 1) }
                        onFetchList(pageNo);
                    }
                }}
            />
        </Suspense>)
    }

    const onUnvoid = (data, callback) => {
        onUpdateStatus(data, callback, false)
    }


    const onVoid = (data, callback) => {
        onUpdateStatus(data, callback, true)
    }

    function onCreateCards() {
        if (!transactionTemplate.getTransactionList()) { return <UpdatingCard /> }
        return transactionTemplate.getTransactionList().length === 0 ? NoData() :
            <div className="cardlist">
                {transactionTemplate.getTransactionList().map((data) => {
                    return <Card
                        controlKey={data.contentId}
                        isRounded={false}
                        removeButtonText="Void"
                        voided={data.voided}
                        onEdit={onEdit}
                        onVoidOrDelete={onVoid}
                        onUnvoid={onUnvoid}
                        isItemIsBody={true}
                        item={{
                            data: data,
                            render: <Suspense fallback={<UpdatingCard />}>
                                <SuspenseBody
                                    wrappedPromiseData={awaitedQueryData('/api/mgrslidercontent/get?' + new URLSearchParams({ contentId: data.contentId }).toString())}
                                    onCreateBody={(result) => {
                                        if (result.fail) {
                                            transactionTemplate.setToast(<Toast
                                                variant="danger"
                                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                                headingText={result.status + "  " + result.statusText}
                                                descriptionText={result.response} />)
                                        } else {
                                            return <div className="card-image-content-group">
                                                <div className="cicgg-p">
                                                    <span className="ggp">{result.description}</span>
                                                    <span className="ggp">{"Available at " + formatDateToMMMDDYYYY(new Date(data.from)) + " until " + formatDateToMMMDDYYYY(new Date(data.to))}</span>
                                                </div>
                                                <div className="cicgg-i">
                                                    <LazyLoadImage className="ciciimg" src={'/public/mgrslidercontent/image?' + new URLSearchParams({ imageUUID: result.imageWebUUID, imageType: 80 }).toString()} />
                                                    <LazyLoadImage className="ciciimg" src={'/public/mgrslidercontent/image?' + new URLSearchParams({ imageUUID: result.imageMobileUUID, imageType: 80 }).toString()} />
                                                </div>
                                                <div className="cicgg-p">
                                                    {data.mainBannerSlider ? <Badge>{"Main Banner"}</Badge> : <></>}
                                                    {data.newsAndEventsSlider ? <Badge>{"News and Events Banner"}</Badge> : <></>}
                                                    {data.announcementsAndPromoSlider ? <Badge>{"Announcements and Promo Banner"}</Badge> : <></>}
                                                </div>
                                                <div className="cicgg-p">
                                                    <span style={{ color: "#3a79b8" }} >{"Upon clicking, follow link " + result.url}</span>
                                                </div>
                                            </div>
                                        }
                                    }}
                                />
                            </Suspense>
                        }}

                    />;
                })}
            </div>
    }



    const onDisplaySlides = () => {

    }




    function onTransactionFormClosed() {
        formDataRef.current = undefined;

    }

    function removeSelected(selector) {
        const elements = document.querySelectorAll(selector);
        if (elements.length !== 0) { elements[0].removeAttribute("selected") }
    }
    function setSelected(selector) {
        const elements = document.querySelectorAll(selector);
        if (elements.length !== 0) { elements[0].setAttribute("selected", "true") };
    }

    function onTransactionListUpdated(transactionList) {
        if (transactionList) {
            if (transactionList.length !== 0) {
                if (selectedContentBinding) {
                    /* const element = document.getElementById(selectedContentBinding.contentId);
                    if (element) {
                        element.click()
                        element.setAttribute("selected", "true")
                    } */
                } else {
                    setSelectedContentBinding(transactionList[0])
                    /*        setSelected("div.card"); */
                }
            }
        }
    }

    const formDataRef = useRef();

    const transactionTemplate = {};
    const [BusinessCards, setBusinessCards] = useState();
    const [BusinessList, setBusinessList] = useState();
    const [selectedBusiness, setSelectedBusiness] = useState();
    const [selectedContentBinding, setSelectedContentBinding] = useState();
    const [displaySlides, setDisplaySlides] = useState();

    useEffect(() => {
        onFetchBusinessList()
    }, [])

    useEffect(() => {
        if (!BusinessList) {
            setBusinessCards(undefined)
        } else {

            setBusinessCards(<div className="list-selector business-selector">
                {BusinessList.map((data) => {
                    return <button className="bl-business-selector" variant="light" onClick={(e) => {
                        removeSelected(".bl-business-selector[selected=\"true\"]");
                        e.currentTarget.setAttribute("selected", "true");
                        setSelectedBusiness(data)
                    }}>
                        <LazyLoadImage src={'/public/mgrbusiness/image?' + new URLSearchParams({ id: data.imageUUID, imageType: 90 }).toString()}
                        /> </button>;
                })}
            </div>)

        }
    }, [BusinessList])

    useEffect(() => {
        if (BusinessList) {
            const prevSelected = document.querySelectorAll(".bl-business-selector");
            if (prevSelected.length !== 0) { prevSelected[0].setAttribute("selected", "true") }
            setSelectedBusiness(BusinessList[0])
        }
    }, [BusinessCards])

    useEffect(() => {
        if (selectedBusiness) {
            transactionTemplate.setCurrentPage(1);
        }
        onFetchList(1)
    }, [selectedBusiness])


    useEffect(() => { if (selectedContentBinding) { setDisplaySlides(onDisplaySlides()); } }, [selectedContentBinding])

    return <TransactionTemplate
        onTransactionListUpdated={onTransactionListUpdated}
        controlId="txn-contentbinding"
        bind={transactionTemplate}
        imports={{ onFetchList: onFetchList, onCreateCards: onCreateCards, onAddNew: onAddNew, onFormClosed: onTransactionFormClosed }}
        leftSideComp={<div>{BusinessCards}</div>}
        rightSideComp={<div className="txn-contmt-fd">
            <div className="txnp">
                {selectedContentBinding ? <ListGroup>
                    <ListGroup.Item  > {displaySlides}</ListGroup.Item>
                </ListGroup> : <></>}
            </div>
        </div>}
        onListTypeChanging={() => setSelectedContentBinding(undefined)}
    />
}