
import { Suspense, useEffect, useRef, useState } from "react";
import "./Models.scss";
import TransactionTemplate from "../../Reusables/TransactionTemplate.jsx";
import { Button, Form } from "react-bootstrap";
import Card from "../Helper/Card.jsx";
import UpdatingCard from "../Helper/UpdatingCard.jsx";
import NoData from "../Helper/NoData.jsx";
import { awaitedSubmitData } from "../Helper/PromiseWrapper.ts";
import SuspenseBody from "../Helper/SuspenseBody.jsx";
import CodeShorter from "../Helper/CodeShorter.jsx";
import Toast from "./Toast.jsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ValueValidator } from "../../Reusables/ValueValidator.jsx";


export default function Models(props) {


    const onFetchBrandList = () => {
        setBrandCards(<UpdatingCard />)
        fetch('/api/mgrproductbrand/activelist')
            .then(response => { return response.json() })
            .then(response => {
                setBrandList(response);
            })
    }

    const onFetchList = (page) => {
        if (selectedBrand) {
            transactionTemplate.setCards(<UpdatingCard />)
            fetch('/api/mgrproductmodel/list?' +
                new URLSearchParams({ brandId: selectedBrand.brandId, page: page, limit: transactionTemplate.getLimit(), voided: transactionTemplate.getVoided() }).toString())
                .then(response => { return response.json() })
                .then(response => {
                    transactionTemplate.setTransactionList(response.response);
                    transactionTemplate.setTotalPages(Math.ceil(response.totalCount / transactionTemplate.getLimit()));
                })
        } else {
            transactionTemplate.setTransactionList([])
        }
    }


    function createEntryForm(title, type, data, callback) {
        transactionTemplate.createEntryForm(title,
            <>
                <Form.Group className="mb-3" >
                    <Form.Label>Model Name</Form.Label>
                    <Form.Control className="default-focus" type="text" name="name" placeholder="Enter model name" defaultValue={data ? data.name : ""} />
                </Form.Group>
            </>,
            (type === "add" ?
                <Button onClick={(e) => { onSaveAddNew(e, callback) }}>Save New</Button> :
                <Button onClick={(e) => { onSaveEdit(e, data, callback) }}>Update</Button>));
    }


    const onAddNew = () => {
        createEntryForm("New Item", "add", undefined, transactionTemplate.setNewDataCallBack)
    }

    const onSaveAddNew = (e, callback) => {
        e.target.disabled = true;
        const source = new FormData(document.getElementById("entry-form"));

        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(source.get("name"), `Model Name`)
            .validate()
            .get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {
            const map = new FormData()
            map.append("name", source.get("name"));
            map.append("brandId", selectedBrand.brandId);
            callback(<Suspense fallback={<></>}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrproductmodel/savenew', map)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            e.target.disabled = false;
                            transactionTemplate.setToast(
                                <Toast
                                    variant="danger"
                                    onClose={() => { transactionTemplate.setToast(undefined) }}
                                    headingText={result.status + "  " + result.statusText}
                                    descriptionText={result.response} />)
                        } else {
                            onFetchList(transactionTemplate.getCurrentPage());
                            transactionTemplate.setEntryForm(undefined)
                            transactionTemplate.setToast(
                                <Toast
                                    variant="success"
                                    onClose={() => { transactionTemplate.setToast(undefined) }}
                                    headingText={"201 OK"}
                                    descriptionText={"Item has been created"} />
                            )
                        }
                    }} />
            </Suspense>)
        }
    }


    const onEdit = (data, callback) => {
        createEntryForm("Modify Item [ " + data.name + " ]", "update", formDataRef.current = data, callback)
    }

    const onSaveEdit = (e, data, callback) => {
        e.target.disabled = true;
        const source = new FormData(document.getElementById("entry-form"));

        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(source.get("name"), `Model Name`)
            .validate()
            .get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {
            const map = new FormData()
            map.append("id", data.modelId);
            map.append("name", source.get("name"));

            callback(<Suspense fallback={<UpdatingCard />}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrproductmodel/update', map)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            callback(undefined);
                            e.target.disabled = false;
                            transactionTemplate.setToast(<Toast
                                variant="danger"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={result.status + "  " + result.statusText}
                                descriptionText={result.response} />)
                        } else {
                            transactionTemplate.setTransactionList(transactionTemplate.getTransactionList().map(item => item === data ? result : item))
                            transactionTemplate.setEntryForm(undefined)
                            callback(undefined);
                            transactionTemplate.setToast(<Toast
                                variant="success"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={"201 OK"}
                                descriptionText={"Modification has been saved"} />)
                        }
                    }} />
            </Suspense>)
        }
    }


    function onUpdateStatus(data, callback, voided) {

        if (formDataRef.current) {
            transactionTemplate.setToast(<Toast
                variant="danger"
                onClose={() => { transactionTemplate.setToast(undefined) }}
                headingText={"405 Method Not Allowed"}
                descriptionText={"Your requested action is not allowed for currently editing data"} />)
            return;
        }

        const map = new FormData()
        map.append("id", data.modelId);
        map.append("voided", voided);
        callback(<Suspense fallback={<UpdatingCard />}>
            <SuspenseBody
                wrappedPromiseData={awaitedSubmitData('/api/mgrproductmodel/updatestatus', map)}
                onCreateBody={(result) => {
                    if (result.fail) {
                        transactionTemplate.setToast(<Toast
                            variant="danger"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={result.status + "  " + result.statusText}
                            descriptionText={result.response} />)
                    } else {
                        transactionTemplate.setToast(<Toast
                            variant="success"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={"201 OK"}
                            descriptionText={"Modification has been saved"} />)
                        let pageNo = transactionTemplate.getCurrentPage();
                        if (transactionTemplate.getTransactionList().length === 1) { transactionTemplate.setCurrentPage(pageNo = transactionTemplate.getCurrentPage() - 1) }
                        onFetchList(pageNo);
                    }
                }}
            />
        </Suspense>)
    }

    const onUnvoid = (data, callback) => {
        onUpdateStatus(data, callback, false)
    }


    const onVoid = (data, callback) => {
        onUpdateStatus(data, callback, true)
    }

    function onCreateCards() {
        if (!transactionTemplate.getTransactionList()) { return <UpdatingCard /> }
        return transactionTemplate.getTransactionList().length === 0 ? NoData() :
            <div className="cardlist">
                {transactionTemplate.getTransactionList().map((data) => {
                    return <Card isRounded={false} item={{ title: data.name, description: <small className="text-muted"><b>{CodeShorter(data.modelId, 12) + + " (Model)"}</b></small>, data: data }} voided={data.voided} onEdit={onEdit} onVoidOrDelete={onVoid} onUnvoid={onUnvoid}
                        icon={'/public/mgrproductbrand/image?' + new URLSearchParams({ id: data.brandId, imageType: 90 }).toString()}
                        removeButtonText="Void" />;
                })}
            </div>
    }

    function onTransactionFormClosed() {
        formDataRef.current = undefined;

    }


    const formDataRef = useRef(null);
    const transactionTemplate = {};

    const [brandCards, setBrandCards] = useState();
    const [brandList, setBrandList] = useState();
    const [selectedBrand, setSelectedBrand] = useState();


    useEffect(() => {
        onFetchBrandList()
    }, [])

    useEffect(() => {
        if (!brandList) {
            setBrandCards(undefined)
        } else {
            setBrandCards(<div className="brandSelector">
                {brandList.map((data) => {
                    return <button className="bl-bt-selector" variant="light" onClick={(e) => {
                        const prevSelected = document.querySelectorAll(".bl-bt-selector[selected=\"true\"]");
                        if (prevSelected.length !== 0) { prevSelected[0].removeAttribute("selected") }
                        e.currentTarget.setAttribute("selected", "true");
                        setSelectedBrand(data)
                    }}>
                        <LazyLoadImage src={'/public/mgrproductbrand/image?' + new URLSearchParams({ id: data.brandId, imageType: 90 }).toString()}
                        /> </button>;
                })}
            </div>)
        }
    }, [brandList])

    useEffect(() => {
        if (brandList) {
            const prevSelected = document.querySelectorAll(".bl-bt-selector");
            if (prevSelected.length !== 0) { prevSelected[0].setAttribute("selected", "true") }
            setSelectedBrand(brandList[0])
        }
    }, [brandCards])

    useEffect(() => {
        if (selectedBrand) {
            transactionTemplate.setCurrentPage(1);
        }
        onFetchList(1)
    }, [selectedBrand])

    return <TransactionTemplate
        controlId="txn-models"
        bind={transactionTemplate}
        imports={{ onFetchList: onFetchList, onCreateCards: onCreateCards, onAddNew: onAddNew, onFormClosed: onTransactionFormClosed }}
        leftSideComp={<div>{brandCards}</div>}
    />
}