
import { Suspense, useEffect, useRef, useState } from "react";
import "./FAQAdmin.scss";
import TransactionTemplate from "../../Reusables/TransactionTemplate.jsx";
import { Badge, Button, Form, ListGroup } from "react-bootstrap";
import Card from "../Helper/Card.jsx";
import UpdatingCard from "../Helper/UpdatingCard.jsx";
import NoData from "../Helper/NoData.jsx";
import { awaitedSubmitData } from "../Helper/PromiseWrapper.ts";
import SuspenseBody from "../Helper/SuspenseBody.jsx";
import CodeShorter from "../Helper/CodeShorter.jsx";
import Toast from "./Toast.jsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { TbEditCircle } from "react-icons/tb";
import { POST, GET } from "../../Reusables/FetchHandler.jsx";
import { RiQuestionnaireFill } from "react-icons/ri";
import { ValueValidator } from "../../Reusables/ValueValidator.jsx";
export default function FAQAdmin(props) {


    const onFetchFAQCategoryList = () => {
        setFAQCategoryCards(<UpdatingCard />)

        GET('/api/mgrfaqcategory/activelist',
            async (failedResponse) => {
                console.log(failedResponse)
            },
            async (successResponse) => {
                setFAQCategoryList(await successResponse.json())
            },
            async (error) => {
                console.log(error)
            }
        )

    }

    const onFetchList = (page) => {

        if (selectedFAQCategory) {
            removeSelected(".ctmd-md-nav[selected=\"true\"]");
            transactionTemplate.setTransactionList(undefined)

            transactionTemplate.setCards(<UpdatingCard />)
            fetch('/api/mgrfaq/list?' +
                new URLSearchParams({ catId: selectedFAQCategory.catId, page: page, limit: transactionTemplate.getLimit(), voided: transactionTemplate.getVoided() }).toString())
                .then(response => { return response.json() })
                .then(response => {
                    transactionTemplate.setTransactionList(response.response);
                    transactionTemplate.setTotalPages(Math.ceil(response.totalCount / transactionTemplate.getLimit()));
                })
        } else {
            transactionTemplate.setTransactionList([])
            transactionTemplate.setTotalPages(0);
            document.querySelector(".ctmd-md-nav").setAttribute("selected", "true");
        }
    }


    function createEntryForm(title, type, data, callback) {

        formDataRef.current = data ? data : {};

        transactionTemplate.createEntryForm(title,
            <>
                <Form.Group className="mb-3" >
                    <Form.Label>Question</Form.Label>
                    <Form.Control className="default-focus" type="text"
                        onChange={(e) => formDataRef.current.question = e.currentTarget.value}
                        defaultValue={data ? formDataRef.current.question : ""} />
                </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Answer</Form.Label>
                    <Form.Control as="textarea" rows={22}
                        onChange={(e) => formDataRef.current.answer = e.currentTarget.value}
                        defaultValue={data ? formDataRef.current.answer : ""} />
                </Form.Group>
            </>,
            (type === "add" ?
                <Button onClick={(e) => { onSaveAddNew(e, callback) }}>Save New</Button> :
                <Button onClick={(e) => { onSaveEdit(e, data, callback) }}>Update</Button>));
    }


    const onAddNew = () => {
        createEntryForm("New Item", "add", undefined, transactionTemplate.setNewDataCallBack)
    }

    const onSaveAddNew = (e, callback) => {
        e.target.disabled = true;

        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(formDataRef.current.question, `Question`)
            .add(formDataRef.current.answer, `Answer`)
            .validate()
            .get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {

            formDataRef.current.catId = selectedFAQCategory.catId;
            const request = new FormData()
            request.append("faq", new Blob([JSON.stringify(formDataRef.current)], { type: "application/json" }));

            callback(<Suspense fallback={<></>}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrfaq/savenew', request)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            e.target.disabled = false;
                            transactionTemplate.setToast(
                                <Toast
                                    variant="danger"
                                    onClose={() => { transactionTemplate.setToast(undefined) }}
                                    headingText={result.status + "  " + result.statusText}
                                    descriptionText={result.response} />)
                        } else {
                            onFetchList(transactionTemplate.getCurrentPage());
                            transactionTemplate.setEntryForm(undefined)
                            transactionTemplate.setToast(
                                <Toast
                                    variant="success"
                                    onClose={() => { transactionTemplate.setToast(undefined) }}
                                    headingText={"201 OK"}
                                    descriptionText={"Item has been created"} />
                            )
                        }
                    }} />
            </Suspense>)
        }
    }

    const onEdit = (data, callback) => {
        createEntryForm("Modify Item", "update", data, callback)
    }

    const onSaveEdit = (e, data, callback) => {
        e.target.disabled = true;

        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(formDataRef.current.question, `Question`)
            .add(formDataRef.current.answer, `Answer`)
            .validate() 
            .get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {
            const request = new FormData()
            request.append("faq", new Blob([JSON.stringify(formDataRef.current)], { type: "application/json" }));

            callback(<Suspense fallback={<UpdatingCard />}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrfaq/update', request)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            callback(undefined);
                            e.target.disabled = false;
                            transactionTemplate.setToast(<Toast
                                variant="danger"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={result.status + "  " + result.statusText}
                                descriptionText={result.response} />)
                        } else {

                            transactionTemplate.setTransactionList(transactionTemplate.getTransactionList().map(item => item === data ? result : item))
                            transactionTemplate.setEntryForm(undefined)
                            setSelectedFAQ(result)
                            callback(undefined);
                            transactionTemplate.setToast(<Toast
                                variant="success"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={"201 OK"}
                                descriptionText={"Modification has been saved"} />)
                        }
                    }} />
            </Suspense>)
        }
    }



    function onUpdateStatus(data, callback, voided) {

        if (formDataRef.current) {
            transactionTemplate.setToast(<Toast
                variant="danger"
                onClose={() => { transactionTemplate.setToast(undefined) }}
                headingText={"405 Method Not Allowed"}
                descriptionText={"Your requested action is not allowed for currently editing data"} />)
            return;
        }

        const map = new FormData()
        map.append("id", data.faqId);
        map.append("voided", voided);
        callback(<Suspense fallback={<UpdatingCard />}>
            <SuspenseBody
                wrappedPromiseData={awaitedSubmitData('/api/mgrfaq/updatestatus', map)}
                onCreateBody={(result) => {
                    if (result.fail) {
                        transactionTemplate.setToast(<Toast
                            variant="danger"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={result.status + "  " + result.statusText}
                            descriptionText={result.response} />)
                    } else {
                        transactionTemplate.setToast(<Toast
                            variant="success"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={"201 OK"}
                            descriptionText={"Modification has been saved"} />)
                        let pageNo = transactionTemplate.getCurrentPage();
                        if (transactionTemplate.getTransactionList().length === 1) { transactionTemplate.setCurrentPage(pageNo = transactionTemplate.getCurrentPage() - 1) }
                        onFetchList(pageNo);
                    }
                }}
            />
        </Suspense>)
    }

    const onUnvoid = (data, callback) => {
        onUpdateStatus(data, callback, false)
    }


    const onVoid = (data, callback) => {
        onUpdateStatus(data, callback, true)
    }

    function onCreateCards() {
        if (!transactionTemplate.getTransactionList()) { return <UpdatingCard /> }
        return transactionTemplate.getTransactionList().length === 0 ? NoData() :
            <div className="cardlist">
                {transactionTemplate.getTransactionList().map((data) => {
                    return <Card
                        controlKey={data.faqId}
                        isRounded={false} item={{ title: data.question, description: <small className="text-muted"><b>{CodeShorter(data.faqId, 12) + + " (Model)"}</b></small>, data: data }} voided={data.voided} onEdit={onEdit} onVoidOrDelete={onVoid} onUnvoid={onUnvoid}
                        icon={<RiQuestionnaireFill />}
                        isSvg={true}
                        removeButtonText="Void"
                        onClick={(e) => {
                            removeSelected("div.card[selected=\"true\"]");
                            document.getElementById(data.faqId).setAttribute("selected", "true");

                            setSelectedFAQ(data)
                        }}
                    />;
                })}
            </div>
    }



    const onDisplayAnswer = () => {
        if (selectedFAQ) {

            return <div className="txnp-image">
                <div className="listgroup-header   mb-2">
                    <span>{"Answer"}</span>
                </div>
                <div className="txnp-body txnp-var-grid">
                    <span className="preserve">{selectedFAQ.answer}</span>

                </div>
            </div>


        }
    }



    function onTransactionFormClosed() {
        formDataRef.current = undefined;
    }

    function removeSelected(selector) {
        const elements = document.querySelectorAll(selector);
        if (elements.length !== 0) { elements[0].removeAttribute("selected") }
    }
    function setSelected(selector) {
        const elements = document.querySelectorAll(selector);
        if (elements.length !== 0) { elements[0].setAttribute("selected", "true") };
    }

    function onTransactionListUpdated(transactionList) {
        if (transactionList) {
            if (transactionList.length !== 0) {
                if (selectedFAQ) {
                    const element = document.getElementById(selectedFAQ.faqId);
                    if (element) {
                        element.click()
                        element.setAttribute("selected", "true")
                    }
                } else {
                    setSelectedFAQ(transactionList[0])
                    setSelected("div.card");
                }
            }
        }
    }

    const formDataRef = useRef();
    const transactionTemplate = {};
    const [FAQCategoryCards, setFAQCategoryCards] = useState();
    const [FAQCategoryList, setFAQCategoryList] = useState();
    const [selectedFAQCategory, setSelectedFAQCategory] = useState();
    const [selectedFAQ, setSelectedFAQ] = useState();
    const [displayAnswer, setDisplayAnswer] = useState();

    useEffect(() => {
        onFetchFAQCategoryList()
    }, [])

    useEffect(() => {
        if (!FAQCategoryList) {
            setFAQCategoryCards(undefined)
        } else {
            setFAQCategoryCards(<div className="FAQCategorySelector">
                {FAQCategoryList.map((data) => {
                    return <button className="bl-bt-selector" variant="light" onClick={(e) => {
                        setSelectedFAQ(undefined)
                        const prevSelected = document.querySelectorAll(".bl-bt-selector[selected=\"true\"]");
                        if (prevSelected.length !== 0) { prevSelected[0].removeAttribute("selected") }
                        e.currentTarget.setAttribute("selected", "true");
                        setSelectedFAQCategory(data)
                    }}>
                        <BiSolidCategoryAlt />
                        <span>{data.name} </span>
                    </button>;
                })}
            </div>)
        }
    }, [FAQCategoryList])

    useEffect(() => {
        if (FAQCategoryList) {
            const prevSelected = document.querySelectorAll(".bl-bt-selector");
            if (prevSelected.length !== 0) { prevSelected[0].setAttribute("selected", "true") }
            setSelectedFAQCategory(FAQCategoryList[0])
        }
    }, [FAQCategoryCards])

    useEffect(() => {
        if (selectedFAQCategory) {
            transactionTemplate.setCurrentPage(1);
        }
        onFetchList(1)
    }, [selectedFAQCategory])


    useEffect(() => { if (selectedFAQ) { setDisplayAnswer(onDisplayAnswer()); } }, [selectedFAQ])

    return <TransactionTemplate
        onTransactionListUpdated={onTransactionListUpdated}
        controlId="txn-faq"
        bind={transactionTemplate}
        imports={{ onFetchList: onFetchList, onCreateCards: onCreateCards, onAddNew: onAddNew, onFormClosed: onTransactionFormClosed }}
        leftSideComp={<div>{FAQCategoryCards}</div>}
        rightSideComp={<div className="txn-faq-fd" >

            <div className="txnp">
                {selectedFAQ ? <ListGroup>
                    <ListGroup.Item  > {displayAnswer}</ListGroup.Item>

                </ListGroup> : <></>}
            </div>
        </div>}
        onListTypeChanging={() => setSelectedFAQ(undefined)}
    />
}