
import { Suspense, useEffect, useRef, useState } from "react";
import "./ContentSliderMaintenance.scss";
import TransactionTemplate from "../../Reusables/TransactionTemplate.jsx";
import { Button, Form } from "react-bootstrap";
import Card from "../Helper/Card.jsx";
import UpdatingCard from "../Helper/UpdatingCard.jsx";
import NoData from "../Helper/NoData.jsx";
import { awaitedSubmitData } from "../Helper/PromiseWrapper.ts";
import SuspenseBody from "../Helper/SuspenseBody.jsx";
import Toast from "./Toast.jsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { GET } from "../../Reusables/FetchHandler.jsx";
import { ValueValidator } from "../../Reusables/ValueValidator.jsx";

export default function ContentSliderMaintenance(props) {

    const onFetchList = (page) => {

        removeSelected(".ctmd-md-nav[selected=\"true\"]");
        transactionTemplate.setTransactionList(undefined)
        transactionTemplate.setCards(<UpdatingCard />)
        const parameters = "?" + new URLSearchParams({
            page: page,
            limit: transactionTemplate.getLimit(),
            voided: transactionTemplate.getVoided()
        }).toString();

        GET('/api/mgrslidercontent/list' + parameters,
            async (failedResponse) => {
                console.log(failedResponse)
            },
            async (successResponse) => {
                const response = await successResponse.json();
                transactionTemplate.setTransactionList(response.response);
                transactionTemplate.setTotalPages(Math.ceil(response.totalCount / transactionTemplate.getLimit()));
            },
            async (error) => {
                console.log(error)
            }
        )

    }


    function createEntryForm(title, type, data, callback) {
        const isEdit = data ? true : false;
        formDataRef.current = data ? data : {};
        formImageRef.current = {};

        transactionTemplate.createEntryForm(title,
            <>
                <Form.Group className="mb-3" >
                    <Form.Label>Description</Form.Label>
                    <Form.Control className="default-focus" type="text"
                        onChange={(e) => formDataRef.current.description = e.currentTarget.value}
                        defaultValue={data ? formDataRef.current.description : ""} />
                </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>URL</Form.Label>
                    <Form.Control className="default-focus" type="text"
                        onChange={(e) => formDataRef.current.url = e.currentTarget.value}
                        defaultValue={data ? formDataRef.current.url : ""} />
                </Form.Group>


                <Form.Group className="mb-3">
                    <Form.Label>Web Banner {isEdit ? `(Optional)` : ``}</Form.Label>
                    <Form.Control type="file" accept="image/png, image/jpeg, image/webp, image/jpg"
                        onChange={(e) => { formImageRef.current.largebanner = e.target.files[0] }} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Mobile Banner {isEdit ? `(Optional)` : ``}</Form.Label>
                    <Form.Control type="file" accept="image/png, image/jpeg, image/webp, image/jpg"
                        onChange={(e) => { formImageRef.current.mobilebanner = e.target.files[0] }} />
                </Form.Group>

            </>,
            (type === "add" ?
                <Button onClick={(e) => { onSaveAddNew(e, callback) }}>Save New</Button> :
                <Button onClick={(e) => { onSaveEdit(e, data, callback) }}>Update</Button>));
    }

    const onAddNew = () => {
        createEntryForm("New Item", "add", undefined, transactionTemplate.setNewDataCallBack)
    }

    const onSaveAddNew = (e, callback) => {
        e.target.disabled = true;


        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(formDataRef.current.description, `Description`)
            .add(formDataRef.current.url, `URL`)
            .add(formImageRef.current.largebanner, `Web Banner`)
            .add(formImageRef.current.mobilebanner, `Mobile Banner`)
            .validate()
            .get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {
            const request = new FormData()
            request.append("url", formDataRef.current.url);
            request.append("description", formDataRef.current.description);
            request.append("largebanner", formImageRef.current.largebanner);
            request.append("mobilebanner", formImageRef.current.mobilebanner);

            callback(<Suspense fallback={<></>}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrslidercontent/onsavenew', request)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            e.target.disabled = false;
                            transactionTemplate.setToast(
                                <Toast
                                    variant="danger"
                                    onClose={() => { transactionTemplate.setToast(undefined) }}
                                    headingText={result.status + "  " + result.statusText}
                                    descriptionText={result.response} />)
                        } else {
                            onFetchList(transactionTemplate.getCurrentPage());
                            transactionTemplate.setEntryForm(undefined)
                            transactionTemplate.setToast(
                                <Toast
                                    variant="success"
                                    onClose={() => { transactionTemplate.setToast(undefined) }}
                                    headingText={"201 OK"}
                                    descriptionText={"Item has been created"} />
                            )
                        }
                    }} />
            </Suspense>)
        }
    }

    const onEdit = (data, callback) => {
        createEntryForm("Modify Item [ " + data.contentId + " ]", "update", data, callback)
    }

    const onSaveEdit = (e, data, callback) => {
        e.target.disabled = true;
        const isValid = new ValueValidator((message) => { transactionTemplate.setToast(message) })
            .add(formDataRef.current.description, `Description`)
            .add(formDataRef.current.url, `URL`)
            .validate()
            .get();

        if (!isValid) {
            e.target.disabled = false;
        }
        else {

            const request = new FormData()
            request.append("sliderContent", new Blob([JSON.stringify(formDataRef.current)], { type: "application/json" }));
            request.append("largebanner", formImageRef.current.largebanner ? formImageRef.current.largebanner : new Blob());
            request.append("mobilebanner", formImageRef.current.mobilebanner ? formImageRef.current.mobilebanner : new Blob());

            callback(<Suspense fallback={<UpdatingCard />}>
                <SuspenseBody
                    wrappedPromiseData={awaitedSubmitData('/api/mgrslidercontent/update', request)}
                    onCreateBody={(result) => {
                        if (result.fail) {
                            callback(undefined);
                            e.target.disabled = false;
                            transactionTemplate.setToast(<Toast
                                variant="danger"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={result.status + "  " + result.statusText}
                                descriptionText={result.response} />)
                        } else {
                            transactionTemplate.setTransactionList(transactionTemplate.getTransactionList().map(item => item === data ? result : item))
                            transactionTemplate.setEntryForm(undefined)
                            setSelectedContentMt(result)
                            callback(undefined);
                            transactionTemplate.setToast(<Toast
                                variant="success"
                                onClose={() => { transactionTemplate.setToast(undefined) }}
                                headingText={"201 OK"}
                                descriptionText={"Modification has been saved"} />)
                        }
                    }} />
            </Suspense>)
        }
    }



    function onUpdateStatus(data, callback, voided) {

        if (formDataRef.current) {
            transactionTemplate.setToast(<Toast
                variant="danger"
                onClose={() => { transactionTemplate.setToast(undefined) }}
                headingText={"405 Method Not Allowed"}
                descriptionText={"Your requested action is not allowed for currently editing data"} />)
            return;
        }

        const map = new FormData()
        map.append("id", data.contentId);
        map.append("voided", voided);
        callback(<Suspense fallback={<UpdatingCard />}>
            <SuspenseBody
                wrappedPromiseData={awaitedSubmitData('/api/mgrslidercontent/updatestatus', map)}
                onCreateBody={(result) => {
                    if (result.fail) {
                        transactionTemplate.setToast(<Toast
                            variant="danger"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={result.status + "  " + result.statusText}
                            descriptionText={result.response} />)
                    } else {
                        transactionTemplate.setToast(<Toast
                            variant="success"
                            onClose={() => { transactionTemplate.setToast(undefined) }}
                            headingText={"201 OK"}
                            descriptionText={"Modification has been saved"} />)
                        let pageNo = transactionTemplate.getCurrentPage();
                        if (transactionTemplate.getTransactionList().length === 1) { transactionTemplate.setCurrentPage(pageNo = transactionTemplate.getCurrentPage() - 1) }
                        onFetchList(pageNo);
                    }
                }}
            />
        </Suspense>)
    }

    const onUnvoid = (data, callback) => {
        onUpdateStatus(data, callback, false)
    }


    const onVoid = (data, callback) => {
        onUpdateStatus(data, callback, true)
    }

    function onCreateCards() {
        if (!transactionTemplate.getTransactionList()) { return <UpdatingCard /> }
        return transactionTemplate.getTransactionList().length === 0 ? NoData() :
            <div className="cardlist">
                {transactionTemplate.getTransactionList().map((data) => {


                    return <Card
                        controlKey={data.contentId}
                        isRounded={false}
                        removeButtonText="Void"
                        voided={data.voided}
                        onEdit={onEdit}
                        onVoidOrDelete={onVoid}
                        onUnvoid={onUnvoid}
                        isItemIsBody={true}
                        item={{
                            data: data,
                            render: <div className="card-image-content-group">
                                <div className="cicgg-p">
                                    <span className="ggp">{data.description}</span>
                                </div>
                                <div className="cicgg-i">
                                    <LazyLoadImage className="ciciimg" src={'/public/mgrslidercontent/image?' + new URLSearchParams({ imageUUID: data.imageWebUUID, imageType: 80 }).toString()} />
                                    <LazyLoadImage className="ciciimg" src={'/public/mgrslidercontent/image?' + new URLSearchParams({ imageUUID: data.imageMobileUUID, imageType: 80 }).toString()} />
                                </div>
                                <div className="cicgg-p">
                                    <span style={{ color: "#3a79b8" }} >{"Upon clicking, follow link " + data.url}</span>
                                </div>
                            </div>
                        }}
                    />;
                })}
            </div>
    }







    function onTransactionFormClosed() {
        formDataRef.current = undefined;
        formImageRef.current = undefined;
    }

    function removeSelected(selector) {
        const elements = document.querySelectorAll(selector);
        if (elements.length !== 0) { elements[0].removeAttribute("selected") }
    }

    function onTransactionListUpdated(transactionList) {
        if (transactionList) {
            if (transactionList.length !== 0) {
                if (selectedContentMt) {
                } else {
                    setSelectedContentMt(transactionList[0])
                }
            }
        }
    }

    const formDataRef = useRef();
    const formImageRef = useRef();
    const transactionTemplate = {};
    const [selectedContentMt, setSelectedContentMt] = useState();


    useEffect(() => {
        onFetchList(1)
    }, [])




    return <TransactionTemplate
        onTransactionListUpdated={onTransactionListUpdated}
        controlId="txn-contentslidermaintenance"
        bind={transactionTemplate}
        imports={{ onFetchList: onFetchList, onCreateCards: onCreateCards, onAddNew: onAddNew, onFormClosed: onTransactionFormClosed }}
        onListTypeChanging={() => setSelectedContentMt(undefined)}
    />
}